import React, { forwardRef, useContext } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { findPFsNoRedux } from '../../actions/PracticeFacilityActions'
import utils from '../../utils'
import OrganizationSearcherV2, {
  Props as OrganizationSearchProps,
} from '../../components/Searchers/OrganizationSearcherV2'
import dateTime from '../../utils/dateTime'
import DisplayAddress from '../../components/Address'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  ID: {
    name: 'ID',
    details: {
      dataFormat(_: any, row: any): any {
        return row.ID
      },
    },
  },
  Name: {
    name: 'Name',
    details: { width: '250px', sortName: 'PracticeFacility.Name' },
  },
  DisplayAddress: {
    name: 'Address',
    details: {
      dataFormat(_: any, row: any) {
        return <DisplayAddress addressObj={row.Address} />
      },
    },
  },
  Phone: { name: 'Phone', details: { dataFormat: utils.phoneFormatter } },
  Fax: { name: 'Fax', details: { dataFormat: utils.phoneFormatter } },
  Email: { name: 'Email', details: {} },
  PosCode: { name: 'POS Code', details: { hidden: true } },
  Npi: { name: 'NPI', details: {} },
  OrganizationID: { name: 'Name', details: { hidden: true } },
  ExternalId: { name: 'External ID', details: {} },
  HasActiveFeeSchedule: {
    name: 'Has Active Fee Schedule',
    details: {
      dataFormat: utils.boolYesNoFormatter,
      sortName: 'HasActiveFeeSchedule',
    },
  },
  ActiveFeeScheduleName: {
    name: 'Fee Schedule Name',
    details: { sortName: 'ActiveFeeScheduleName' },
  },
  LaunchDate: {
    name: 'Launch Date',
    details: { dataFormat: dateTime.cellFormatter() },
  },
  TerminationDate: {
    name: 'Termination Date',
    details: { dataFormat: dateTime.cellFormatter() },
  },
})

const defaultSort = { col: 'PracticeFacility.Name', dir: 'asc' }

export const Table = forwardRef(function PracticeFacilityTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = findPFsNoRedux,
    fnLinkOnRowClick = (row: any) =>
      `/organization/${row.OrganizationId}/practice_facility/${row.ID}`,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      fnLinkOnRowClick,
    },
    ref
  )

  return <>{TableDisplay}</>
})

export function FilterOrganization(
  props: Partial<OrganizationSearchProps>
): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <OrganizationSearcherV2
      {...props}
      selectedOrganizationID={filter.organizationID}
      onChange={(id: number | null) => {
        setFilterImmediate({ organizationID: id })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
        style: { minWidth: 250, maxWidth: 350 },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 350 },
      }}
    />
  )
}
