import React, { useState, useEffect, forwardRef } from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, {
  SnackbarTypeSuccess,
  SnackbarTypeWarning,
  SnackbarTypeError,
} from '../../hooks/useSnackbar'
import {
  getBillingEntityByID,
  createBillingEntity,
  saveBillingEntity,
} from '../../actions/BillingEntityActions'
import {
  renderTextField as RenderTextField,
  renderNotesField as RenderNotesField,
  SetterArgs,
} from '../ViewHelpers'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { ShapeBillingEntity } from '../../models/BillingEntity'

export interface props {
  organizationID?: number
  onSave?(): void
  readOnly?: boolean
}

const emptyDefaults = (): ShapeBillingEntity => ({
  ID: 0,
  Name: '',
  TIN: '',
  NPI: '',
  OrganizationId: null,
  Notes: '',
  // Sequence: 0,
  DateStart: null,
  DateEnd: null,
  RemittanceAddressID: null,
  RemittanceAddress: {
    ID: 0,
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    Zip: '',
  },
  // IsActive: true,
  // IsDefaultOrganizationBillingEntity: false,
})

export default forwardRef(function DisplayBillingEntity(
  { organizationID, onSave, readOnly }: props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [record, setRecord] = useState<ShapeBillingEntity>(emptyDefaults())
  const [validations, setValidations] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const canSave = Object.keys(validations).length === 0

  React.useImperativeHandle(
    ref,
    () => ({
      openEmpty,
      viewBillingEntityID,
    }),
    []
  )

  useEffect(() => {
    if (isOpen) {
      setRecord(emptyDefaults())
      setValidations({})
      setIsLoading(false)
      return
    }
  }, [isOpen])

  useEffect(() => {
    const vldtns = {} as any
    if (!record.Name) {
      vldtns.Name = {
        error: true,
        helperText: 'Name is required',
        FormHelperTextProps: { error: true },
      }
    }
    if (!!record.NPI && (record.NPI || '').length !== 10) {
      vldtns.NPI = {
        error: true,
        helperText: 'Must be 10 digits',
        FormHelperTextProps: { error: true },
      }
    }
    if ((record.TIN || '').length !== 9) {
      vldtns.TIN = {
        error: true,
        helperText: 'Must be 9 digits',
        FormHelperTextProps: { error: true },
      }
    }
    setValidations(vldtns)
  }, [record.Name, record.NPI, record.TIN])

  const setter = ({ name, value }: SetterArgs) => {
    setRecord((curr: ShapeBillingEntity) => ({ ...curr, [name]: value }))
  }

  const setterRemittanceAddress = ({ name, value }: SetterArgs) => {
    setRecord((curr: ShapeBillingEntity) => {
      if (!curr.RemittanceAddress) {
        curr.RemittanceAddress = {} as any
      }
      curr.RemittanceAddress[name] = value
      return { ...curr }
    })
  }

  function openEmpty() {
    setRecord(emptyDefaults())
    setIsOpen(true)
  }

  function viewBillingEntityID(id: number) {
    setIsLoading(true)
    setIsOpen(true)

    getBillingEntityByID(id)
      .then((res: any) => {
        if (res.error) throw res
        setRecord(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading Billing Entity',
        })
      )
      .finally(() => setIsLoading(false))
  }

  function doSave() {
    if (!canSave) {
      // insurance really; Save button should be disabled if not valid
      showSnackbar(
        'Validation errors must be fixed prior to saving',
        SnackbarTypeWarning
      )
      return
    }

    const isNew = !record.ID
    if (isNew) {
      if (!organizationID) {
        showSnackbar(
          'Cannot create new Billing Entity without an Organization ID. Please contact engineering.',
          SnackbarTypeError
        )
        return
      }
      createBillingEntity({ ...record, OrganizationId: organizationID })
        .then((res: any) => {
          if (res.error) throw res
          showSnackbar('New Billing Entity created OK', SnackbarTypeSuccess)
          setIsOpen(false)
          onSave?.()
        })
        .catch(
          catchAPIError({
            defaultMessage: 'Failed creating new Billing Entity',
          })
        )

      return
    }

    saveBillingEntity(record)
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Billing Entity saved OK', SnackbarTypeSuccess)
        setIsOpen(false)
        onSave?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed saving Billing Entity',
        })
      )
  }

  const spreadInputProps = { use2023Styles: true, opts: { margin: 'dense' } }

  return (
    <>
      <Dialog open={isOpen} maxWidth={false} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          {readOnly ? 'View' : !!record.ID ? 'Edit' : 'Add'} Billing Entity
        </DialogTitle>

        <DialogContent>
          {isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <DesignSuite2023.LoadingSpinner />
            </div>
          ) : (
            <>
              <fieldset disabled={readOnly}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DesignSuite2023.Tooltip title="Name is visible to providers in GoZERO.">
                      <div>
                        <RenderTextField
                          name="Name"
                          label="Name"
                          value={record.Name}
                          setter={setter}
                          use2023Styles
                          opts={{
                            margin: 'dense',
                            ...validations.Name,
                          }}
                        />
                      </div>
                    </DesignSuite2023.Tooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <DesignSuite2023.Tooltip
                      title={
                        <span style={{ maxWidth: 380, display: 'block' }}>
                          Tax Identification Number is fixed after creation and
                          cannot be updated. To setup a new TIN, create a new
                          Billing Entity.
                        </span>
                      }>
                      <div>
                        <RenderTextField
                          name="TIN"
                          label="TIN"
                          value={record.TIN}
                          setter={setter}
                          disabled={!!record.ID}
                          use2023Styles
                          opts={{
                            margin: 'dense',
                            ...validations.TIN,
                          }}
                        />
                      </div>
                    </DesignSuite2023.Tooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <RenderTextField
                      name="NPI"
                      label="NPI"
                      value={record.NPI}
                      setter={setter}
                      use2023Styles
                      opts={{
                        margin: 'dense',
                        ...validations.NPI,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ManagedDateInput
                      name="DateStart"
                      label="Valid From"
                      value={record.DateStart}
                      setter={setter}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ManagedDateInput
                      name="DateEnd"
                      label="Valid Thru"
                      value={record.DateEnd}
                      setter={setter}
                    />
                  </Grid>
                </Grid>

                <DesignSuite2023.Divider />

                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <RenderTextField
                      name="Address1"
                      label="Address1"
                      value={record.RemittanceAddress.Address1}
                      setter={setterRemittanceAddress}
                      {...spreadInputProps}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <RenderTextField
                      name="Address2"
                      label="Address2"
                      value={record.RemittanceAddress.Address2}
                      setter={setterRemittanceAddress}
                      {...spreadInputProps}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <RenderTextField
                      name="City"
                      label="City"
                      value={record.RemittanceAddress.City}
                      setter={setterRemittanceAddress}
                      {...spreadInputProps}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <RenderTextField
                      name="State"
                      label="State"
                      value={record.RemittanceAddress.State}
                      setter={setterRemittanceAddress}
                      {...spreadInputProps}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <RenderTextField
                      name="Zip"
                      label="Zip"
                      value={record.RemittanceAddress.Zip}
                      setter={setterRemittanceAddress}
                      {...spreadInputProps}
                    />
                  </Grid>
                </Grid>

                <DesignSuite2023.Divider />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <RenderNotesField
                      name="Notes"
                      label="Notes"
                      value={record.Notes}
                      setter={setter}
                      {...spreadInputProps}
                    />
                  </Grid>
                </Grid>
              </fieldset>
            </>
          )}
        </DialogContent>

        {!readOnly && (
          <DialogActions>
            <DesignSuite2023.GridLR
              left={
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    setIsOpen(false)
                  }}>
                  Cancel
                </Button>
              }
              right={
                <>
                  <Button
                    disabled={!canSave}
                    color="primary"
                    variant="outlined"
                    onClick={doSave}>
                    Save
                  </Button>
                </>
              }
            />
          </DialogActions>
        )}
      </Dialog>
    </>
  )
})
