import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import {
  Chip,
  Grid,
  Button,
  IconButton,
  InputAdornment,
  TextField as MUITextField,
} from '@material-ui/core'
import styled from 'styled-components'
import dateTime from '../../../../utils/dateTime'
import helpers from '../../helpers'
import {
  BillingEntityActions,
  EmployerActions,
  LoaActions,
  MemberActions,
  OrgActions,
  PriceActions,
  ReferralActions,
} from '../../../../actions'
import { findZClaims } from '../../../../actions/ZClaimActions'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'

const StyledFastView = styled.div`
  padding: 1rem;

  .title {
    margin: 0 0 1rem;
    background: rgba(0, 0, 0, 0.45);
    border-radius: 5px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .details {
    padding: 0.35rem;

    .content {
      padding-bottom: 0.75rem;
    }
    .subgrid {
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-row-gap: 0.35rem;
      align-items: center;
      margin-bottom: 0.5rem;
      span {
        font-size: 90%;
      }
    }
  }
`

const StyledResult = styled.div`
  border-bottom: 1px dotted #ccc;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
`

const StyledTextField = styled(MUITextField)`
  * {
    color: inherit;
  }
  fieldset {
    border-color: #fff;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #fff;
    color: #fff;

    legend {
      color: inherit;
    }
  }
`

interface props {
  kind?: FastViewType | null
  zClaimID: number | null
  claim: any
  doClose(): void
}

interface optProps {
  onFastViewClose?(): void
  [key: string]: any
}

export enum FastViewType {
  FEE_SCHEDULE = 'fs',
  EMPLOYER = 'emp',
  ORGANIZATION = 'org',
  BILLING_ENTITY = 'be',
  PRACTICE_FACILITY = 'pf',
  PATIENT = 'pat',
  SUBSCRIBER = 'sub',
  CLAIM_ETC = 'etc', // claim notes & such
}

export default forwardRef(function FastView(
  allProps: props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const [fvc, setFVC] = useState<FastViewType | null>(null)
  const [otherProps, setOtherProps] = useState<optProps>({})
  // const informClose = React.useMemo(() => {
  //   return () => {
  //     console.log('informClose invoked with', otherProps)
  //     otherProps?.onFastViewClose?.()
  //   }
  // }, [otherProps])

  const spreadProps = { ...allProps, doClose: () => setFVC(null) }

  useImperativeHandle(
    ref,
    () => ({
      toggle: (fvc: FastViewType | null, passOtherProps?: any): void => {
        if (!fvc) {
          setFVC(null)
          // setOtherProps({})
          return
        }
        setFVC(fvc)
        if (!!passOtherProps) {
          setOtherProps(passOtherProps)
        }
      },
    }),
    []
  )

  useEffect(() => {
    if (!fvc) {
      otherProps?.onFastViewClose?.()
      if (Object.keys(otherProps).length >= 1) {
        setOtherProps({})
      }
      return
    }
  }, [fvc, otherProps])

  let comp = null
  switch (fvc) {
    case FastViewType.FEE_SCHEDULE:
      comp = <FeeSchedule {...spreadProps} {...otherProps} />
      break
    case FastViewType.EMPLOYER:
      comp = <Employer {...spreadProps} {...otherProps} />
      break
    case FastViewType.ORGANIZATION:
      comp = <Organization {...spreadProps} {...otherProps} />
      break
    case FastViewType.BILLING_ENTITY:
      comp = <BillingEntity {...spreadProps} {...otherProps} />
      break
    case FastViewType.PATIENT:
      comp = (
        <Member
          {...spreadProps}
          {...otherProps}
          memberID={spreadProps?.claim?.Claim?.PatientInfo?.MemberID}
        />
      )
      break
    case FastViewType.SUBSCRIBER:
      comp = (
        <Member
          {...spreadProps}
          {...otherProps}
          memberID={spreadProps?.claim?.Claim?.SubscriberInfo?.MemberID}
        />
      )
      break
    case FastViewType.CLAIM_ETC:
      comp = <ClaimEtc {...spreadProps} {...otherProps} />
      break
    default:
      comp = <span>not implemented</span>
  }

  return (
    <DesignSuite2023.Drawer
      variant="persistent"
      anchor="left"
      open={!!fvc}
      onClose={() => setFVC(null)}
      width="20vw"
      paperTransparent>
      {comp}
    </DesignSuite2023.Drawer>
  )
})

function Member({
  zClaimID,
  memberID,
  doClose,
}: props & Partial<any>): React.ReactElement | null {
  const [memberData, setMemberData] = useState<any>(null)
  const [loas, setLoas] = useState<any[]>([])
  const [referrals, setReferrals] = useState<any[]>([])
  const [otherClaims, setOtherClaims] = useState<any[]>([])
  const [expandedEmID, setExpandedEmID] = useState<number | null>(null)
  const [expandedLoaID, setExpandedLoaID] = useState<number | null>(null)
  const [expandedRflID, setExpandedRflID] = useState<number | null>(null)
  const [expandedZClaimID, setExpandedZClaimID] = useState<number | null>(null)
  const [rflStatuses, setRflStatuses] = useState<{
    [statusID: number]: string
  }>({})

  useEffect(() => {
    if (!memberID) return
    MemberActions.getMemberByID(memberID).then((res: any) => {
      setMemberData(res.Data)
    })
  }, [memberID])

  useEffect(() => {
    if (!memberData) return
    const employerMemberIDs = memberData.EmployerMemberships.map(
      (em: any) => em.ID
    )
    LoaActions.findLOAs({ filter: { employerMemberIDs, NoLimit: true } }).then(
      (res: any) => {
        setLoas(res.Data)
      }
    )
    ReferralActions.getReferralStatuses().payload.then((res: any) => {
      setRflStatuses(
        res.Data.reduce((accum: any, item: any) => {
          accum[item.ID] = item.Descr
          return accum
        })
      )
    })
    ReferralActions.findReferralsV2({
      filter: { employerMemberIDs, disableLimit: true },
    }).then((res: any) => {
      setReferrals(res.Data)
    })
    findZClaims({
      filter: {
        patientMemberIDs: [memberID],
        disableLimit: true,
        excludeZClaimIDs: [zClaimID],
      },
    }).then((res: any) => {
      setOtherClaims(res.Data)
    })
  }, [memberData])

  function toggleEm(id: number) {
    if (expandedEmID === id) return setExpandedEmID(null)
    setExpandedEmID(id)
  }

  function toggleLoa(id: number) {
    if (expandedLoaID === id) return setExpandedLoaID(null)
    setExpandedLoaID(id)
  }

  function toggleRfl(id: number) {
    if (expandedRflID === id) return setExpandedRflID(null)
    setExpandedRflID(id)
  }

  function toggleZClaimID(id: number) {
    if (expandedZClaimID === id) return setExpandedZClaimID(null)
    setExpandedZClaimID(id)
  }

  if (!memberID || !memberData) return null

  return (
    <StyledFastView>
      <h4 className="title">
        <span>Member Info Fast View</span>
        <div>
          <IconButton onClick={doClose} size="small" color="inherit">
            <DesignSuite2023.CommonIcons.IconArrowLeft fontSize="inherit" />
          </IconButton>
        </div>
      </h4>

      <div className="details">
        <div className="content">
          <small>
            <strong>Name</strong>
          </small>
          <br />
          {memberData.FirstName} {memberData.LastName}
        </div>
        <div className="content">
          <small>
            <strong>Date Of Birth</strong>
          </small>
          <br />
          {dateTime.parse(memberData.Dob).format()}
        </div>
        <div className="content">
          <small>
            <strong>Retired</strong>
          </small>
          <br />
          <helpers.IconFromBool value={memberData.Retired} />{' '}
          {memberData.Retired ? 'Yes' : 'No'}
        </div>
        <div className="content">
          <small>
            <strong>Social Security #</strong>
          </small>
          <br />
          {memberData.Ssn}
        </div>
        <div className="content">
          <small>
            <strong>Address</strong>
          </small>
          <br />
          {memberData.Address.DisplayAddress}
        </div>
      </div>

      <div style={{ marginBottom: '1.5rem' }}>
        <h5>Employer Memberships</h5>
        {(memberData.EmployerMemberships || []).map((em: any) => {
          return (
            <StyledResult key={em.ID}>
              <IconButton
                size="small"
                style={{ color: 'inherit' }}
                onClick={() => toggleEm(em.ID)}>
                <DesignSuite2023.CommonIcons.IconArrowDown />
              </IconButton>
              &nbsp;
              <DesignSuite2023.Tooltip title="Employer name">
                <Chip size="small" label={em.EmployerName} />
              </DesignSuite2023.Tooltip>
              {em.ID === expandedEmID && (
                <div className="details">
                  <div className="content">
                    <small>
                      <strong>Is Subscriber</strong>
                    </small>
                    <br />
                    <helpers.IconFromBool value={em.IsSubscriber} />{' '}
                    {em.IsSubscriber ? 'Yes' : 'No'}
                  </div>
                  <div className="content">
                    <small>
                      <strong>Activation / Deactivation</strong>
                    </small>
                    <br />
                    {dateTime.parse(em.ActivationDate).format()} -{' '}
                    {dateTime.parse(em.DeactivationDate).format()}
                  </div>
                  <div className="content">
                    <small>
                      <strong>Integration ID</strong>
                    </small>
                    <br />
                    {em.IntegrationId}
                  </div>
                  <div className="content">
                    <small>
                      <strong>Insurance Member ID</strong>
                    </small>
                    <br />
                    {em.InsuranceMemberID || '-'}
                  </div>
                  <div className="content">
                    <small>
                      <strong>Has Quest</strong>
                    </small>
                    <br />
                    <helpers.IconFromBool value={em.HasQuest} />{' '}
                    {em.HasQuest ? 'Yes' : 'No'}
                  </div>
                </div>
              )}
            </StyledResult>
          )
        })}
      </div>

      <div style={{ marginBottom: '1.5rem' }}>
        <h5>LOAs</h5>
        {(loas || []).map((loa: any) => {
          return (
            <StyledResult key={loa.ID}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <IconButton
                    size="small"
                    style={{ color: 'inherit', marginRight: '0.5rem' }}
                    onClick={() => toggleLoa(loa.ID)}>
                    <DesignSuite2023.CommonIcons.IconArrowDown />
                  </IconButton>
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ paddingBottom: '0.25rem' }}>
                    <DesignSuite2023.Tooltip title="Organization name">
                      <Chip size="small" label={loa.Organization.Name} />
                    </DesignSuite2023.Tooltip>
                  </div>
                  <DesignSuite2023.GridLR
                    left={
                      <small>
                        ServiceDate:{' '}
                        {dateTime.parse(loa.DateOfService).format()}
                      </small>
                    }
                    right={<small>Status: {loa.Status?.Descr}</small>}
                  />
                </div>
              </div>

              {loa.ID === expandedLoaID && (
                <div className="details">
                  <div style={{ textAlign: 'center' }}>
                    <a
                      href={`/loa/${loa.ID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#64a7df' }}>
                      <strong>Open LOA page in new window</strong>
                    </a>
                  </div>
                  <div className="content">
                    <small>
                      <strong>Contract Date</strong>
                    </small>
                    <br />
                    {dateTime.parse(loa.ContractDate).format()}
                  </div>
                  <div className="content">
                    <small>
                      <strong>CostKey</strong>
                    </small>
                    <br />
                    <Chip size="small" label={loa.CostKey?.Code || '?'} />{' '}
                    {loa.CostKey?.Descr}
                  </div>
                  <div className="content">
                    <small>
                      <strong>Main CPT Code</strong>
                    </small>
                    <br />
                    <Chip size="small" label={loa.MainCptCode || '?'} />
                  </div>
                  <div className="content">
                    <small>
                      <strong>Price</strong>
                    </small>
                    <br />${loa.Price} (TypicalPPO: ${loa.TypicalPPOPrice})
                  </div>
                  <div className="content">
                    <small>
                      <strong>Via Employer</strong>
                    </small>
                    <br />
                    {loa.Employer?.Name}
                  </div>
                  <div className="content">
                    <small>
                      <strong>Reason</strong>
                    </small>
                    <br />
                    {loa.Reason || '(none provided)'}
                  </div>
                  <div className="content">
                    <small>
                      <strong>Notes</strong>
                    </small>
                    <br />
                    {loa.Notes || '(none provided)'}
                  </div>
                  <div className="content">
                    <small>
                      <strong>Bundle Contents</strong>
                    </small>
                    <br />
                    {loa.BundleContents || '(none provided)'}
                  </div>
                  <div className="content">
                    <small>
                      <strong>Referral</strong>
                    </small>
                    <br />
                    {!!loa.ReferralID ? (
                      <a
                        href={`https://go.zero.health/referral/${loa.ReferralID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#64a7df' }}>
                        <strong>View in GoZERO</strong>
                      </a>
                    ) : (
                      '(unlinked)'
                    )}
                  </div>
                </div>
              )}
            </StyledResult>
          )
        })}
      </div>

      <div>
        <h5>Referrals</h5>
        {(referrals || []).map((rfl: any) => {
          return (
            <StyledResult key={rfl.ID}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <IconButton
                    size="small"
                    style={{ color: 'inherit', marginRight: '0.5rem' }}
                    onClick={() => toggleRfl(rfl.ID)}>
                    <DesignSuite2023.CommonIcons.IconArrowDown />
                  </IconButton>
                </div>
                <DesignSuite2023.GridLR
                  left={
                    <DesignSuite2023.Tooltip title="Referral description">
                      <Chip size="small" label={rfl.Descr} />
                    </DesignSuite2023.Tooltip>
                  }
                  right={
                    <small>
                      Status:
                      <br />
                      {rflStatuses[rfl.StatusID]}
                    </small>
                  }
                />
              </div>

              {rfl.ID === expandedRflID && (
                <div className="details">
                  <div className="content">
                    <small>
                      <strong>Created</strong>
                    </small>
                    <br />
                    {dateTime
                      .parse(rfl.CreatedAt)
                      .format(dateTime.formats.PrettyVerbose)}
                  </div>
                  <div className="content">
                    <small>
                      <strong>Notes</strong>
                    </small>
                    <br />
                    {rfl.Notes}
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <a
                      href={`https://go.zero.health/referral/${rfl.ID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#64a7df' }}>
                      <strong>View in GoZERO</strong>
                    </a>
                  </div>
                </div>
              )}
            </StyledResult>
          )
        })}
      </div>

      <div>
        <h5>Other Claims Attached To This Member</h5>
        {(otherClaims || []).map((zc: any) => {
          return (
            <StyledResult key={zc.ID}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <IconButton
                    size="small"
                    style={{ color: 'inherit', marginRight: '0.5rem' }}
                    onClick={() => toggleZClaimID(zc.ID)}>
                    <DesignSuite2023.CommonIcons.IconArrowDown />
                  </IconButton>
                </div>
                <DesignSuite2023.GridLR
                  left={
                    <DesignSuite2023.Tooltip title="Clearing House Claim Number">
                      <Chip
                        size="small"
                        label={zc.Detail?.ClearingHouseClaimNumber}
                      />
                    </DesignSuite2023.Tooltip>
                  }
                  right={
                    <>
                      <small style={{ whiteSpace: 'nowrap' }}>
                        ID: {zc.ID}
                      </small>
                      <br />
                      <small style={{ whiteSpace: 'nowrap' }}>
                        Status: {zc.ZClaimStatus}
                      </small>
                    </>
                  }
                />
              </div>
            </StyledResult>
          )
        })}
      </div>
    </StyledFastView>
  )
}

function Organization({
  zClaimID,
  claim,
  doClose,
}: props): React.ReactElement | null {
  const [orgData, setOrgData] = useState<any>(null)
  const [billingEntities, setBillingEntities] = useState<any[]>([])
  const [searchQ, setSearchQ] = useState('')
  const [expandedID, setExpandedID] = useState<number | null>(null)
  const organizationID = claim?.Claim?.OrganizationInfo?.ID

  useEffect(() => {
    if (!organizationID) return
    OrgActions.getOrganization(organizationID).then((res: any) => {
      setOrgData(res)
    })
  }, [organizationID])

  useEffect(() => {
    if (!organizationID) return
    if (searchQ.length === 1 || searchQ.length === 2) return
    BillingEntityActions.findBillingEntities({
      filter: {
        q: searchQ,
        OrganizationIDs: [organizationID],
        disableLimit: true,
      },
    }).then((res: any) => {
      setBillingEntities(res.Data || [])
    })
  }, [searchQ])

  if (!organizationID || !orgData) return null

  return (
    <StyledFastView>
      <h4 className="title">
        <span>Organization Fast View</span>
        <div>
          <IconButton onClick={doClose} size="small" color="inherit">
            <DesignSuite2023.CommonIcons.IconArrowLeft fontSize="inherit" />
          </IconButton>
        </div>
      </h4>

      <div className="details">
        <div className="content">
          <small>
            <strong>Name</strong>
          </small>
          <br />
          {orgData.Name}
        </div>
        <div className="content">
          <small>
            <strong>Description</strong>
          </small>
          <br />
          {orgData.Descr}
        </div>
        <div className="content">
          <small>
            <strong>Billing Notes</strong>
          </small>
          <br />
          {orgData.BillingNotes}
        </div>

        <div className="content">
          <small>
            <strong>Details</strong>
          </small>
          <div className="subgrid">
            <span>EDIBilling</span>
            <span>
              <helpers.IconFromBool value={orgData.EdiBilling} />
            </span>
            <span>GoZeroBilling</span>
            <span>
              <helpers.IconFromBool value={orgData.GoZeroBilling} />
            </span>
            <span>LOAOnly</span>
            <span>
              <helpers.IconFromBool value={orgData.LoaOnly} />
            </span>
            {/* <span>PlanRenewalDay</span><span>{empData.PlanRenewalDay}</span>
            <span>PlanRenewalMonth</span><span>{empData.PlanRenewalMonth}</span>
            <span>DeductibleStartDay</span><span>{empData.DeductibleStartDay}</span>
            <span>DeductibleStartMonth</span><span>{empData.DeductibleStartMonth}</span>
            <span>TIN</span><span>{empData.TIN}</span>
            <span></span><span></span>
            <span>LaunchDate</span><span>{empData.LaunchDate}</span>
            <span></span><span></span>
            <span>TerminationDate</span><span>{empData.TerminationDate}</span> */}
          </div>
        </div>

        <div className="content">
          <small>
            <strong>Specializations</strong>
          </small>
          <br />
          {(orgData.Specializations || []).map((s: any) => (
            <div key={s}>{s}</div>
          ))}
        </div>

        <div className="content">
          <small>
            <strong>Configurable Networks</strong>
          </small>
          <br />
          {(orgData.ConfigurableNetworks || []).map((n: any) => (
            <div key={n.ID}>
              {n.Name}
              <br />
              <small>{n.Notes}</small>
            </div>
          ))}
        </div>
      </div>

      <hr />

      <h5>Billing Entities</h5>
      <TextField searchQ={searchQ} setSearchQ={setSearchQ} />
      {billingEntities.map((be: any) => {
        return (
          <StyledResult key={be.ID}>
            <IconButton
              size="small"
              style={{ color: 'inherit' }}
              onClick={() => setExpandedID(be.ID)}>
              <DesignSuite2023.CommonIcons.IconArrowDown />
            </IconButton>
            &nbsp;
            <DesignSuite2023.Tooltip title="Billing entity name">
              <Chip size="small" label={be.Name} />
            </DesignSuite2023.Tooltip>
            {be.ID === expandedID && (
              <div className="details">
                <div className="content">
                  <Grid container spacing={4}>
                    <Grid item>
                      <small>
                        <DesignSuite2023.Tooltip title="Tax Identifier">
                          <strong>TIN</strong>
                        </DesignSuite2023.Tooltip>
                      </small>
                      <br />
                      {be.TIN}
                    </Grid>
                    <Grid item>
                      <small>
                        <DesignSuite2023.Tooltip title="National Provider Identity">
                          <strong>NPI</strong>
                        </DesignSuite2023.Tooltip>
                      </small>
                      <br />
                      {be.NPI}
                    </Grid>
                  </Grid>
                </div>
                <div className="content">
                  <small>
                    <strong>Notes</strong>
                  </small>
                  <br />
                  {be.Notes || '(none)'}
                </div>
                <div className="content">
                  <small>
                    <strong>Remittance Address</strong>
                  </small>
                  <br />
                  {be.RemittanceAddress?.DisplayAddress}
                </div>
              </div>
            )}
          </StyledResult>
        )
      })}
    </StyledFastView>
  )
}

function Employer({
  zClaimID,
  claim,
  doClose,
}: props): React.ReactElement | null {
  const [empData, setEmpData] = useState<any>(null)
  const employerID = claim?.Employer?.ID

  useEffect(() => {
    if (!employerID) return
    EmployerActions.getEmployerById({ employerId: employerID }).payload.then(
      (res: any) => {
        setEmpData(res.Data)
      }
    )
  }, [employerID])

  if (!employerID || !empData) return null

  return (
    <StyledFastView>
      <h4 className="title">
        <span>Employer Fast View</span>
        <div>
          <IconButton onClick={doClose} size="small" color="inherit">
            <DesignSuite2023.CommonIcons.IconArrowLeft fontSize="inherit" />
          </IconButton>
        </div>
      </h4>

      <div className="details">
        <div className="content">
          <small>
            <strong>Name</strong>
          </small>
          <br />
          {empData.Name}
        </div>
        <div className="content">
          <small>
            <strong>Description</strong>
          </small>
          <br />
          {empData.Descr}
        </div>
        <div className="content">
          <small>
            <strong>LOA Process</strong>
          </small>
          <br />
          {empData.LOAProcessType}
        </div>

        <div className="content">
          <small>
            <strong>Plan Details</strong>
          </small>
          <div className="subgrid">
            <span>PlanRenewalDay</span>
            <span>{empData.PlanRenewalDay}</span>
            <span>PlanRenewalMonth</span>
            <span>{empData.PlanRenewalMonth}</span>
            <span>DeductibleStartDay</span>
            <span>{empData.DeductibleStartDay}</span>
            <span>DeductibleStartMonth</span>
            <span>{empData.DeductibleStartMonth}</span>
            <span>TIN</span>
            <span>{empData.TIN}</span>
            <span></span>
            <span></span>
            <span>LaunchDate</span>
            <span>{empData.LaunchDate}</span>
            <span></span>
            <span></span>
            <span>TerminationDate</span>
            <span>{empData.TerminationDate}</span>
          </div>
        </div>

        <div className="content">
          <small>
            <strong>Exclusion Notes</strong>
          </small>
          <br />
          {empData.ExclusionNotes}
        </div>
      </div>
    </StyledFastView>
  )
}

function FeeSchedule({
  zClaimID,
  claim,
  doClose,
  // other props (specialized API for bundle linking)
  presetSearch = '',
  onSelectFeeSchedulePrice,
}: props & Partial<any>): React.ReactElement | null {
  const [prices, setPrices] = useState<any[]>([])
  const [searchQ, setSearchQ] = useState(presetSearch)
  const [expandedID, setExpandedID] = useState<number | null>(null)
  const feeScheduleID = claim?.FeeSchedule?.ID
  const canSelect = !!onSelectFeeSchedulePrice

  // automatically fires load when component mounts
  useEffect(() => {
    if (!feeScheduleID) return
    if (searchQ.length === 1 || searchQ.length === 2) return
    setExpandedID(null)
    PriceActions.findPrices({
      filter: { feeScheduleID, q: searchQ, disableLimit: true },
    }).payload.then((res: any) => {
      setPrices(res.Data || [])
    })
  }, [feeScheduleID, searchQ])

  if (!feeScheduleID)
    return (
      <StyledFastView>
        <h4 className="title">
          <span>Fee Schedule Prices Fast View</span>
          <div>
            <IconButton onClick={doClose} size="small" color="inherit">
              <DesignSuite2023.CommonIcons.IconArrowLeft fontSize="inherit" />
            </IconButton>
          </div>
        </h4>
        <span>No fee schedule available</span>
      </StyledFastView>
    )

  return (
    <StyledFastView>
      <h4 className="title">
        <span>Fee Schedule Prices Fast View</span>
        <div>
          <IconButton onClick={doClose} size="small" color="inherit">
            <DesignSuite2023.CommonIcons.IconArrowLeft fontSize="inherit" />
          </IconButton>
        </div>
      </h4>

      <TextField searchQ={searchQ} setSearchQ={setSearchQ} />

      {prices.map((p: any) => {
        return (
          <StyledResult key={p.ID}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <IconButton
                  size="small"
                  style={{ color: 'inherit' }}
                  onClick={() => setExpandedID(p.ID)}>
                  <DesignSuite2023.CommonIcons.IconArrowDown />
                </IconButton>
                &nbsp;
                <DesignSuite2023.Tooltip title="Cost key code">
                  <Chip size="small" label={p.CostKey.Code} />
                </DesignSuite2023.Tooltip>
                {!!p.CostKey.DRGCode && (
                  <>
                    &nbsp;
                    <DesignSuite2023.Tooltip title="DRG code">
                      <Chip size="small" label={p.CostKey.DRGCode} />
                    </DesignSuite2023.Tooltip>
                  </>
                )}
              </Grid>
              <Grid item>
                Price: $<strong>{p.Price}</strong>
                <div>
                  <small>
                    <strong>Start:</strong>{' '}
                    {dateTime.parse(p.DateStart).format()}
                    {!!p.DateEnd && (
                      <>
                        <br />
                        <strong>End: </strong>{' '}
                        {dateTime.parse(p.DateEnd).format()}
                      </>
                    )}
                  </small>
                </div>
              </Grid>
            </Grid>
            {p.ID === expandedID && (
              <div className="details">
                <div className="content">
                  <small>
                    <strong>Category</strong>
                  </small>
                  <br />
                  {p.CostKey.Category}
                </div>
                <div className="content">
                  <small>
                    <strong>Description</strong>
                  </small>
                  <br />
                  {p.CostKey.Descr}
                </div>
                <div className="content">
                  <small>
                    <strong>Bundle Contents</strong>
                  </small>
                  <br />
                  {p.CostKey.BundleContents}
                </div>
                <div className="subgrid">
                  <span>AllowTravel</span>
                  <div>
                    <helpers.IconFromBool value={p.CostKey.AllowTravel} />
                  </div>
                  <span>OverrideTypicalPPO</span>
                  <div>
                    <helpers.IconFromBool
                      value={p.CostKey.AlwaysOverrideTypicalPPO}
                    />
                  </div>
                  <span>RequireUnits</span>
                  <div>
                    <helpers.IconFromBool value={p.CostKey.RequireUnits} />
                  </div>
                  <span>GroupByServiceDate</span>
                  <div>
                    <helpers.IconFromBool
                      value={p.CostKey.GroupByServiceDate}
                    />
                  </div>
                  <span>RequireMedicalReview</span>
                  <div>
                    <helpers.IconFromBool
                      value={p.CostKey.RequireMedicalReview}
                    />
                  </div>
                  <span>MissingPathology</span>
                  <div>
                    <helpers.IconFromBool value={p.CostKey.MissingPathology} />
                  </div>
                  <span>MissingImplant</span>
                  <div>
                    <helpers.IconFromBool value={p.CostKey.MissingImplant} />
                  </div>
                  <span>MissingXrays</span>
                  <div>
                    <helpers.IconFromBool value={p.CostKey.MissingXrays} />
                  </div>
                  <span>TypicalPPO</span>
                  <div>todo</div>
                  <span>PPO HRR(OK)</span>
                  <div>todo</div>
                </div>
                {canSelect && (
                  <div style={{ textAlign: 'center', paddingTop: '0.5rem' }}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        onSelectFeeSchedulePrice?.(p)
                      }}>
                      Select Fee Schedule Price
                    </Button>
                  </div>
                )}
              </div>
            )}
            {/* @todo: national typical ppo and tulsa HRR; see cost key fast search on savings review page for example */}
          </StyledResult>
        )
      })}
    </StyledFastView>
  )
}

function BillingEntity({
  zClaimID,
  claim,
  doClose,
}: props): React.ReactElement | null {
  const [data, setData] = useState<any>(null)
  const { catchAPIError } = useErrorHandlers()
  const billingEntityID = claim?.Claim?.BillingEntityInfo?.ID

  useEffect(() => {
    if (!billingEntityID) return
    BillingEntityActions.getBillingEntityByID(billingEntityID)
      .then((res: any) => {
        if (res?.error) throw res
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading billing entity',
        })
      )
  }, [billingEntityID])

  if (!billingEntityID || !data) return null

  return (
    <StyledFastView>
      <h4 className="title">
        <span>Billing Entity Fast View</span>
        <div>
          <IconButton onClick={doClose} size="small" color="inherit">
            <DesignSuite2023.CommonIcons.IconArrowLeft fontSize="inherit" />
          </IconButton>
        </div>
      </h4>

      <div className="details">
        <div className="content">
          <small>
            <strong>Name</strong>
          </small>
          <br />
          {data?.Name}
        </div>
        <div className="content">
          <small>
            <strong>Notes</strong>
          </small>
          <br />
          {data?.Notes}
        </div>
        <div
          className="content"
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto',
            gridRowGap: '0.5rem',
          }}>
          <small>
            <strong>NPI</strong>
          </small>
          <small>
            <strong>TIN</strong>
          </small>
          <small>
            <strong>Active</strong>
          </small>
          <small>
            <strong>Org Default</strong>
          </small>
          <span>{data?.NPI}</span>
          <span>{data?.TIN}</span>
          <span>
            <helpers.IconFromBool value={data.IsActive} />{' '}
            {data.IsActive ? 'Yes' : 'No'}
          </span>
          <span>
            <helpers.IconFromBool
              value={data.IsDefaultOrganizationBillingEntity}
            />{' '}
            {data.IsDefaultOrganizationBillingEntity ? 'Yes' : 'No'}
          </span>
        </div>
        <div className="content">
          <small>
            <strong>Remittance Address</strong>
          </small>
          <br />
          {data?.RemittanceAddress?.DisplayAddress}
        </div>
      </div>
    </StyledFastView>
  )
}

function ClaimEtc({ claim, doClose }: props): React.ReactElement | null {
  if (!claim) return null

  return (
    <StyledFastView>
      <h4 className="title">
        <span>Ancillary Claim Info</span>
        <div>
          <IconButton onClick={doClose} size="small" color="inherit">
            <DesignSuite2023.CommonIcons.IconArrowLeft fontSize="inherit" />
          </IconButton>
        </div>
      </h4>

      <div className="details">
        <div className="content">
          <small>
            <strong>Notes</strong>
          </small>
          <br />
          {(claim?.Notes || []).map((n: any) => {
            return (
              <div key={n.ID}>
                <small>type: {n.NoteType}</small>
                <br />
                {n.Note}
              </div>
            )
          })}
        </div>
      </div>
    </StyledFastView>
  )
}

function TextField({ searchQ, setSearchQ }: any): React.ReactElement {
  return (
    <StyledTextField
      variant="outlined"
      size="small"
      label="Search"
      margin="none"
      fullWidth
      autoFocus
      InputLabelProps={{ shrink: true, style: { color: 'inherit' } }}
      InputProps={{
        style: { color: 'inherit' },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              style={{ color: 'inherit' }}
              onClick={() => setSearchQ('')}>
              <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      style={{ marginBottom: '1rem' }}
      value={searchQ}
      onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
        setSearchQ(ev.target.value)
      }
    />
  )
}
