import React, { useEffect, useMemo, useState } from 'react'
import { Card, Container, Typography } from '@material-ui/core'
import styled from 'styled-components'
import ChipInformationDisplay from './ChipInformationDisplay'
import { renderTextField as RenderTextField } from '../../../components/Inputs/standard'
import DataTable from '../../../components/DataTable/DataTable'

export interface paidRecordDetail {
  LineNumber: number
  ZClaimID: number
  ZClaimAmount: string
  IsReversal: boolean
  FundingInfos: string[]
}

export interface paidRecord {
  PaymentID: string
  PaymentType: string
  Date: Date
  OrganizationID: number
  PartnerID: number
  PayeeName: string
  CheckingAccount: string
  ManualPayType: string
  CheckNumber: number
  Notes: string
  ClaimCount: number
  Amount: string

  Details: paidRecordDetail[]
}

interface paidDetailProps {
  data: paidRecord
  doClose?(): void
}

const PaymentDetailDiv = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`

const claimColumns = {
  LineNumber: {
    name: 'Line Number',
  },
  ZClaimID: {
    name: 'ZClaimID',
    details: {
      dataFormat(_: any, row: paidRecordDetail): JSX.Element {
        return (
          <span style={{ width: '200px' }}>
            {row.ZClaimID}{' '}
            {row.IsReversal && <span style={{ color: 'red' }}>[Reversal]</span>}
          </span>
        )
      },
    },
  },
  FundingInfos: {
    name: 'Funding Information',
    details: {},
  },
  ZClaimAmount: {
    name: 'Amount',
    details: {
      dataFormat(_: any, row: paidRecordDetail): JSX.Element {
        return (
          <span style={{ color: row.IsReversal ? 'red' : 'inherit' }}>
            {formatAmount(row.ZClaimAmount.toString())}
          </span>
        )
      },
    },
  },
}

export default function PaidDetail({
  data,
  doClose,
}: paidDetailProps): React.ReactElement {
  onclose = doClose ? doClose : null

  const sorted = useMemo(() => {
    return data.Details.sort((a, b) => a.LineNumber - b.LineNumber)
  }, [data.Details])

  const [filteredRows, setFilteredRows] = useState<paidRecordDetail[]>([])
  const [search, setSearch] = useState('')
  const [paginatedRows, setPaginatedRows] = useState<paidRecordDetail[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    paginateFilteredRows()
  }, [page, pageSize, filteredRows])

  useEffect(() => {
    if (!search) {
      setFilteredRows(sorted)
      return
    }
    setFilteredRows(
      sorted.filter((item) =>
        item.ZClaimID.toString().includes(search.toLowerCase())
      )
    )
  }, [search])

  function paginateFilteredRows() {
    setPaginatedRows(
      filteredRows.slice(page * pageSize - pageSize, page * pageSize)
    )
  }
  function handlePageChange(value: any) {
    setPage(value.page)
  }

  function handlePageSizeChange(value: any) {
    setPageSize(value.pageSize)
  }

  return (
    <Container>
      <Typography component="div" variant="h5" style={{ marginTop: '16px' }}>
        {data.PayeeName}
      </Typography>
      <PaymentDetailDiv>
        <ChipInformationDisplay label="Payment ID" value={data.PaymentID} />
        <ChipInformationDisplay label="Date" value={data.Date.toString()} />
        <ChipInformationDisplay
          label="Amount"
          value={formatAmount(data.Amount)}
        />
        <ChipInformationDisplay
          label="Checking Account"
          value={data.CheckingAccount}
        />
      </PaymentDetailDiv>
      <PaymentDetailDiv>
        <ChipInformationDisplay label="Payment Type" value={data.PaymentType} />
        {data.ManualPayType && (
          <ChipInformationDisplay
            label="Manual Payment Type"
            value={data.ManualPayType}
          />
        )}

        {data.CheckNumber && (
          <ChipInformationDisplay
            label="Check Number"
            value={data.CheckNumber}
          />
        )}
        {data.Notes && (
          <ChipInformationDisplay label="Notes" value={data.Notes} />
        )}
      </PaymentDetailDiv>

      <Card variant="outlined">
        <RenderTextField
          name="ZClaimID"
          label="ZClaimID"
          value={search}
          setter={({ value }) => setSearch(value)}
          opts={{ style: { width: '150px', marginLeft: '8px' } }}
          use2023Styles
        />
        <DataTable
          columns={claimColumns}
          data={paginatedRows}
          initPage={1}
          initPageSize={pageSize}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={(v: any) => handlePageSizeChange(v)}
          count={filteredRows.length}
        />
      </Card>
    </Container>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
