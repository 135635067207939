import React, { forwardRef, useContext, useState } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { findBillingEntities } from '../../actions/BillingEntityActions'
import dateTime from '../../utils/dateTime'
import DisplayAddress from '../../components/Address'
import { OrganizationSearcherV2 } from '../../components/Searchers'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { pick } from 'lodash'
import DesignSuite2023 from '../../components/DesignSuite2023'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  ID: { name: 'ID' },
  Name: { name: 'Name', details: { sortName: 'BillingEntity.Name' } },
  OrganizationName: {
    name: 'Organization',
    details: {
      sortName: 'BillingEntity.OrganizationName',
      dataFormat(_: any, row: any) {
        return (
          <a
            target="_blank"
            rel="noreferrer"
            href={`/organization/${row.OrganizationID}`}>
            {row.OrganizationName}
          </a>
        )
      },
    },
  },
  TIN: { name: 'TIN', details: { sortName: 'BillingEntity.TIN' } },
  NPI: { name: 'NPI', details: { sortName: 'BillingEntity.NPI' } },
  Notes: {
    name: 'Notes',
    details: {
      dataFormat(_: any, row: any) {
        return (
          <span
            style={{ display: 'inline-block', fontSize: '90%', maxWidth: 320 }}>
            {row.Notes}
          </span>
        )
      },
    },
  },
  DateStart: {
    name: 'DateStart',
    details: {
      dataFormat: dateTime.cellFormatter(),
    },
  },
  DateEnd: {
    name: 'DateEnd',
    details: {
      dataFormat: dateTime.cellFormatter(),
    },
  },
  IsContracted: {
    name: 'IsContracted',
    details: {
      dataFormat(_: any, row: any) {
        return row.IsContracted ? 'Yes' : 'No'
      },
    },
  },
  DisplayAddress: {
    name: 'Address',
    details: {
      dataFormat(_: any, row: any) {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            <DisplayAddress addressObj={row?.RemittanceAddress} />
          </span>
        )
      },
    },
  },
})

export const densifiedColumns = Object.freeze({
  ...pick(defaultColumns, ['ID']),
  Name: {
    name: 'Name',
    details: {
      width: 300,
      sortName: 'BillingEntity.Name',
      dataFormat(_: any, row: any) {
        return (
          <div>
            <span>{row?.Name}</span>
            <span
              style={{
                display: 'block',
                whiteSpace: 'nowrap',
                fontSize: '95%',
                opacity: 0.75,
              }}>
              <DisplayAddress addressObj={row?.RemittanceAddress} />
            </span>
          </div>
        )
      },
    },
  },
  ...pick(defaultColumns, ['OrganizationName']),
  Notes: {
    name: 'Notes',
    details: {
      dataFormat(_: any, row: any): React.ReactElement | null {
        if (!row.Notes) return null
        return (
          <DesignSuite2023.Tooltip title={row.Notes || ''}>
            <DesignSuite2023.CommonIcons.IconPeek />
          </DesignSuite2023.Tooltip>
        )
      },
    },
  },
  ...pick(defaultColumns, [
    'TIN',
    'NPI',
    'DateStart',
    'DateEnd',
    'IsContracted',
  ]),
})

const defaultSort = { col: 'BillingEntity.Name', dir: 'asc' }

export const Table = forwardRef(function BillingEntityTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = findBillingEntities,
    DataTableProps = {},
    initPageSize = 25,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize,
      DataTableProps: {
        density: 'small',
        rowsPerPage: [10, 25, 50, 100, 250],
        ...DataTableProps,
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})

export function FilterOrganization(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <OrganizationSearcherV2
      {...props}
      preloadAll
      selectedOrganizationID={filter.organizationIDs?.[0]}
      onChange={(id: number | null) => {
        if (!id) {
          setFilterImmediate({ organizationIDs: [] })
          return
        }
        setFilterImmediate({ organizationIDs: [id] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
        style: { minWidth: 250, maxWidth: 350 },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 350 },
      }}
    />
  )
}

export function FilterContractedStatus({ ...props }: any): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ contractedStatus: null })
    }
    setFilterImmediate({ contractedStatus: sel.props.value })
  }

  const v = filter.contractedStatus || 'all'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ width: 130, ...props?.style }}>
      <InputLabel shrink id={internalID}>
        Contracted
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Contracted"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
