import React, { forwardRef, useContext, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findOpenPayables } from '../../../actions/ZClaimFundingActions'
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import ViewPayableDetailOverlay from './ViewPayableDetailOverlay'
import {
  getPayableStatusLabel,
  getPayEntityTypeDisplay,
  payableRecord,
  PayableStatus,
  PayEntityType,
} from './PayableDetail'
//@ts-ignore
import styled from 'styled-components'
import ManualPay from './ManualPay'

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`
export * from '../../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  ViewPayableDetails: {
    name: '',
    details: {
      dataFormat(_: any, row: payableRecord): any {
        return <InlineViewPayableDetail data={row} />
      },
    },
  },
  PayEntityType: {
    name: 'Type',
    details: { dataFormat: getPayEntityTypeDisplay },
  },
  PayEntityName: { name: 'Payee' },
  ClaimCount: { name: 'Claim Count' },
  PayableStatus: {
    name: 'Payable Status',
    details: {
      dataFormat(_: any, row: payableRecord): any {
        return getPayableStatusLabel(row.PayableStatus as PayableStatus)
      },
    },
  },
  LastCollectedOn: {
    name: 'Last Collected On',
    details: {
      dataFormat(_: any, row: payableRecord): any {
        const today = new Date()
        const lastCollectedOn = new Date(row.LastCollectedOn)
        const style = lastCollectedOn > today ? { color: 'fuchsia' } : {}
        return <div style={style}>{row.LastCollectedOn}</div>
      },
    },
  },
  Amount: {
    name: 'Amount',
    details: {
      dataFormat(_: any, row: payableRecord): any {
        const amount = parseFloat(row.Amount)
        const style = amount < 0 ? { color: 'red' } : {}
        return <div style={style}>{formatAmount(row.Amount)}</div>
      },
    },
  },
  ManualPaymentButton: {
    name: '',
    details: {
      dataFormat(_: any, row: payableRecord): any {
        const today = new Date()
        const lastCollectedOn = new Date(row.LastCollectedOn)
        const disabled = lastCollectedOn > today
        return parseFloat(row.Amount) > 0 ? (
          <InlineManualPaymentButton data={row} disabled={disabled} />
        ) : null
      },
    },
  },
})

const defaultSort = { col: 'PayEntityName', dir: 'asc' }

export const Table = forwardRef(function Table(
  {
    customColumns = defaultColumns,
    apiEndpoint = findOpenPayables,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 100,
      DataTableProps: {
        isRowCheckable: (row: payableRecord) => {
          const today = new Date()
          const lastCollectedOn = new Date(row.LastCollectedOn)
          return (
            row.PayEntityType === PayEntityType.BillingEntity &&
            parseFloat(row.Amount) >= 0 &&
            lastCollectedOn <= today
          )
        },
        keyProp: 'RecordID',
        ...DataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-invoice-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

function InlineViewPayableDetail({
  data,
}: {
  data: payableRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <ViewPayableDetailOverlay
        ref={ref}
        data={data}
        onClose={() => refresh()}
      />
    </>
  )
}

function InlineManualPaymentButton({
  data,
  disabled,
}: {
  data: payableRecord
  disabled: boolean
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)

  return <ManualPay data={data} disabled={disabled} onClose={() => refresh()} />
}

export function FilterPayableStatus(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState(() => {
    return Object.values(PayableStatus).map((value: PayableStatus) => ({
      value,
      label: getPayableStatusLabel(value),
    }))
  })

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ payableStatuses: null })
    }
    setFilterImmediate({ payableStatuses: [sel.props.value] })
  }

  const v = filter.payableStatuses || PayableStatus.All

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Payable Status
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Payable Status"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterPayEntityType(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState(() => {
    return Object.values(PayEntityType).map((value: PayEntityType) => ({
      value,
      label: getPayEntityTypeDisplay(value),
    }))
  })

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ payEntityTypes: null })
    }
    setFilterImmediate({ payEntityTypes: [sel.props.value] })
  }

  const v = filter.payEntityTypes || PayEntityType.All

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Pay Entity Type
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Payable Status"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
