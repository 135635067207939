import React, { useEffect, useState, forwardRef } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  renderNotesField as RenderNotesField,
  RenderPriceField,
  RenderSelectField2,
  SetterArgs,
  Setter,
  SelectItem,
} from '../../ViewHelpers'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import { ShapeEmployerMarketingActivity } from './types'
import * as FileUpload from '../../../components/FileUpload'
import { getFileTypes } from '../../../actions/FileActions'
import {
  getEmployerMarketingActivityByID,
  getEmployerMarketingActivityTypes,
  putEmployerMarketingActivityByID,
  postEmployerMarketingActivity,
} from '../../../actions/EmployerActions'
import { fileLinkFormatter } from '../../../utils/ReactFormatters'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import styled from 'styled-components'

const StyledDialog = styled(Dialog)`
  .upload-trigger {
    min-height: 0 !important;
  }
  .optional {
    text-align: center;

    .caption {
      position: relative;
      top: -25px;
      color: #888;
    }
  }
`

interface props {
  showTriggerBtn?: boolean
  employerID?: number | null
  onSaved?(): void
}

const emptyRecord = {
  EmployerID: null,
  ActivityType: null,
  Cost: null,
  Notes: null,
  FileID: null,
  ActivityDate: null,
}

export default forwardRef(function DialogManageMarketingActivity(
  { showTriggerBtn = true, employerID = null, onSaved }: props,
  ref: any
): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<ShapeEmployerMarketingActivity>({
    ...emptyRecord,
    EmployerID: employerID,
  })
  const [fileTypeID, setFileTypeID] = useState<number | null>(null)
  const [activityTypes, setActivityTypes] = useState<SelectItem[] | null>(null)
  const [uploadInFlight, setUploadInFlight] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const snackbar = useSnackbar()

  React.useImperativeHandle(ref, () => ({ openAndLoadID }))

  useEffect(() => {
    if (isOpen) {
      loadFileTypeID()
      loadActivityTypes()
      return
    }
    setData({ ...emptyRecord, EmployerID: employerID })
  }, [isOpen])

  function openAndLoadID(id: number) {
    loadByID(id).then(() => setIsOpen(true))
  }

  function loadByID(id: number) {
    return getEmployerMarketingActivityByID(id)
      .then((res: any) => {
        setData(res.Data)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed loading activity type info' })
      )
  }

  function loadFileTypeID() {
    if (fileTypeID) return // ghetto cache; if component already has this in memory great, otherwise reload it
    getFileTypes({ filter: { Hashes: ['employer_marketing'] } })
      .then((res: any) => {
        if (res.error) throw res
        setFileTypeID(res?.[0]?.ID)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed loading activity type info' })
      )
  }

  function loadActivityTypes() {
    if (activityTypes) return // ghetto cache; if component already has this in memory great, otherwise reload it
    getEmployerMarketingActivityTypes()
      .then((res: any) => {
        setActivityTypes(res.Data.map((v: any) => ({ value: v, label: v })))
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed loading activity type info' })
      )
  }

  function doSave() {
    let fn = !!data.ID
      ? putEmployerMarketingActivityByID
      : postEmployerMarketingActivity
    fn(data)
      .then(() => {
        // we don't propagate back to the onSaved func; it really shouldn't care
        onSaved && onSaved()
        setIsOpen(false)
        snackbar.show(
          'Employer marketing activity saved OK',
          SnackbarTypeSuccess
        )
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed saving activity type info' })
      )
  }

  function isDisabled(): boolean {
    if (uploadInFlight) return true
    if (!data.ActivityType) return true
    if (!data.ActivityDate) return true
    return false
  }

  const setter: Setter = ({ name, value }: SetterArgs) => {
    setData((curr: ShapeEmployerMarketingActivity & Partial<any>) => {
      curr[name] = value
      return { ...curr }
    })
  }

  return (
    <>
      {showTriggerBtn && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsOpen(true)}>
          Add Marketing Activity
        </Button>
      )}

      <StyledDialog open={isOpen} maxWidth="lg">
        <DialogTitle>Record New Marketing Activity</DialogTitle>
        <DialogContent style={{ width: 650 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <RenderSelectField2
                name="ActivityType"
                label="Activity Type"
                value={data.ActivityType}
                setter={setter}
                items={activityTypes || []}
                FormControlProps={{
                  margin: 'none',
                  size: 'small',
                  fullWidth: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <ManagedDateInput
                name="ActivityDate"
                label="Activity Date"
                value={data.ActivityDate}
                setter={setter}
                margin="none"
              />
            </Grid>
            <Grid item xs={2}>
              <RenderPriceField
                name="Cost"
                label="Cost"
                value={data.Cost || ''}
                setter={setter}
                use2023Styles
                opts={{ margin: 'none' }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RenderNotesField
                name="Notes"
                label="Notes"
                value={data.Notes || ''}
                setter={setter}
                opts={{ fullWidth: true }}
                use2023Styles
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!!data.File && data.File.ID && (
                <div>
                  <Typography variant="body2">
                    A file is already linked to this record; if you upload a new
                    one, it'll override the current one and you will have no
                    historical reference to it (but it can still be found by
                    searching the Files tab for this employer).
                  </Typography>
                  <div style={{ padding: '0.5rem', textAlign: 'center' }}>
                    {fileLinkFormatter(null, data.File)}
                  </div>
                </div>
              )}
              {!!fileTypeID && (
                <>
                  <FileUpload.Uploader
                    autoSign
                    autoUniqueName
                    defaultFileData={{
                      FileTypeID: fileTypeID,
                      EmployerID: data.EmployerID,
                    }}
                    CustomFileDisplay={(props: any) => (
                      <FileUpload.DefaultSingleFileInput
                        {...props}
                        showInputs={false}
                      />
                    )}
                    subscribeAllProgress={(fu: FileUpload.coordinator) => {
                      if (fu.status === FileUpload.statuses.START) {
                        setUploadInFlight(true)
                        return
                      }
                      if (fu.status === FileUpload.statuses.DONE) {
                        setUploadInFlight(false)
                      }
                    }}
                    onSuccess={(res: FileUpload.postFileResponse[]) => {
                      setter({ name: 'FileID', value: res[0].ID })
                    }}
                  />
                  {!uploadInFlight && !data?.FileID && (
                    <div className="optional">
                      <Typography
                        className="caption"
                        variant="caption"
                        color="textSecondary">
                        (optional)
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button
                disabled={isDisabled()}
                color="primary"
                variant="contained"
                onClick={doSave}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </StyledDialog>
    </>
  )
})
