/*
Instead of dumping raw JSON to the screen (even if its "pretty" to developers),
this is more end-user friendly. Pass in any object (like a srcPatient record) and it'll
show it like a pretty mini-table.
*/
import React from 'react'
import styled from 'styled-components'

const StyledDisplay = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0.25rem;
  background: #222;
  color: #fff;
  font-family: monospace;
  font-size: 90%;
  padding: 1rem;
  border-radius: 4px;
  overflow: hidden;
  overflow-x: scroll;

  strong {
    white-space: nowrap;
  }
  span.null {
    opacity: 0.35;
  }
`

interface props {
  data: any
  hideEmptyFields?: boolean
  skipFields?: string[]
}

export default function DisplayKeyValueData({
  data,
  hideEmptyFields = true,
  skipFields,
  ...otherProps
}: props & Partial<any>): React.ReactElement | null {
  function _reduceObject(v: any): any {
    if (!hideEmptyFields) {
      return v
    }
    const ff = removeEmptyObjFields(v)
    for (const k of skipFields || []) {
      delete ff[k]
    }
    return ff
  }

  if (!data) return null

  return (
    <StyledDisplay {...otherProps}>
      {Object.keys(_reduceObject(data)).map((k, i): any => {
        return (
          <React.Fragment key={`${k}.${i}`}>
            <strong key={`${k}.${i}.label`}>{k}</strong>
            {data?.[k] === null ? (
              <span key={`${k}.${i}.value`} className="null">
                null
              </span>
            ) : (
              <span key={`${k}.${i}.value`}>{JSON.stringify(data?.[k])}</span>
            )}
          </React.Fragment>
        )
      })}
    </StyledDisplay>
  )
}

export function removeEmptyObjFields(obj: any): any {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v !== null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmptyObjFields(v) : v])
  )
}
