import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { OrgActions } from '../../actions'
import debounce from 'lodash/debounce'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import './styles.scss'
const { findOrgs: getOrganizations, getOrganization: getOrganizationByID } =
  OrgActions

interface DataItem {
  ID: number
  Name: string
  IsActive: boolean
}

export interface Props {
  selectedOrganizationID?: number | null
  onChange(organizationID: number | null, obj?: any): void
  AutoCompleteProps?: object
  TextFieldProps?: any
  disableClear?: boolean
  preloadAll?: boolean
}

const genericErrMsg =
  'Uh oh, something went awry searching for organizations. Try again later.'

export default function OrganizationSearcherV2({
  selectedOrganizationID,
  onChange,
  AutoCompleteProps = {},
  TextFieldProps = {},
  disableClear = false,
  preloadAll = false,
  ...props
}: Props) {
  const [query, setQuery] = useState<string>('')
  const [data, setData] = useState<Array<DataItem>>(Array<DataItem>())
  const [active, setActive] = useState<DataItem | null>(null)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!selectedOrganizationID) {
      return
    }
    if (active && active.ID === selectedOrganizationID) {
      return
    }
    getOrganizationByID(selectedOrganizationID)
      .then((r: any) => {
        setActive({ ID: r.ID, Name: r.Name, IsActive: r.IsActive })
      })
      .catch(
        catchAPIError({
          defaultMessage: genericErrMsg,
        })
      )
  }, [selectedOrganizationID, active])

  useEffect(() => {
    if (preloadAll) return
    if (query.length < 3) {
      return
    }
    loadOrgs({ filter: { q: query } })
  }, [preloadAll, query])

  useEffect(() => {
    if (!preloadAll) return
    loadOrgs({ filter: { noLimit: true }, sort: ['Organization.Name', 'asc'] })
  }, [preloadAll])

  const onInputChange = React.useCallback(
    debounce((ev: any, q: string, r: string) => {
      setQuery(q)
    }, 350),
    [setQuery]
  )

  function loadOrgs(params: any) {
    getOrganizations(params)
      .then((r: any) => {
        setData(
          r.Data.map((p: any) => {
            return { ID: p.ID, Name: p.Name, IsActive: p.IsActive }
          })
        )
      })
      .catch(
        catchAPIError({
          defaultMessage: genericErrMsg,
        })
      )
  }

  function onSelectionChange(_: any, b: DataItem | null, reason: string) {
    setActive(b)
    onChange(b ? b.ID : null, b || null)
  }

  return (
    <Autocomplete
      classes={{ root: 'autocomplete-root' }}
      options={data}
      getOptionLabel={(opt: DataItem) => opt.Name}
      getOptionSelected={(opt: DataItem) => {
        return active ? opt.ID === active.ID : false
      }}
      defaultValue={null}
      value={active}
      disableClearable={disableClear}
      onChange={onSelectionChange}
      onInputChange={onInputChange}
      renderInput={(params: any) => {
        // We have to do some fancy destructuring here in order to allow passing TextFieldProps that
        // may contain InputProps and things like a start/end adornment, while **being sure we don't
        // override the 'params' sent by the Autocomplete component
        const { InputProps: tfInputProps, ...tfProps } = TextFieldProps
        return (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            label="Organization"
            placeholder="Start typing to search organizations..."
            style={{ minWidth: 250 }}
            InputProps={{
              ...params.InputProps,
              ...tfInputProps,
            }}
            {...tfProps}
          />
        )
      }}
      {...AutoCompleteProps}
    />
  )
}
