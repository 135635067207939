import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  Paper,
  Divider,
  Tabs,
  Tab,
  Typography,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Chip,
} from '@material-ui/core'
import queryString from 'query-string'
import _ from 'lodash'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import Models from '../../models'
import UserTable from '../Users/UserTable'
import * as OrgTable from '../Organizations/OrganizationTable'
import * as PracticeFacilityTable from '../PracticeFacilities/PracticeFacilityTable2'
import BundlerTable from '../Bundlers/BundlerTable'
import * as EmployerTable from '../Employers/EmployerTable'
import { GroupRelationsManager } from './GroupRelationsManager.js'
import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'
import { GroupActions, SnackbarActions, clearSaveResult } from '../../actions'
const { getNewGroup, saveNewGroup, saveGroup, getGroup, SAVE_NEW_GROUP } =
  GroupActions
const { setSnackbarMessage } = SnackbarActions

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)

  return Object.assign(
    {
      slider: {
        padding: '22px 0px',
      },
      childContainer: {
        minHeight: 600,
      },
    },
    defaults
  )
}

export class GroupDetail extends DetailView {
  constructor(props) {
    super(props)
    const { match } = props

    this.state = {
      saving: false,
      id: match.params.id,
      fields: Object.assign({}, Models.Group.editFields),
      tabValue: 0,
      loading: true,
      loadingUsers: true,
      confirmUnsavedChanges: false,
      manageUsersOpen: false,
      manageOrgsOpen: false,
      manageFacilitiesOpen: false,
      manageBundlersOpen: false,
      manageEmployersOpen: false,
      actionText: null,
    }

    this.tabs = {
      users: 0,
      orgs: 1,
      pfs: 2,
      bundlers: 3,
      employers: 4,
      loading: 5,
    }
  }

  renderChildTabs() {
    if (!this.props.group || !this.props.group.ID) return <div />
    const { AllOrganizations, AllEmployers } = this.state.fields
    return (
      <div>
        <Tabs
          value={this.state.tabValue}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="inherit">
          <Tab value={this.tabs.users} label="Users" />
          {!AllOrganizations ? (
            <Tab value={this.tabs.orgs} label="Organizations" />
          ) : null}
          <Tab value={this.tabs.pfs} label="Facilities" />
          {!AllEmployers ? (
            <Tab value={this.tabs.employers} label="Employers" />
          ) : null}
          <Tab value={this.tabs.bundlers} label="Bundlers" />
        </Tabs>
        <div className={this.props.classes.childContainer}>
          {this.renderChildTabContent()}
        </div>
      </div>
    )
  }

  renderChildTabContent() {
    const { group } = this.props
    const { tabValue } = this.state
    const { ID } = group
    let table

    if (tabValue === this.tabs.users) {
      table = (
        <div>
          <UserTable groupIds={[ID * 1]} />
          <Button
            style={{ float: 'right', marginTop: 15 }}
            onClick={() => {
              this.setState({ manageUsersOpen: true })
            }}
            color="primary"
            variant="contained">
            Manage Users
          </Button>
        </div>
      )
    } else if (tabValue === this.tabs.orgs) {
      table = (
        <div>
          <OrgTable.Table
            passFilters={{ AccessGroupIDs: [ID * 1] }}
            LeftHeaderItems={
              <>
                <OrgTable.StandardFilterSearch autoFocus />
                &nbsp;
                <OrgTable.FilterStatus />
              </>
            }
            RightHeaderItems={
              <>
                <Chip label={`GroupID: ${ID}`} />
              </>
            }
          />
          <Button
            style={{ float: 'right', marginTop: 15 }}
            onClick={() => {
              this.setState({ manageOrgsOpen: true })
            }}
            color="primary"
            variant="contained">
            Manage Organizations
          </Button>
        </div>
      )
    } else if (tabValue === this.tabs.pfs) {
      table = (
        <div>
          <PracticeFacilityTable.Table
            passFilters={{ AccessGroupIDs: [ID * 1] }}
            LeftHeaderItems={
              <>
                <PracticeFacilityTable.StandardFilterSearch autoFocus />
              </>
            }
            RightHeaderItems={
              <>
                <Chip label={`GroupID: ${ID}`} />
              </>
            }
          />
          <Button
            style={{ float: 'right', marginTop: 15 }}
            onClick={() => {
              this.setState({ manageFacilitiesOpen: true })
            }}
            color="primary"
            variant="contained">
            Manage Facilities
          </Button>
        </div>
      )
    } else if (tabValue === this.tabs.employers) {
      table = (
        <div>
          <EmployerTable.Table
            passFilters={{ AccessGroupIDs: [ID * 1], isCustomer: null }}
            LeftHeaderItems={
              <>
                <EmployerTable.StandardFilterSearch autoFocus />
                &nbsp;&nbsp;
                <EmployerTable.FilterEmployerStatus />
              </>
            }
          />
          <Button
            style={{ float: 'right', marginTop: 15 }}
            onClick={() => {
              this.setState({ manageEmployersOpen: true })
            }}
            color="primary"
            variant="contained">
            Manage Employers
          </Button>
        </div>
      )
    } else if (tabValue === this.tabs.bundlers) {
      table = (
        <div>
          <BundlerTable groupIds={[ID * 1]} />
          <Typography variant="caption" style={{ marginTop: 10 }}>
            Currently ZeroCard is the only bundler supported. This will need to
            be updated if we want to support multiple bundlers
          </Typography>
          <Button
            style={{ float: 'right', marginTop: 15 }}
            onClick={() => {
              this.setState({ manageBundlersOpen: true })
            }}
            color="primary"
            variant="contained">
            Manage Bundlers
          </Button>
        </div>
      )
    }

    return <div>{table}</div>
  }

  componentWillUnmount() {
    this.props.getNewGroup()
    this.props.clearSaveResult()
  }

  componentDidMount() {
    const { match, history } = this.props
    const { location } = history
    const qsParams = queryString.parse(location.search)
    const groupId = match.params.id
    this.props.clearSaveResult()

    if (groupId) {
      this.setState({
        fields: Object.assign({}, Models.Group.editFields, { ID: groupId }),
      })
      this.props.getGroup({ groupId }).then((res) => {
        this.setState({ loading: false, tabValue: +qsParams.active_tab || 0 })
      })
    } else {
      this.props.getNewGroup()
      this.setState({
        fields: Object.assign({}, Models.Group.editFields),
        loading: false,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newGroup = nextProps.group
    const curGroup = this.state.fields
    const { saveResult } = nextProps
    const { history } = this.props
    const { location } = history
    const qsParams = queryString.parse(location.search)

    this.setState({ tabValue: +qsParams.active_tab || 0 })

    if (
      newGroup.ID !== curGroup.ID ||
      curGroup.UpdatedAt !== newGroup.UpdatedAt
    ) {
      const newFields = Object.assign(
        {},
        this.state.fields,
        _.omitBy(newGroup, _.isNull)
      )

      this.setState({
        fields: newFields,
        originalFields: newFields,
        changedFields: {},
      })
    }

    if (saveResult) {
      if (saveResult.type === SAVE_NEW_GROUP) {
        this.props.setSnackbarMessage('Group successfully saved!', 'success')
        this.props.clearSaveResult()
        this.props.history.push(`/group/${nextProps.group.ID}`)
      } else if (saveResult.error) {
        const msg = `An error occurred while saving: ${saveResult.message}`
        this.setState({ saving: false, saveResult })
        this.props.setSnackbarMessage(msg, 'error')
        this.props.clearSaveResult()
      } else {
        this.setState({ saving: false, saveResult })
        this.props.setSnackbarMessage('Group successfully saved!', 'success')
      }
    }
  }

  backToGroups = (e) => {
    const { state } = this.props.location
    let qs = ''

    if (state) {
      const { prevSearch } = state
      if (!_.isEmpty(prevSearch)) {
        qs = prevSearch
      }
    }

    this.props.history.push(`/users#groups${qs}`)
  }

  handleSave = (e) => {
    this.executeSave()
  }

  reloadTabs = () => {
    const { tabValue } = this.state
    const { loading } = this.tabs
    this.setState({ tabValue: loading })
    setTimeout(() => {
      this.setState({ tabValue })
    }, 200)
  }

  handleManagedUpdate = () => {
    // force update of users table
    this.reloadTabs()
    this.setState({
      manageUsersOpen: false,
      manageOrgsOpen: false,
      manageFacilitiesOpen: false,
      manageBundlersOpen: false,
      manageEmployersOpen: false,
    })
  }

  // we can manage all the dialogs closed state together
  handleDialogCancel = () => {
    this.setState({
      manageUsersOpen: false,
      manageOrgsOpen: false,
      manageFacilitiesOpen: false,
      manageBundlersOpen: false,
      manageEmployersOpen: false,
    })
  }

  handleAddNew = () => {
    this.props.history.push('/group')
    window.location.reload()
  }

  executeSave = () => {
    this.setState({ saving: true })
    const groupToSave = Object.assign({}, this.props.group, this.state.fields)

    if (groupToSave.ID) this.props.saveGroup(groupToSave.ID, groupToSave)
    else {
      this.props.saveNewGroup(groupToSave)
    }
  }

  handleCheckboxChange = (e) => {
    this.handleFieldUpdate(e.target.name, e.target.checked)
    this.handleTabChange(null, this.tabs.users)
  }

  renderContent = () => {
    const { classes, group } = this.props
    const { ID } = group

    return (
      <div>
        <Paper className={classes.mainContent} elevation={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} className={classes.cardColumn}>
              <Card>
                <CardHeader
                  title="Group Details"
                  subheader="Details about this specific group."
                />
                <CardContent>
                  {ID && (
                    <div>
                      <TextField
                        disabled
                        label="ID"
                        defaultValue={ID}
                        className={classes.field}
                        autoFocus
                      />
                    </div>
                  )}
                  <TextField
                    autoFocus
                    label="Name"
                    className={classes.field}
                    value={this.state.fields.Name}
                    onChange={this.handleChange}
                    name="Name"
                    margin="normal"
                  />
                  <TextField
                    label="Notes"
                    multiline
                    placeholder="Enter notes here"
                    rows="4"
                    rowsMax="8"
                    className={classes.field}
                    value={this.state.fields.Notes}
                    onChange={this.handleChange}
                    name="Notes"
                    margin="normal"
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.cardColumn}>
              <Card>
                <CardHeader
                  title="* Access"
                  subheader="Enable access to ALL ___ for any users in this group."
                />
                <CardContent>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={this.handleCheckboxChange}
                          checked={!!this.state.fields.AllOrganizations}
                          value={true}
                          name="AllOrganizations"
                        />
                      }
                      label="All Organizations"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={this.handleCheckboxChange}
                          checked={!!this.state.fields.AllEmployers}
                          value={true}
                          name="AllEmployers"
                        />
                      }
                      label="All Employers"
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={3} />
            <Grid item xs={12} sm={9} />
            <Grid item xs={12} sm={3}>
              <Button style={{ float: 'right' }} onClick={this.backToGroups}>
                {'< Back to Groups'}
              </Button>
            </Grid>
            <Grid item sm={6} lg={9}>
              {this.state.saveResult && this.state.saveResult.error && (
                <Typography type="title" className={classes.errorMsg}>
                  {this.state.saveResult.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div className={classes.saveButtonContainer}>
                {this.renderStatusIcon()}
                <Button
                  color="primary"
                  className={classes.button}
                  variant="contained"
                  onClick={this.handleSave}>
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleAddNew}>
                  Add Another Group
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              {group && group.ID && this.renderChanges()}
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.sectionDivider} />
            </Grid>
            <Grid item xs={12}>
              {this.renderChildTabs()}
            </Grid>
            {group && group.ID && this.renderConfirmUnsavedChanges()}
          </Grid>
        </Paper>
      </div>
    )
  }

  render() {
    const { group } = this.props
    const {
      manageUsersOpen,
      manageOrgsOpen,
      manageFacilitiesOpen,
      manageBundlersOpen,
      manageEmployersOpen,
    } = this.state
    return (
      <div>
        {this.props.getError
          ? this.renderError(this.props.getError.message)
          : this.renderContent()}
        {group && group.ID && (
          <GroupRelationsManager
            groupId={group.ID}
            onConfirm={this.handleManagedUpdate}
            onCancel={this.handleDialogCancel}
            usersOpen={manageUsersOpen}
            orgsOpen={manageOrgsOpen}
            facilitiesOpen={manageFacilitiesOpen}
            bundlersOpen={manageBundlersOpen}
            employersOpen={manageEmployersOpen}
            resourceName={group.Name}
          />
        )}
      </div>
    )
  }
}

GroupDetail.propTypes = {
  group: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    group: state.groups.group || {},
    saveResult: state.groups.saveResult,
    getError: state.groups.getError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveNewGroup,
      saveGroup,
      getNewGroup,
      getGroup,
      setSnackbarMessage,
      clearSaveResult,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(GroupDetail)
