import React, { useState, useEffect } from 'react'
import * as api from '../../../../../services/thezerocard/api-helper'
import useErrorHandlers from '../../../../../hooks/useErrorHandlers'
import { ShapeBillingEntity2QueryResponse } from '../../../../BillingEntities/types'
import dateTime from '../../../../../utils/dateTime'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import FixClaimBillingEntities from './fixClaims'

const StyledResults = styled.div`
  margin: 1rem auto;
  width: fit-content;
  display: grid;
  // grid-template-columns: repeat(11, max-content);
  grid-template-columns: repeat(11, fit-content(20%));
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  align-items: center;

  span.hdr {
    font-weight: 600;
    border-bottom: 3px solid #aaa;
  }

  small {
    display: block;
    opacity: 0.7;
    font-weight: inherit;
  }

  hr {
    grid-column: 1/-1;
    width: 100%;
    height: 1px;
    border-top: 1px solid #ccc;
    margin: 0;
  }

  .claim-count {
    font-weight: bold;
  }

  .not-latest {
    text-decoration: line-through;
    &.claim-count,
    &.is-latest {
      text-decoration: none;
    }
  }
  .current {
    font-weight: 700;
    color: #1a846e;
  }
`

export type apiResponse = {
  BillingEntity2ID: number
  BillingEntity2UUID: string
  IsLatest: boolean
  ZClaimCount: number
  ZClaimIDs: number[]
  PointInTimeRecord: ShapeBillingEntity2QueryResponse
  Current?: ShapeBillingEntity2QueryResponse // only sent if different than PointInTimeRecord
}

export default function WorkflowBillingEntities(): React.ReactElement {
  const [data, setData] = useState<apiResponse[] | null>(null)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    loadReport()
      .then((res: any) => {
        if (res.error) throw res
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading billing entity report',
        })
      )
  }, [])

  if (!data) return <div>Loading...</div>

  return (
    <div style={{ maxWidth: '100%' }}>
      <Typography variant="body2">
        This is a snapshot of all claims currently in adjudication or funding
        review, grouped by billing entity.
      </Typography>
      <StyledResults>
        <span className="hdr">ID</span>
        <span className="hdr">Name &amp; Org</span>
        <span className="hdr">TIN</span>
        <span className="hdr">NPI</span>
        <span className="hdr">Contracted?</span>
        <span className="hdr">Record Up-To-Date?</span>
        <span className="hdr">Address</span>
        <span className="hdr">Start Date</span>
        <span className="hdr">End Date</span>
        <span className="hdr">ZClaim Count</span>
        <span className="hdr">&nbsp;</span>
        {data.map((row) => (
          <React.Fragment key={row.BillingEntity2ID}>
            <RenderRow row={row} />
            <hr />
          </React.Fragment>
        ))}
      </StyledResults>
    </div>
  )
}

function RenderRow({
  row,
  className = '',
}: {
  row: apiResponse | ShapeBillingEntity2QueryResponse
  className?: string
}): React.ReactElement | null {
  let use
  let origRes
  if ('ZClaimCount' in row) {
    use = row.PointInTimeRecord as ShapeBillingEntity2QueryResponse
    origRes = row
  } else {
    use = row as ShapeBillingEntity2QueryResponse
  }
  if (!use) return null

  className = `${className} ${use.IsLatest ? 'latest' : 'not-latest'}`

  return (
    <React.Fragment>
      <div className={`cell id ${className}`}>
        <span>{origRes?.BillingEntity2ID || use.ID}</span>
      </div>
      <div className={`cell name-and-org ${className}`}>
        <span>{use.Name}</span>
        <small>{use.OrganizationName}</small>
      </div>
      <div className={`cell tin ${className}`}>
        <span>{use.TIN}</span>
      </div>
      <div className={`cell npi ${className}`}>
        <span>{use.NPI}</span>
      </div>
      <div className={`cell is-contracted ${className}`}>
        {use.IsContracted ? (
          'Yes'
        ) : (
          <span style={{ color: 'red', fontWeight: 'bold' }}>NO</span>
        )}
      </div>
      <div className={`cell is-latest ${className}`}>
        {row.IsLatest ? (
          'Yes'
        ) : (
          <span style={{ color: 'red', fontWeight: 'bold' }}>NO</span>
        )}
      </div>
      <div className={`cell address ${className}`}>
        {use.RemittanceDisplayAddress}
      </div>
      <div className={`cell start-date ${className}`}>
        {dateTime.parse(use.DateStart).format()}
      </div>
      <div className={`cell end-date ${className}`}>
        {dateTime
          .parse(use.DateEnd)
          .format(dateTime.formats.AmericanDate, 'N/A')}
      </div>
      <div className={`cell claim-count ${className}`}>
        {origRes?.ZClaimCount || null}
      </div>
      <div className={`cell fix ${className}`}>
        {origRes && !origRes.IsLatest && (
          <FixClaimBillingEntities fixable={origRes} />
        )}
      </div>

      {!!origRes && !origRes.IsLatest && origRes.Current && (
        <RenderRow className="current" row={origRes.Current} />
      )}
    </React.Fragment>
  )
}

function loadReport() {
  return api.get('/zclaims/billing_entity/report')
}
