import React, { useImperativeHandle } from 'react'
import * as BillingEntityTable from '../../BillingEntities/BillingEntityTable2'
import { Button, Dialog, Typography } from '@material-ui/core'
import styled from 'styled-components'

const StyledDialog = styled(Dialog)`
  .std-table {
    .header-items {
      margin-top: 0;
      margin: 0;
      width: auto;
      padding: 0.5rem 0.5rem 0;
      position: sticky;
      top: 0;
      z-index: 99;
      background: #fff;
      align-items: center;
    }
    .base-table-display {
      margin: 0;
    }
  }
`

interface props {
  onPickedBillingEntity: Function
  Trigger?: React.FC<any> | null
}

export default React.forwardRef(function DialogBillingEntitySearch(
  { onPickedBillingEntity, Trigger }: props,
  ref: any
): React.ReactElement | null {
  const [isOpen, setIsOpen] = React.useState(false)

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    [setIsOpen]
  )

  function doClose() {
    setIsOpen(false)
  }

  function onSelectBillingEntity(be: any) {
    onPickedBillingEntity?.(be)
    doClose()
  }

  let btnTrigger = null
  if (Trigger) {
    btnTrigger = (
      <Trigger
        onClick={(ev: any) => {
          ev?.stopPropagation()
          setIsOpen(true)
        }}
      />
    )
  }

  return (
    <>
      {btnTrigger}

      <StyledDialog open={isOpen} onClose={doClose} maxWidth="xl">
        <BillingEntityTable.Table
          DataTableProps={{ density: 'small' }}
          passFilters={{ contractedStatus: 'yes' }}
          onRowClick={onSelectBillingEntity}
          customColumns={BillingEntityTable.densifiedColumns}
          RightHeaderItems={
            <>
              <Typography variant="h6">Billing Entity Search</Typography>
              &nbsp;&nbsp;
              <Button onClick={doClose} variant="outlined" size="small">
                Close
              </Button>
            </>
          }
          LeftHeaderItems={
            <>
              <BillingEntityTable.StandardFilterSearch />
              &nbsp;&nbsp;
              <BillingEntityTable.FilterOrganization />
              &nbsp;&nbsp;
              <BillingEntityTable.FilterContractedStatus />
            </>
          }
        />
      </StyledDialog>
    </>
  )
})
