import React, { useEffect, useState, useRef } from 'react'
import * as OrgTable from './OrganizationTable'
import * as BillingEntityTable from '../BillingEntities/BillingEntityTable2'
import DisplayBillingEntity from '../BillingEntities/DisplayBillingEntity'
import {
  useSetFrameHeaderLeft,
  useSetFrameHeaderMid,
} from '../../components/AppFrame'
import styled from 'styled-components'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import { Button, Chip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const StyledComponent = styled.div`
  .std-table {
    .header-items {
      margin-top: 0;
    }
  }
`

const TAB_ORGS = 'tab_orgs'
const TAB_BILLING_ENTITIES = 'tab_billing_entities'

export default function Organization(/*props : any*/): React.ReactElement | null {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'organizations.tabs',
  })
  const [tabVal, setTabVal] = useState(queryData?.tablVal || TAB_ORGS)
  const refBillingEntityTable = useRef<any>(null)
  const refDisplayBillingEntity = useRef<any>(null)
  const history = useHistory()

  useEffect(() => {
    setQueryData({ tabVal })
  }, [tabVal])

  useSetFrameHeaderLeft(
    () => <h3 style={{ margin: 0 }}>Provider Organizations</h3>,
    []
  )

  useSetFrameHeaderMid(
    () => (
      <div>
        <Chip
          variant={tabVal === TAB_ORGS ? 'default' : 'outlined'}
          label="Organizations"
          onClick={() => setTabVal(TAB_ORGS)}
        />
        &nbsp;
        <Chip
          variant={tabVal === TAB_BILLING_ENTITIES ? 'default' : 'outlined'}
          label="Billing Entities"
          onClick={() => setTabVal(TAB_BILLING_ENTITIES)}
        />
      </div>
    ),
    [tabVal, setTabVal]
  )

  return (
    <StyledComponent>
      {tabVal === TAB_ORGS && (
        <OrgTable.Table
          enableURLReflection="organizations.list"
          LeftHeaderItems={
            <>
              <OrgTable.StandardFilterSearch />
              &nbsp;
              <OrgTable.FilterStatus />
            </>
          }
          RightHeaderItems={
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/organization')
              }}>
              Add New
            </Button>
          }
          DataTableProps={{
            density: 'small',
            rowsPerPage: [10, 25, 50],
          }}
          initPageSize={25}
        />
      )}

      {tabVal === TAB_BILLING_ENTITIES && (
        <BillingEntityTable.Table
          ref={refBillingEntityTable}
          passFilters={{ contractedStatus: 'yes' }}
          initPageSize={50}
          LeftHeaderItems={
            <>
              <BillingEntityTable.StandardFilterSearch />
              &nbsp;
              <BillingEntityTable.FilterOrganization />
              &nbsp;
              <BillingEntityTable.FilterContractedStatus
                style={{ maxWidth: 100 }}
              />
            </>
          }
          onRowClick={(row: any) => {
            refDisplayBillingEntity.current?.viewBillingEntityID(row.ID)
          }}
        />
      )}

      <DisplayBillingEntity
        ref={refDisplayBillingEntity}
        onSave={() => refBillingEntityTable?.current?.refresh()}
      />
    </StyledComponent>
  )
}
