import React, { useState, useEffect } from 'react'
import {
  getExcodeList,
  // postClearClaimExCode,
  // postClearLineExCode,
} from '../../../actions/ZClaimActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import DeletableChip from './DeletableChip'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { Chip, Button, IconButton, Popover, TextField } from '@material-ui/core'
import { randomID } from '../helpers'
// import useSnackbar /*, { SnackbarTypeSuccess }*/ from '../../../hooks/useSnackbar'
import styled from 'styled-components'
import { ShapeExCodeOpt } from '../types'

const StyledPopover = styled.div`
  padding: 0.75rem;
  font-size: 90%;
  color: #fff;
  background: #222;
  width: 400px;
  height: 240px;
  overflow: hidden;
  overflow-y: scroll;

  // .yes {color:yellow;font-weight:bold;cursor:pointer;}
`

const StyledTextField = styled(TextField)`
  margin-bottom: 0.75rem;

  .MuiInputLabel-root {
    color: inherit;
    opacity: 0.65;
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: #fff;
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(3px);

    &:hover {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }
    }
    .MuiInputBase-input {
      color: inherit;
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
  }

  .Mui-focused {
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }
    }
  }
`

const StyledTooltip = styled.div`
  strong {
    display: block;
  }
  span {
    font-size: 90%;
  }
`

interface props {
  list: any[]
  handleClearExCode?(exCodeOpt: ShapeExCodeOpt): void
  onExCodeAssigned?(exCodeOpt: ShapeExCodeOpt): void
  readOnly?: boolean
}

export default React.forwardRef(function ExCodesDisplay(
  { list = [], handleClearExCode, onExCodeAssigned, readOnly = false }: props,
  ref: any
): React.ReactElement | null {
  const [id] = useState(randomID())
  const [popoverAnchor, setPopoverAnchor] = useState<any>(null)
  const [excOpts, setExcOpts] = useState<ShapeExCodeOpt[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const { catchAPIError } = useErrorHandlers()
  // const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!popoverAnchor) return
    // light-weight cache; if list is already loaded, don't reload it, as the list of
    // excodes are very stable and will rarely change. cacheGetExCodeList
    // is acting en-lieu of redux or some centralized store mechanism solely for purposes
    // of being lighter weight and hopefully easier to understand
    if (excOpts?.length > 0) return
    cacheGetExCodeList()
      .then((res: any) => {
        setExcOpts(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading excode list',
        })
      )
  }, [popoverAnchor])

  function doClear(ec: ShapeExCodeOpt & Partial<any>) {
    handleClearExCode?.(ec)
  }

  function doAssignExCode(eco: ShapeExCodeOpt) {
    onExCodeAssigned && onExCodeAssigned(eco)
    setPopoverAnchor(null)
  }

  function filteredOpts() {
    if (!searchText) return excOpts
    return excOpts.filter((eco: ShapeExCodeOpt) => {
      return (
        eco.Handle.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        eco.Description.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
    })
  }

  function openPopover(ev: any) {
    setSearchText('')
    setPopoverAnchor(ev.currentTarget)
  }

  const addBtnAndPopover = readOnly ? null : (
    <>
      <IconButton size="small" onClick={openPopover} ref={ref}>
        <DesignSuite2023.CommonIcons.IconAdd fontSize="inherit" />
      </IconButton>
      <Popover
        id={id}
        open={!!popoverAnchor}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        transitionDuration={0}>
        <StyledPopover>
          <>
            <div
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                paddingTop: '0.25rem',
              }}>
              <StyledTextField
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                autoFocus
                margin="none"
                onChange={(ev: any) => setSearchText(ev.target.value)}
                value={searchText}
              />
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr auto',
                gridRowGap: '0.25rem',
                gridColumnGap: '0.5rem',
                alignItems: 'center',
              }}>
              {filteredOpts().map((eco: ShapeExCodeOpt) => (
                <React.Fragment key={`${eco.Handle}.wrap`}>
                  <span key={`${eco.Handle}.1`}>
                    <Chip size="small" label={eco.Handle} />
                  </span>
                  <span key={`${eco.Handle}.2`}>{eco.Description}</span>
                  <div key={`${eco.Handle}.3`}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="inherit"
                      onClick={() => doAssignExCode(eco)}>
                      Assign
                    </Button>
                  </div>
                  <hr
                    key={`${eco.Handle}.hr`}
                    style={{
                      width: '100%',
                      gridColumn: '1/-1',
                      borderTop: '1px dashed #aaa',
                      margin: '0.15rem 0',
                    }}
                  />
                </React.Fragment>
              ))}
            </div>
          </>
        </StyledPopover>
      </Popover>
    </>
  )

  return (
    <>
      {list.map((ec: any) => {
        if (!!ec.IsCleared) {
          return (
            <Chip
              key={ec.ID}
              size="small"
              label={
                <DesignSuite2023.Tooltip title={ec.Description}>
                  <span style={{ textDecoration: 'line-through' }}>
                    {ec.Handle}
                  </span>
                </DesignSuite2023.Tooltip>
              }
            />
          )
        }
        return (
          <DeletableChip
            key={ec.ID}
            color="secondary"
            label={
              <DesignSuite2023.Tooltip
                title={
                  <StyledTooltip>
                    <strong>{ec.Description}</strong>
                    {ec.Note ? <span>Note: "{ec.Note}"</span> : null}
                  </StyledTooltip>
                }>
                <span>{ec.Handle}</span>
              </DesignSuite2023.Tooltip>
            }
            popMessage="Clear this ExCode?"
            onConfirm={doClear.bind(null, ec)}
          />
        )
      })}

      {addBtnAndPopover}
    </>
  )
})

let _cachedExCodeList: Promise<any>
async function cacheGetExCodeList(): Promise<any> {
  if (_cachedExCodeList) return _cachedExCodeList
  _cachedExCodeList = await getExcodeList()
  return _cachedExCodeList
}
