import React, { useState } from 'react'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import {
  postSendZelisPayments,
  PayItems,
} from '../../../actions/ZClaimFundingActions'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { Button } from '@material-ui/core'

interface props {
  postSendZelisPaymentsParams: PayItems
  paymentCount: number
  onComplete?(): void
  disabled?: boolean
}

export default React.forwardRef(function ButtonDoZelisPayment(
  {
    postSendZelisPaymentsParams,
    paymentCount,
    onComplete,
    disabled = false,
  }: props,
  ref: any
): React.ReactElement {
  const [isWorking, setIsWorking] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  function doPayment() {
    setIsWorking(true)

    return postSendZelisPayments(postSendZelisPaymentsParams)
      .then(() => {
        showSnackbar('Payment completed OK', SnackbarTypeSuccess)
        onComplete?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed Payment',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <DesignSuite2023.Tooltip title="Payment selected items">
      <span>
        <Button
          ref={ref}
          disabled={isWorking || disabled}
          fullWidth
          variant="outlined"
          color="secondary"
          size="small"
          endIcon={<DesignSuite2023.CommonIcons.IconAttachMoney />}
          onClick={doPayment}>
          {'Zelis Pay' + (paymentCount > 0 ? ` (${paymentCount})` : '')}
        </Button>
      </span>
    </DesignSuite2023.Tooltip>
  )
})
