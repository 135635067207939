import React, { useState, useEffect, useRef, useContext } from 'react'
import { Button, IconButton, Typography } from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import {
  putZClaimAssignVBASource,
  putZClaimAssignUncontractedProvider,
} from '../../../../actions/ZClaimActions'
import * as TableSrcRecords from '../srcRecords/tableSrcRecords'
import useQueryParamsGen2 from '../../../../hooks/useQueryParamsGen2'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import { kind } from '../srcRecords/tableSrcRecords'
import * as stdTableSetup from '../../../../hooks/useStandardTableSetup'
import DisplayKeyValueData from '../../Components/DisplayKeyValueData'
import * as EligibilityTable from '../../../Eligibility/EligibilityTable2'
import * as BillingEntityTable from '../../../BillingEntities/BillingEntityTable2'
import * as ClaimTable from '../../Components/ClaimTable'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar from '../../../../hooks/useSnackbar'
import styled from 'styled-components'
import ConfirmDialog from '../../../../components/ConfirmDialog'

const StyledWrap = styled.div`
  padding: 1.5rem;

  .std-table .header-items {
    margin-top: 0;
  }

  h4 {
    margin: 0;
  }

  .flexd {
    display: flex;
    align-items: start;
    width: 100%;

    .src-info {
      padding-right: 1rem;
    }

    .right {
      flex: 1;
      overflow: clip;
      overflow-clip-margin: 1rem;
    }
  }
`

export default function WorkflowReconcileSourceData(): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.workflows.srcDataTabs',
  })
  const [tab, setTab] = useState<string>(queryData['tab'] || 'pts')
  const refTable = useRef<any>(null)

  useEffect(() => {
    setQueryData({ tab })
  }, [tab])

  return (
    <div>
      <div className="sublayout">
        <div className="left">
          <DesignSuite2023.StyledTabs
            value={tab}
            orientation="vertical"
            indicatorColor="secondary"
            textColor="inherit"
            style={{ margin: 0, borderRadius: '8px' }}>
            <DesignSuite2023.StyledTab
              value={'pts'}
              label="Patients"
              onClick={() => setTab('pts')}
            />
            <DesignSuite2023.StyledTab
              value={'bil'}
              label="Billing Entities (+Org)"
              onClick={() => setTab('bil')}
            />
          </DesignSuite2023.StyledTabs>
        </div>

        <div className="right">
          {tab === 'pts' && (
            <TableSrcRecords.Table
              ref={refTable}
              // noWrapWhitespace
              kind="src_patient"
              legacyMode
              enableURLReflection="zclaims.workflows.vba.patients"
              passFilters={{ HasNoDefaulter: true, legacyMode: true }}
              LeftHeaderItems={
                <>
                  <Typography>Patient Source Data</Typography>
                  &nbsp;
                  <IconButton
                    size="small"
                    onClick={() => refTable.current?.refresh()}>
                    <DesignSuite2023.CommonIcons.IconRefresh fontSize="inherit" />
                  </IconButton>
                  &nbsp;
                  <TableSrcRecords.StandardFilterSearch />
                </>
              }
              RightHeaderItems={
                <>
                  <TableSrcRecords.FilterWhereHasNoDefaulter />
                </>
              }
              RenderComponentOnClick={BtnAssigner}
            />
          )}
          {tab === 'bil' && (
            <TableSrcRecords.Table
              ref={refTable}
              // noWrapWhitespace
              kind="src_billing_provider"
              legacyMode
              enableURLReflection="zclaims.workflows.vba.billing_provider"
              passFilters={{ HasNoDefaulter: true, legacyMode: true }}
              LeftHeaderItems={
                <>
                  <Typography>Billing Provider Source Data</Typography>
                  &nbsp;
                  <IconButton
                    size="small"
                    onClick={() => refTable.current?.refresh()}>
                    <DesignSuite2023.CommonIcons.IconRefresh fontSize="inherit" />
                  </IconButton>
                  &nbsp;
                  <TableSrcRecords.StandardFilterSearch />
                </>
              }
              RightHeaderItems={
                <>
                  <TableSrcRecords.FilterWhereHasNoDefaulter />
                </>
              }
              RenderComponentOnClick={BtnAssigner}
            />
          )}
        </div>
      </div>
    </div>
  )
}

interface assignerProps {
  kind: kind
  srcRec: any
}

function BtnAssigner({ kind, srcRec }: assignerProps): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const [component, setComponent] = useState<React.ReactElement | null>(null)

  function onClick(ev: any) {
    ev.stopPropagation()
    switch (kind) {
      case 'src_patient':
        setComponent(<AssignPatient kind={kind} srcRec={srcRec} />)
        return
      case 'src_billing_provider':
        setComponent(
          <AssignBillingEntity kind={kind} srcRec={srcRec} />
          // <ReconcileSrcBillingEntity kind={kind} hash={hash} />
        )
        return
    }
  }

  function onClose() {
    setComponent(null)
    refresh()
  }

  return (
    <>
      <IconButton size="small" onClick={onClick}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <DesignSuite2023.Drawer
        open={!!component}
        width="80vw"
        disableEscapeKeyDown
        onClose={onClose}>
        {component}
      </DesignSuite2023.Drawer>
    </>
  )
}

function AssignBillingEntity({
  kind,
  srcRec,
}: assignerProps): React.ReactElement {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const refClaimsTable = useRef<any>(null)

  function onSelect(row: any) {
    ConfirmDialog({
      content: `You selected ${row.Name} (TIN:${row.TIN}; NPI:${row.NPI}; RemittanceAddress:${row.RemittanceAddress?.DisplayAddress}); all claims imported from VBA with this same billing provider info will be updated. Continue?`,
      onConfirm() {
        putZClaimAssignVBASource({
          Kind: kind,
          ZClaimObservedBillingProviderID: srcRec.ID,
          BillingEntity2ID: row.ID,
        })
          .then(() => {
            showSnackbar('Billing Provider assigned OK', 'success')
            refClaimsTable.current?.refresh()
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed assigning billing provider',
            })
          )
      },
    })
  }

  function markAsUncontractedProvider() {
    ConfirmDialog({
      content:
        'This will record the information from the source record and categorize all associated claims as attached to an "uncontracted provider". This means claims will NOT be associated with an organization.',
      onConfirm() {
        putZClaimAssignUncontractedProvider({
          ZClaimObservedBillingProviderID: srcRec.ID,
        })
          .then(() => {
            showSnackbar('Uncontracted Provider assigned OK', 'success')
            refClaimsTable.current?.refresh()
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed assigning uncontracted provider',
            })
          )
      },
    })
  }

  return (
    <StyledWrap>
      <div className="flexd">
        <div className="src-info">
          <h4>Billing Provider Source Record</h4>
          <DisplayKeyValueData
            hideEmptyFields={false}
            data={srcRec}
            style={{ margin: '0.5rem 0' }}
          />
          <DesignSuite2023.Tooltip title='ZERO occasionaly receives claims from providers by accident (claims intended for a different payer); in other words: claims we should not receive. We still track the groups sending these claims and bucket them together under a "trash" billing entity.'>
            <Button
              color="secondary"
              variant="outlined"
              onClick={markAsUncontractedProvider}>
              Mark As Uncontracted Provider
            </Button>
          </DesignSuite2023.Tooltip>
        </div>
        <div className="right">
          <div style={{ width: '100%' }}>
            <BillingEntityTable.Table
              // ref={refEligTable}
              onRowClick={onSelect}
              DataTableProps={{ density: 'small' }}
              // noWrapWhitespace
              customColumns={BillingEntityTable.densifiedColumns}
              initPageSize={10}
              LeftHeaderItems={
                <>
                  <BillingEntityTable.StandardFilterSearch />
                  &nbsp;&nbsp;
                  <BillingEntityTable.FilterOrganization />
                  &nbsp;&nbsp;
                  <BillingEntityTable.FilterContractedStatus />
                </>
              }
              RightHeaderItems={
                <>
                  <Typography variant="body2" color="textSecondary">
                    Use the filters to locate a <strong>Billing Entity</strong>
                  </Typography>
                </>
              }
            />
          </div>
        </div>
      </div>

      <DesignSuite2023.Divider />

      {srcRec?.Hash && (
        <ClaimTable.Table
          ref={refClaimsTable}
          passFilters={{ linkedToSrcHash: srcRec?.Hash }}
          initPageSize={250}
          LeftHeaderItems={
            <>
              <h4>Claims where this record appears</h4>
            </>
          }
        />
      )}
    </StyledWrap>
  )
}

function AssignPatient({ kind, srcRec }: assignerProps): React.ReactElement {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const refClaimsTable = useRef<any>(null)

  function onSelect(row: any) {
    ConfirmDialog({
      content: `You selected ${row.FirstName} ${row.LastName} (${row.EmployerName}); all claims imported from VBA with this same patient info will be updated. Continue?`,
      onConfirm() {
        putZClaimAssignVBASource({
          Kind: kind,
          ZClaimObservedPatientID: srcRec.ID,
          EmployerMemberID: row.EmployerMemberID,
        })
          .then(() => {
            showSnackbar('Patient assigned OK', 'success')
            refClaimsTable.current?.refresh()
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed assigning patient',
            })
          )
      },
    })
  }

  return (
    <StyledWrap>
      <div className="flexd">
        <div className="src-info">
          <h4>
            {kind === 'src_patient' ? 'Patient' : 'Subscriber'} Source Record
          </h4>
          <DisplayKeyValueData
            hideEmptyFields={false}
            data={srcRec}
            style={{ marginTop: '0.5rem' }}
          />
        </div>
        <div className="right">
          <div style={{ width: '100%' }}>
            <EligibilityTable.Table
              // ref={refEligTable}
              onRowClick={onSelect}
              DataTableProps={{ density: 'small' }}
              noWrapWhitespace
              LeftHeaderItems={
                <>
                  {/* use of the eligTableContext here is purely to support pressing Enter to search;
                these component would work fine NOT wrapped in the context and <form> tags */}
                  <EligibilityTable.EligTableContext.Consumer>
                    {({ doSearch }: { doSearch(): void }) => (
                      <form
                        onSubmit={(ev: any) => {
                          ev.preventDefault()
                          ev.stopPropagation()
                          doSearch()
                        }}
                        style={{ width: '100%' }}>
                        <EligibilityTable.FilterFirstName />
                        &nbsp;&nbsp;
                        <EligibilityTable.FilterLastName />
                        &nbsp;&nbsp;
                        <EligibilityTable.FilterDateOfBirth />
                        &nbsp;&nbsp;
                        <EligibilityTable.FilterButtonDoSearch />
                        &nbsp;&nbsp;
                        <EligibilityTable.FilterButtonClear />
                      </form>
                    )}
                  </EligibilityTable.EligTableContext.Consumer>
                </>
              }
            />
          </div>
        </div>
      </div>

      <DesignSuite2023.Divider />
      {srcRec?.Hash && (
        <ClaimTable.Table
          ref={refClaimsTable}
          passFilters={{ linkedToSrcHash: srcRec?.Hash }}
          initPageSize={250}
          LeftHeaderItems={
            <>
              <h4>Claims where this record appears</h4>
            </>
          }
        />
      )}
    </StyledWrap>
  )
}
