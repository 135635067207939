import React, { useEffect } from 'react'
import { getZelisBatchDetail } from '../../../actions/ZClaimFundingActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import styled from 'styled-components'
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import FileLinkBtn from '../../../components/FileLinkBtn/FileLinkBtn'
import useSnackbar, { SnackbarTypeError } from '../../../hooks/useSnackbar'
import ChipInformationDisplay from './ChipInformationDisplay'

interface zelisBatchDetail {
  ID: number
  Date: Date
  Amount: number
  FileName: string
  FileID: number
  S3Key: string
  Payments: payment[]
}
interface payment {
  ID: number
  Date: Date
  PayeeName: string
  Amount: number
  OrganizationName: string
  BillingEntityName: string
  CheckNumber: number
  Notes: string
}

const defaultZelisBatch = {
  ID: 0,
  Date: new Date(),
  Amount: 0,
  FileName: '',
  FileID: 0,
  S3Key: '',
  Payments: [],
}

const DetailDiv = styled.div`
  margin-bottom: 16px;
`

export default function ZelisBatchDetail({
  data,
  doClose,
}: any): React.ReactElement {
  onclose = doClose ? doClose : null

  const [batchInfo, setBatchInfo] = React.useState<zelisBatchDetail>({
    ...defaultZelisBatch,
  })
  const { catchAPIError } = useErrorHandlers()
  const { showDurationShort } = useSnackbar()

  useEffect(() => {
    getZelisBatchDetail(data.ID)
      .then((res: any) => {
        setBatchInfo(res.Data)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed to get ZelisBatch Details' })
      )
  }, [data.ID])
  return (
    <>
      <Container>
        <Card variant={'outlined'} style={{ marginTop: '20px' }}>
          <CardHeader title={<div>Zelis Batch {batchInfo.ID}</div>} />
          <CardContent>
            <DetailDiv>
              <ChipInformationDisplay
                label="Batch Date"
                value={batchInfo.Date.toString()}
              />
              <ChipInformationDisplay
                label="Batch Amount"
                value={formatAmount(batchInfo.Amount.toString())}
              />
              <Chip label="File" size="small" />
              &nbsp;&nbsp;&nbsp;
              <FileLinkBtn
                filename={batchInfo.FileName}
                handleGetDownloadURLFail={() => {
                  showDurationShort(
                    'Unable to download file',
                    SnackbarTypeError
                  )
                }}
                fileId={batchInfo.FileID}
              />
            </DetailDiv>
            {batchInfo.Payments && batchInfo.Payments.length > 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Payment ID</TableCell>
                      <TableCell>Check Number</TableCell>
                      <TableCell>Payment Date</TableCell>
                      <TableCell>Billing Entity</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {batchInfo.Payments.map((payment: any) => (
                      <TableRow key={payment.ID}>
                        <TableCell>{payment.ID}</TableCell>
                        <TableCell>{payment.CheckNumber}</TableCell>
                        <TableCell>{payment.Date}</TableCell>
                        <TableCell>{payment.BillingEntityName}</TableCell>
                        <TableCell>{payment.Notes}</TableCell>
                        <TableCell>{formatAmount(payment.Amount)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
