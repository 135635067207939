import * as api from '../services/thezerocard/api-helper'

export const findBillingEntities = (params: any) => {
  return api.search(`/billing_entity`, params)
}

export const getBillingEntityByID = (id: number) => {
  return api.get(`/billing_entity/${id}`)
}

export const saveBillingEntity = (body: any) => {
  return api.put(`/billing_entity/${body.ID}`, body)
}

export const createBillingEntity = (body: any) => {
  return api.post('/billing_entity', body)
}

export const deleteBillingEntities = (ids: any) => {
  return api.del('/billing_entity', ids)
}
