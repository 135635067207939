import React, { useState, useEffect, useRef } from 'react'
import {
  getZClaimSrcRecord,
  putZClaimSrcRecordDefaulterPracticeFacility,
} from '../../../../actions/ZClaimActions'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import styled from 'styled-components'
import DisplayKeyValueData from '../../Components/DisplayKeyValueData'
import * as PracticeFacilityTable from '../../../../views/PracticeFacilities/PracticeFacilityTable2'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import * as ClaimTable from '../../Components/ClaimTable'
import { Typography } from '@material-ui/core'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar from '../../../../hooks/useSnackbar'

const StyledWrap = styled.div`
  padding: 1.5rem;

  .std-table .header-items {
    margin-top: 0;
  }

  h4 {
    margin: 0;
  }

  .flexd {
    display: flex;
    align-items: start;
    width: 100%;

    .src-info {
      padding-right: 1rem;
    }

    .right {
      flex: 1;
      overflow: clip;
      overflow-clip-margin: 1rem;
    }
  }
`

interface props {
  kind: string
  hash: string
}

export default function ReconcileSrcServiceFacility({
  kind,
  hash,
}: props): React.ReactElement {
  const [srcRec, setSrcRec] = useState<any>(null)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const refClaimsTable = useRef<any>(null)

  useEffect(() => {
    getZClaimSrcRecord({ kind, hash }).then(({ Data }: any) => {
      setSrcRec(Data)
    })
  }, [kind, hash])

  function onSelect(row: any) {
    ConfirmDialog({
      content: (
        <div>
          <Typography variant="h5">{row.Name}</Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Address:</strong> {row.Address?.DisplayAddress || '?'}
            <br />
            <strong>Is Active:</strong> {row.IsActive ? 'Yes' : 'No'}
            <br />
            <strong>Active Fee Schedule Name:</strong>{' '}
            {row.ActiveFeeScheduleName || '?'}
          </Typography>
          <DesignSuite2023.Divider />
          <Typography variant="body1" paragraph>
            You selected the above Practice Facility as the default for this
            source record. All future claims with the same data will
            automatically link to this Practice Facility, and any current claims
            **that have not manually assigned the Practice Facility** will be
            auto-set.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            If you think this Practice Facility even <strong>might</strong> be
            inaccurate, given what you see in the source data, do not continue
            and talk to a manager.
          </Typography>
        </div>
      ),
      onConfirm() {
        putZClaimSrcRecordDefaulterPracticeFacility({
          Hash: srcRec.Hash,
          PracticeFacilityID: row.ID,
        })
          .then((res: any) => {
            if (res.error) throw res
            showSnackbar(
              'Assigned default practice facility for source record OK',
              'success'
            )
            refClaimsTable.current?.refresh()
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed assigning defaulter',
            })
          )
      },
    })
  }

  return (
    <StyledWrap>
      <DesignSuite2023.AlertWarning style={{ marginBottom: '1rem' }}>
        <Typography variant="body2">
          Assigning a defaulter for a practice facility requires further
          validation to ensure correctness: all the "Claims Where This Record
          Appears" below must{' '}
          <strong>all be part of the same organization</strong>, and the
          practice facility you pick must be a part of that organization. This
          will be verified upon saving.
        </Typography>
      </DesignSuite2023.AlertWarning>
      <div className="flexd">
        <div className="src-info">
          <h4>Service Facility Source Record</h4>
          <DisplayKeyValueData
            hideEmptyFields={false}
            data={srcRec}
            style={{ marginTop: '0.5rem' }}
          />
        </div>
        <div className="right">
          <PracticeFacilityTable.Table
            DataTableProps={{ density: 'small' }}
            noWrapWhitespace
            onRowClick={onSelect}
            LeftHeaderItems={
              <>
                <PracticeFacilityTable.StandardFilterSearch />
                &nbsp;&nbsp;
                <PracticeFacilityTable.FilterOrganization preloadAll />
              </>
            }
            RightHeaderItems={
              <>
                <Typography variant="body2" color="textSecondary">
                  Use the filters to locate a <strong>Practice Facility</strong>
                </Typography>
              </>
            }
          />
        </div>
      </div>

      <DesignSuite2023.Divider />

      {srcRec?.Hash && (
        <ClaimTable.Table
          ref={refClaimsTable}
          passFilters={{ linkedToSrcHash: srcRec?.Hash }}
          LeftHeaderItems={
            <>
              <h4>Claims where this record appears</h4>
            </>
          }
        />
      )}
    </StyledWrap>
  )
}
