import {
  getInvoiceNotifications,
  getInvoiceReceiptDetail,
  postSendInvoiceCreatedNotification,
  postSendInvoiceReminderNotification,
  receiptType,
} from '../../../actions/ZClaimFundingActions'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import styled from 'styled-components'
import ChipInformationDisplay from './ChipInformationDisplay'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'

interface appliedReceipt {
  ReceiptID: number
  ReceiptDate: Date
  ReceiptType: receiptType
  CheckNumber: string
  Notes: string
  ReceiptAmount: string
  AppliedAmount: string
}

interface notification {
  EventType: string
  EventDescription: string
  EventDate: Date
  ConsumedDate: Date
}

interface invoiceInfo {
  InvoiceID: number
  EmployerID: number
  EmployerName: string
  InvoiceNumber: number
  InvoiceDate: Date
  InvoiceAmount: string
  AppliedAmount: string
  OpenBalance: string
  AppliedReceipts: appliedReceipt[]
}

interface invoiceReceiptDetailProps {
  invoiceID: number
  doClose?(): void
}

const DetailDiv = styled.div`
  margin-bottom: 16px;
`

export default function InvoiceReceiptDetail({
  invoiceID,
  doClose,
}: invoiceReceiptDetailProps): React.ReactElement {
  const { catchAPIError } = useErrorHandlers()
  const [data, setData] = useState<invoiceInfo>({
    InvoiceID: 0,
    EmployerID: 0,
    EmployerName: '',
    InvoiceNumber: 0,
    InvoiceDate: new Date(0),
    InvoiceAmount: '0',
    AppliedAmount: '0',
    OpenBalance: '0',
    AppliedReceipts: [],
  })
  const [notifications, setNotifications] = useState<notification[]>([])
  const { showDurationShort } = useSnackbar()
  onclose = doClose ? doClose : null

  useEffect(() => {
    getData()
    getNotificationData()
  }, [invoiceID])

  function getData() {
    return getInvoiceReceiptDetail(invoiceID)
      .then((res: any) => {
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get invoice receipt data',
        })
      )
  }

  function getNotificationData() {
    return getInvoiceNotifications(invoiceID)
      .then((res: any) => {
        setNotifications(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get notification receipt data',
        })
      )
  }

  function sendInvoiceReminder() {
    if (notifications.length === 0) {
      return
    }

    postSendInvoiceReminderNotification({ invoiceIDs: [invoiceID] })
      .then((res: any) => {
        showDurationShort(
          `Invoice Reminder Notification Queued`,
          SnackbarTypeSuccess
        )
        getNotificationData()
      })
      .catch(catchAPIError({ defaultMessage: 'Unable to queue notification' }))
  }

  function sendInvoiceNotification() {
    if (notifications.length > 0) {
      return
    }

    postSendInvoiceCreatedNotification({ invoiceIDs: [invoiceID] })
      .then((res: any) => {
        showDurationShort(
          `Invoice Created Notification Queued`,
          SnackbarTypeSuccess
        )
        getNotificationData()
      })
      .catch(catchAPIError({ defaultMessage: 'Unable to queue notification' }))
  }

  if (!data) {
    return <></>
  }

  return (
    <>
      <Container>
        <Card variant={'outlined'} style={{ marginTop: '20px' }}>
          <CardHeader title={<div>{data.EmployerName}</div>} />
          <CardContent>
            <DetailDiv>
              <ChipInformationDisplay
                label="Invoice Number"
                value={data.InvoiceNumber.toString()}
              />
              <ChipInformationDisplay
                label="Invoice Date"
                value={data.InvoiceDate.toString()}
              />
              <ChipInformationDisplay
                label="Invoice Amount"
                value={formatAmount(data.InvoiceAmount)}
              />
              <ChipInformationDisplay
                label="Applied Amount"
                value={formatAmount(data.AppliedAmount)}
              />
              <ChipInformationDisplay
                label="Open Balance"
                value={formatAmount(data.OpenBalance)}
              />
            </DetailDiv>
            {data.AppliedReceipts && data.AppliedReceipts.length > 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Receipt ID</TableCell>
                      <TableCell>Receipt Date</TableCell>
                      <TableCell>Receipt Type</TableCell>
                      <TableCell>Check Number</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Receipt Amount</TableCell>
                      <TableCell>Applied Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.AppliedReceipts.map((receipt) => (
                      <TableRow key={receipt.ReceiptID}>
                        <TableCell>{receipt.ReceiptID}</TableCell>
                        <TableCell>{receipt.ReceiptDate}</TableCell>
                        <TableCell>{receipt.ReceiptType}</TableCell>
                        <TableCell>{receipt.CheckNumber}</TableCell>
                        <TableCell>{receipt.Notes}</TableCell>
                        <TableCell>
                          {formatAmount(receipt.ReceiptAmount)}
                        </TableCell>
                        <TableCell>
                          {formatAmount(receipt.AppliedAmount)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
        <Card variant={'outlined'} style={{ marginTop: '20px' }}>
          <CardContent>
            <DesignSuite2023.GridLR
              left={
                <Typography component="div" variant="h5">
                  Notification History
                </Typography>
              }
              right={
                notifications.length === 0 ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={sendInvoiceNotification}>
                    Send Creation Notification
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={sendInvoiceReminder}>
                    {' '}
                    Send Invoice Reminder{' '}
                  </Button>
                )
              }
            />
            {notifications.length > 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Notification Type</TableCell>
                      <TableCell>Notification Created</TableCell>
                      <TableCell>Email Sent</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notifications.map((notif, index) => (
                      <TableRow key={`notif${index}`}>
                        <TableCell>{notif.EventDescription}</TableCell>
                        <TableCell>{notif.EventDate.toString()}</TableCell>
                        <TableCell>
                          {notif.ConsumedDate?.toString() || ''}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

function formatAmount(amount: string | number): string {
  if (!amount) {
    return `$0.00`
  }

  if (typeof amount === 'number') {
    amount = amount.toString()
  }

  if (amount === '0') {
    return `$0.00`
  }

  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
