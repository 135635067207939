import React, { ReactElement } from 'react'
import {
  PostSendInvoiceNotificationsParams,
  postSendInvoiceReminderNotification,
} from '../../../actions/ZClaimFundingActions'
import { Button } from '@material-ui/core'
import { invoicedRecord } from './InvoicedTable'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'

interface props {
  invoicedRecords: invoicedRecord[]
  onComplete: () => void
  disabled: boolean
}

export default function ButtonSendInvoiceReminderNotification({
  invoicedRecords,
  onComplete,
  disabled = false,
}: props): ReactElement {
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  function doPostSendInvoiceReminderNotifications() {
    const params: PostSendInvoiceNotificationsParams = {
      invoiceIDs: invoicedRecords.map((record) => record.InvoiceID),
    }

    postSendInvoiceReminderNotification(params)
      .then(() => {
        showSnackbar(
          'Invoice Reminder Notifications Created',
          SnackbarTypeSuccess
        )
        onComplete?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to create Notifications',
        })
      )
  }

  return (
    <>
      <Button
        fullWidth
        disabled={disabled}
        variant="outlined"
        color="primary"
        size="small"
        style={{ height: '50px' }}
        onClick={() => doPostSendInvoiceReminderNotifications()}>
        Send Invoice Reminder
      </Button>
    </>
  )
}
