import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Step as MUIStep,
  StepLabel as MUIStepLabel,
  Stepper as MUIStepper,
} from '@material-ui/core'
import styled from 'styled-components'
import { EmployerSearcher } from '../../../components/Searchers'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import {
  renderNotesField as RenderNotesField,
  RenderPriceField,
  renderTextField as RenderTextField,
  SetterArgs,
} from '../../ViewHelpers'
import {
  PostEmployerReceiptRecord,
  PostEmployerReceiptsParams,
  postEmployerReceipts,
  receiptType,
} from '../../../actions/ZClaimFundingActions'
import EmployerReceiptDetail from './EmployerReceiptDetail'

interface props {
  onCancel?: () => void
}

enum Steps {
  Enter,
  Apply,
}

const stepLabels = {
  [Steps.Enter]: 'Enter Funds',
  [Steps.Apply]: 'Apply Funds',
} as any

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
  }
`

export default function ReceiveEmployerFunds({
  onCancel,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const [isOpen, setIsOpen] = useState(false)
  const [activeStep, setActiveStep] = useState<Steps>(Steps.Enter)
  const [receiptID, setReceiptID] = useState<number>(0)
  const { showForDuration: showSnackbar } = useSnackbar()

  const initialPostEmployerReceiptRecord: PostEmployerReceiptRecord = {
    employerID: 0,
    receiptDate: new Date(),
    receiptType: receiptType.CHECK,
    checkNumber: '',
    amount: '0',
    notes: '',
  }

  const [postEmployerReceiptRecord, setPostEmployerReceiptRecord] =
    useState<PostEmployerReceiptRecord>(initialPostEmployerReceiptRecord)

  const setter = ({ name, value }: SetterArgs) =>
    setPostEmployerReceiptRecord((curr: PostEmployerReceiptRecord) => ({
      ...curr,
      [name]: value,
    }))

  useEffect(() => {
    setReceiptID(0)
    setPostEmployerReceiptRecord(initialPostEmployerReceiptRecord)
    setActiveStep(Steps.Enter)
  }, [isOpen])

  function doPostEmployerReceipt() {
    let params: PostEmployerReceiptsParams = [postEmployerReceiptRecord]

    interface res {
      Data: number[]
    }

    postEmployerReceipts(params)
      .then((res: res) => {
        if (res && Array.isArray(res.Data) && res.Data.length === 1) {
          setReceiptID(res.Data[0])
        }

        setActiveStep(Steps.Apply)

        showSnackbar(`Receipt posted OK`, SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed posting receipt',
        })
      )
  }

  function displayStep(st: Steps) {
    return { style: { display: activeStep === st ? 'block' : 'none' } }
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Receive Employer Funds">
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          size="small"
          endIcon={<DesignSuite2023.CommonIcons.IconAttachMoney />}
          onClick={() => {
            setIsOpen(true)
          }}>
          Receive Employer Funds
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog
        open={isOpen}
        maxWidth="lg"
        onClose={() => {
          setIsOpen(false)
        }}>
        <DialogTitle>Receive Employer Funds</DialogTitle>
        <DialogContent style={{ width: '1080px' }}>
          <StyledContent>
            <MUIStepper
              alternativeLabel
              activeStep={activeStep}
              style={{ paddingTop: 0 }}>
              {Object.entries(stepLabels).map((tuple: any[]) => {
                return (
                  <MUIStep key={tuple[0]}>
                    <MUIStepLabel>{tuple[1]}</MUIStepLabel>
                  </MUIStep>
                )
              })}
            </MUIStepper>

            <div {...displayStep(Steps.Enter)}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <EmployerSearcher
                        selectedEmployerID={
                          postEmployerReceiptRecord.employerID
                        }
                        onChange={(id: number) =>
                          setter({ name: 'employerID', value: id })
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ManagedDateInput
                        margin={'none'}
                        name="receiptDate"
                        label="Date"
                        value={postEmployerReceiptRecord.receiptDate}
                        setter={setter}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <RenderPriceField
                        opts={{ margin: 'none' }}
                        name="amount"
                        label="Amount"
                        value={postEmployerReceiptRecord.amount}
                        setter={setter}
                        use2023Styles
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl>
                        <FormLabel>Type</FormLabel>
                        <RadioGroup
                          row
                          name="receiptType"
                          value={postEmployerReceiptRecord.receiptType}
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setter({
                              name: ev.target.name,
                              value: ev.target.value,
                            })
                            if (ev.target.value !== receiptType.CHECK) {
                              setter({ name: 'checkNumber', value: '' })
                            }
                          }}>
                          <FormControlLabel
                            value={receiptType.ACH}
                            control={<Radio />}
                            label="EFT"
                          />
                          <FormControlLabel
                            value={receiptType.CHECK}
                            control={<Radio />}
                            label="Check"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <RenderTextField
                        name="checkNumber"
                        label="Check Number"
                        value={postEmployerReceiptRecord.checkNumber}
                        opts={{
                          hidden:
                            postEmployerReceiptRecord.receiptType !==
                            receiptType.CHECK,
                        }}
                        setter={setter}
                        use2023Styles
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <RenderNotesField
                    opts={{ rows: 8, margin: 'none' }}
                    name="notes"
                    label="Notes"
                    value={postEmployerReceiptRecord.notes}
                    setter={setter}
                    use2023Styles
                  />
                </Grid>
              </Grid>
            </div>

            <div {...displayStep(Steps.Apply)}>
              {receiptID !== 0 && (
                <EmployerReceiptDetail
                  receiptID={receiptID}
                  doClose={() => setReceiptID(0)}
                />
              )}
            </div>
          </StyledContent>
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    setIsOpen(false)
                    onCancel && onCancel()
                  }}>
                  {activeStep === Steps.Enter ? 'Cancel' : 'Close'}
                </Button>
                {activeStep === Steps.Apply && (
                  <Button
                    style={{ marginLeft: '10px' }}
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setActiveStep(Steps.Enter)
                      setReceiptID(0)
                      setPostEmployerReceiptRecord(
                        initialPostEmployerReceiptRecord
                      )
                    }}>
                    Enter Another Receipt
                  </Button>
                )}
              </>
            }
            right={
              <>
                {activeStep === Steps.Enter && (
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={doPostEmployerReceipt}>
                      Save Receipt
                    </Button>
                  </>
                )}
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
