import React, { useState, useRef } from 'react'
import * as BillingEntityTable2 from '../../BillingEntities/BillingEntityTable2'
import DisplayBillingEntity from '../../BillingEntities/DisplayBillingEntity'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { deleteBillingEntities } from '../../../actions/BillingEntityActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import { Button, Chip } from '@material-ui/core'

interface props {
  orgID?: number | null
}

export default function DetailTabBillingEntity({
  orgID,
}: props): React.ReactElement | null {
  const [checkedRows, setCheckedRows] = useState<Array<any>>([])
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const refTable = useRef<any>(null)
  const refDisplay = useRef<any>(null)

  if (!orgID) return null

  function doDelete() {
    ConfirmDialog({
      content: 'Do you really want to delete the selected Billing Entity(s)?',
      onConfirm() {
        const ids = checkedRows.map((row: any) => row.ID)
        deleteBillingEntities(ids)
          .then((res: any) => {
            if (res.error) throw res
            showSnackbar('Billing Entity(s) deleted OK', SnackbarTypeSuccess)
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed deleting billing entities',
            })
          )
          .finally(() => {
            refTable.current?.refresh()
          })
      },
    })
  }

  function onAddNew() {
    refDisplay?.current?.openEmpty()
  }

  if (!orgID) {
    return (
      <span>
        OrganizationID is required (if organization is unsaved, save it first)
      </span>
    )
  }

  return (
    <>
      <BillingEntityTable2.Table
        ref={refTable}
        passFilters={{ OrganizationIDs: [orgID] }}
        onCheckHandler={setCheckedRows}
        onRowClick={(row: any) => {
          refDisplay?.current?.viewBillingEntityID(row.ID)
        }}
        LeftHeaderItems={
          <>
            <BillingEntityTable2.StandardFilterSearch />
            <Chip label={`OrgID: ${orgID}`} style={{ marginLeft: '5px' }} />
          </>
        }
        RightHeaderItems={
          <>
            {!!checkedRows.length && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={doDelete}>
                  Delete
                </Button>
                &nbsp;
              </>
            )}
            {!!orgID && (
              <Button variant="contained" color="primary" onClick={onAddNew}>
                Add New
              </Button>
            )}
          </>
        }
      />

      <DisplayBillingEntity
        ref={refDisplay}
        organizationID={orgID}
        onSave={() => refTable?.current?.refresh()}
      />
    </>
  )
}
