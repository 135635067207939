import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  renderNotesField,
  renderSwitchField,
  renderTextField,
  SetterArgs,
} from '../ViewHelpers'
import * as OrgTable from '../Organizations/OrganizationTable'
import * as CostKeyTable2 from '../CostKeys/CostKeyTable2'
import * as EmployerTable from '../Employers/EmployerTable'
import * as PracticeFacilityTable from '../PracticeFacilities/PracticeFacilityTable2'
import { RouteComponentProps, useHistory, useLocation } from 'react-router'
import { canExecEngineering } from '../../utils/perms'
import { Network } from './types'
import {
  getNetwork,
  putRemoveNetworkEntities,
  saveNewNetwork,
  updateNetwork,
} from '../../actions/NetworkActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import styled from 'styled-components'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import DialogAddOrganization from './DialogAddOrganization'
import DialogAddEmployer from './DialogAddEmployer'
import DialogAddPracticeFacility from './DialogAddPracticeFacility'
import DialogAddCostKey from './DialogAddCostKey'
import _pick from 'lodash/pick'

const StyledPaper = styled(Paper)`
  overflow: hidden;
  padding: 1rem;
`

const StyledTabWrap = styled.div`
  /*background:#263f52;
  color:#fff;*/
  margin: -1rem -1.5rem 0.75rem;

  .MuiTabs-root {
    .MuiTab-wrapper {
      white-space: nowrap;
    }
  }
`

const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 2rem;
  row-gap: 1rem;
  align-items: start;
  row-gap: 1rem;

  h4 {
    margin-top: 0;
  }
`

const Blockquote = styled.blockquote`
  margin: 2rem auto 1rem;
  max-width: 850px;
  text-align: left;
`

const CustomAlert = styled(Alert)`
  margin: 0;
`

const defaultNetwork = {
  ID: 0,
  CreatedAt: '',
  UpdatedAt: '',
  DeletedAt: '',
  Name: '',
  Hash: '',
  Notes: '',
  IsPrivate: false,
  IncludeInPublicSearch: false,
  UseSpecificCostKeys: false,
  CreatedByUserID: 0,
  ModifiedByUserID: 0,
}

const tabs = {
  orgs: 0,
  pfs: 1,
  employers: 2,
  costkeys: 3,
}

const customColsPFTable = _pick(PracticeFacilityTable.defaultColumns, [
  'ID',
  'Name',
  'DisplayAddress',
  'HasActiveFeeSchedule',
  'ActiveFeeScheduleName',
  'LaunchDate',
  'TerminationDate',
])

const customColsEmpTable = _pick(EmployerTable.defaultColumns, [
  'Name',
  'Exclusions',
  'LaunchDate',
  'TerminationDate',
  'DisplayAddress',
])

export default function NetworkDetail({
  match,
}: RouteComponentProps<{ id: string }>): React.ReactElement | null {
  const id = match.params.id ? Number(match.params.id) : null
  const { queryData, setQueryData } = useQueryParamsGen2()
  const [networkObj, setNetworkObj] = useState<Network>(defaultNetwork)
  const [initialNetwork, setInitialNetwork] = useState<Network>()
  const [changedFields, setChangedFields] = useState<Array<string>>([])
  const [tabValue, setTabValue] = useState<number>(
    Number.isInteger(queryData?.tabValue) ? queryData.tabValue : 0
  )
  const [selectedCKs, setSelectedCKs] = useState<Array<any>>([])
  const [selectedOrgs, setSelectedOrgs] = useState<Array<any>>([])
  const [selectedPFs, setSelectedPFs] = useState<Array<any>>([])
  const [selectedEmps, setSelectedEmps] = useState<Array<any>>([])
  const [permEngineering] = useState<boolean>(canExecEngineering())
  const { catchAPIError } = useErrorHandlers()
  const { show: showSnackbar } = useSnackbar()
  const history = useHistory()
  const location = useLocation()
  const refCKTable = useRef()
  const refOrgTable = useRef()
  const refPFTable = useRef()
  const refEmpTable = useRef()

  useEffect(() => {
    if (!id) return
    getNetwork(id)
      .then((res: Network) => {
        setInitialNetwork(res)
        setNetworkObj(res)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to fetch Network; please contact Engineering',
        })
      )
  }, [id])

  const setter = ({ name, value }: SetterArgs) => {
    const newFields = Object.assign({}, networkObj, { [name]: value })
    setNetworkObj(newFields)
    handleSetChangedFields(name, value)
  }

  const handleSetChangedFields = (name: string, value: any) => {
    if (!id) return
    if (!changedFields.includes(name))
      return setChangedFields([...changedFields, name])
    const checkValue = value === '' ? null : value
    if (initialNetwork && checkValue === initialNetwork[name as keyof Network])
      setChangedFields(changedFields.filter((field) => field !== name))
  }

  const handleSave = () => {
    if (!id || !networkObj.ID) {
      saveNewNetwork(
        _pick(networkObj, ['Name', 'Notes', 'IsPrivate', 'UseSpecificCostKeys'])
      )
        .then((res: Network) => {
          history.push(`/network/${res.ID}`)
        })
        .catch(
          catchAPIError({
            defaultMessage:
              'Failed to save new Network; please contact Engineering',
          })
        )
      return
    }
    updateNetwork(id, networkObj)
      .then((res: Network) => {
        setNetworkObj(res)
        setInitialNetwork(res)
        setChangedFields([])
        showSnackbar('Network Successfully Updated', SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to update Network; please contact Engineering',
        })
      )
  }

  const backToNetworks = () => {
    if (location.state) {
      // @ts-ignore
      const qs = location.state.prevSearch ? location.state.prevSearch : ''
      // @ts-ignore
      if (location.state.originPathname)
        // @ts-ignore
        return history.push(`${location.state.originPathname + qs}`)
    }
    history.push('/networks')
  }

  const handleTabChange = (e: any, value: number) => {
    setTabValue(value)
    setQueryData({ tabValue: value })
  }

  const canSave = () => {
    if (id && !changedFields.length) return true
    if (!id && !networkObj.Name) return true
    return false
  }

  function onClickRemoveCostKeys() {
    putRemoveNetworkEntities(networkObj.ID, {
      CostKeyCodeIDs: selectedCKs.map((e: any) => e.ID),
    })
      .then((_: any) => {
        showSnackbar('Remove from network OK!', SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed removing from network',
          duration: 6000,
        })
      )
      .finally(() => {
        // @ts-ignore
        refCKTable.current?.refresh()
      })
  }

  function onClickRemoveOrgs() {
    putRemoveNetworkEntities(networkObj.ID, {
      OrganizationIDs: selectedOrgs.map((e: any) => e.ID),
    })
      .then((_: any) => {
        showSnackbar('Remove from network OK!', SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed removing from network',
          duration: 6000,
        })
      )
      .finally(() => {
        // @ts-ignore
        refOrgTable.current?.refresh()
      })
  }

  function onClickRemovePFs() {
    putRemoveNetworkEntities(networkObj.ID, {
      PracticeFacilityIDs: selectedPFs.map((e: any) => e.ID),
    })
      .then((_: any) => {
        console.log('PF = ', _)
        showSnackbar('Remove from network OK!', SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed removing from network',
          duration: 6000,
        })
      )
      .finally(() => {
        // @ts-ignore
        refPFTable.current?.refresh()
      })
  }

  function onClickRemoveEmps() {
    putRemoveNetworkEntities(networkObj.ID, {
      EmployerIDs: selectedEmps.map((e: any) => e.ID),
    })
      .then((_: any) => {
        showSnackbar('Remove from network OK!', SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed removing from network',
          duration: 6000,
        })
      )
      .finally(() => {
        // @ts-ignore
        refEmpTable.current?.refresh()
      })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader
              title="Network Details"
              subheader="Details about this specific network."
            />
            <CardContent>
              {id && (
                <TextField
                  fullWidth
                  disabled
                  label="ID"
                  value={id}
                  autoFocus
                  margin="dense"
                />
              )}
              {renderTextField({
                name: 'Name',
                label: 'Name',
                value: networkObj.Name,
                setter,
              })}
              {id && (
                <TextField
                  fullWidth
                  disabled
                  label="Hash"
                  value={networkObj.Hash}
                  autoFocus
                  margin="dense"
                />
              )}
              {renderNotesField({
                name: 'Notes',
                label: 'Notes',
                value: networkObj.Notes,
                setter,
              })}
              {renderSwitchField({
                name: 'IsPrivate',
                label: 'Is Private',
                value: networkObj.IsPrivate,
                setter,
              })}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={9}>
          <Card>
            <CardHeader
              title="Network Settings"
              subheader="Configure network operation."
            />
            <CardContent>
              <StyledLayout>
                <div>
                  {renderSwitchField({
                    name: 'UseSpecificCostKeys',
                    label: '',
                    value: networkObj.UseSpecificCostKeys,
                    setter,
                  })}
                </div>
                <div>
                  <h4>Use Only Specific Cost Keys</h4>
                  <p>
                    With this setting <strong>disabled</strong>, the network
                    will automatically use all cost keys offered by attached
                    Organizations or Practice Facilities, determined by fee
                    schedules.
                  </p>
                  <p>
                    By <strong>enabling</strong>, it indicates that only cost
                    keys you directly select for inclusion below will be
                    available to the network. The cost keys supported by this
                    network will <strong>not</strong> be auto-calculated by
                    pulling relevant prices from the currently active fee
                    schedule for associated Organizations/Facilities.
                  </p>
                  {initialNetwork?.UseSpecificCostKeys &&
                    !networkObj?.UseSpecificCostKeys && (
                      <>
                        <CustomAlert severity="warning">
                          By disabling this option, any currently assigned cost
                          keys will be removed from the network. If you
                          re-enable this setting, you'll need to re-add the Cost
                          Keys.
                        </CustomAlert>
                      </>
                    )}
                </div>

                <div>
                  {renderSwitchField({
                    name: 'IncludeInPublicSearch',
                    label: '',
                    value: networkObj.IncludeInPublicSearch,
                    setter,
                  })}
                </div>
                <div>
                  <h4>Include In Public Search</h4>
                  <p>
                    With this setting <strong>enabled</strong>, information on
                    the services from the provider will appear in public search
                    results.
                  </p>
                </div>
              </StyledLayout>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <hr />

      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12} md="auto">
          {changedFields.length && id ? (
            <Typography>
              Unsaved Changes to: {changedFields.join(', ')}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={12} md="auto">
          <Button onClick={backToNetworks}>&lt; Back to Networks</Button>
          &nbsp;&nbsp;
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={canSave()}>
            Save
          </Button>
        </Grid>
      </Grid>

      <hr />

      {!!networkObj.ID && (
        <>
          <StyledPaper elevation={2}>
            <StyledTabWrap>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="inherit"
                variant="fullWidth">
                <Tab value={tabs.orgs} label="Organizations" />
                <Tab value={tabs.pfs} label="Practice Facilities" />
                <Tab value={tabs.employers} label="Employers" />
                <Tab value={tabs.costkeys} label="Cost Keys" />
              </Tabs>
            </StyledTabWrap>
            {tabValue === tabs.orgs && (
              <OrgTable.Table
                ref={refOrgTable}
                passFilters={{ ConfigurableNetworkID: networkObj.ID }}
                onCheckHandler={setSelectedOrgs}
                LeftHeaderItems={
                  <>
                    <OrgTable.StandardFilterSearch />
                    &nbsp;&nbsp;
                    <OrgTable.FilterStatus />
                  </>
                }
                RightHeaderItems={
                  <>
                    {permEngineering && (
                      <DialogAddOrganization
                        networkID={networkObj.ID}
                        onClose={() => {
                          // @ts-ignore
                          refOrgTable.current?.refresh()
                        }}
                      />
                    )}
                  </>
                }
                DataTableProps={{
                  LeftFooterItems: (
                    <>
                      {!!selectedOrgs.length && permEngineering && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          onClick={onClickRemoveOrgs}>
                          Remove
                        </Button>
                      )}
                    </>
                  ),
                }}
              />
            )}
            {tabValue === tabs.pfs && (
              <PracticeFacilityTable.Table
                ref={refPFTable}
                customColumns={customColsPFTable}
                passFilters={{ ConfigurableNetworkID: networkObj.ID }}
                onCheckHandler={setSelectedPFs}
                LeftHeaderItems={<PracticeFacilityTable.StandardFilterSearch />}
                RightHeaderItems={
                  <>
                    {permEngineering && (
                      <DialogAddPracticeFacility
                        networkID={networkObj.ID}
                        onClose={() => {
                          // @ts-ignore
                          refPFTable.current?.refresh()
                        }}
                      />
                    )}
                  </>
                }
                DataTableProps={{
                  LeftFooterItems: (
                    <>
                      {!!selectedPFs.length && permEngineering && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          onClick={onClickRemovePFs}>
                          Remove
                        </Button>
                      )}
                    </>
                  ),
                }}
              />
            )}
            {tabValue === tabs.employers && (
              <EmployerTable.Table
                ref={refEmpTable}
                customColumns={customColsEmpTable}
                passFilters={{ ConfigurableNetworkID: networkObj.ID }}
                onCheckHandler={setSelectedEmps}
                LeftHeaderItems={
                  <>
                    <EmployerTable.StandardFilterSearch />
                    &nbsp;&nbsp;
                    <EmployerTable.FilterEmployerStatus />
                  </>
                }
                RightHeaderItems={
                  <>
                    {permEngineering && (
                      <DialogAddEmployer
                        networkID={networkObj.ID}
                        onClose={() => {
                          // @ts-ignore
                          refEmpTable.current?.refresh()
                        }}
                      />
                    )}
                  </>
                }
                DataTableProps={{
                  LeftFooterItems: (
                    <>
                      {!!selectedEmps.length && permEngineering && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          onClick={onClickRemoveEmps}>
                          Remove
                        </Button>
                      )}
                    </>
                  ),
                }}
              />
            )}
            {tabValue === tabs.costkeys && (
              <>
                {!networkObj.UseSpecificCostKeys && (
                  <div>
                    <Blockquote>
                      The setting <strong>Use Specific Cost Keys</strong>{' '}
                      disables the option to assign specific Cost Keys to this
                      network. Cost Keys are automatically inherited from the
                      assigned Organizations or Practice Facilities.
                    </Blockquote>
                  </div>
                )}
                {networkObj.UseSpecificCostKeys && (
                  <CostKeyTable2.Table
                    ref={refCKTable}
                    passFilters={{ ConfigurableNetworkID: networkObj.ID }}
                    onCheckHandler={setSelectedCKs}
                    LeftHeaderItems={<CostKeyTable2.StandardFilterSearch />}
                    RightHeaderItems={
                      <>
                        {permEngineering && (
                          <DialogAddCostKey
                            networkID={networkObj.ID}
                            onClose={() => {
                              // @ts-ignore
                              refCKTable.current?.refresh()
                            }}
                          />
                        )}
                      </>
                    }
                    DataTableProps={{
                      LeftFooterItems: (
                        <>
                          {!!selectedCKs.length && permEngineering && (
                            <Button
                              color="secondary"
                              variant="contained"
                              size="small"
                              onClick={onClickRemoveCostKeys}>
                              Remove
                            </Button>
                          )}
                        </>
                      ),
                    }}
                  />
                )}
              </>
            )}
          </StyledPaper>
        </>
      )}
    </>
  )
}
