import React, { forwardRef, useContext } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findZClaimInvoiceable } from '../../../actions/ZClaimFundingActions'
import { EmployerSearcher } from '../../../components/Searchers'
import { IconButton } from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import ViewInvoiceableClaimTableOverlay from './ViewInvoiceableClaimTableOverlay'
//@ts-ignore
import styled from 'styled-components'
import dateTime from '../../../utils/dateTime'
import EmployerRefund from './EmployerRefund'

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`
export * from '../../../hooks/useStandardTableSetup'

export interface invoiceableRecord {
  EmployerID: number
  EmployerName: string
  InvoiceStartDate: Date
  InvoiceEndDate: Date
  ClaimCount: number
  InvoiceTotal: string
  ZClaimIDs: number[]
}

export const defaultColumns = Object.freeze({
  _ViewInvoiceableDetails: {
    name: '',
    details: {
      dataFormat(_: any, row: invoiceableRecord): any {
        return <InlineViewInvoiceableDetailTable data={row} />
      },
    },
  },
  EmployerName: { name: 'Employer', details: { sortName: 'EmployerName' } },
  InvoiceStartDate: {
    name: 'Start Date',
    details: {
      dataFormat(_: any, row: invoiceableRecord): any {
        return <div>{dateTime.parse(row.InvoiceStartDate).format()}</div>
      },
    },
  },
  InvoiceEndDate: {
    name: 'End Date',
    details: {
      dataFormat(_: any, row: invoiceableRecord): any {
        return <div>{dateTime.parse(row.InvoiceEndDate).format()}</div>
      },
    },
  },
  ClaimCount: { name: 'Claim Count' },
  InvoiceTotal: {
    name: 'Total',
    details: {
      dataFormat(_: any, row: invoiceableRecord): any {
        const amount = parseFloat(row.InvoiceTotal.replace(/[^0-9.-]+/g, ''))
        const style = amount < 0 ? { color: 'red' } : {}
        return <div style={style}>{formatAmount(row.InvoiceTotal)}</div>
      },
      sortName: 'InvoiceTotal',
    },
  },
  ManualPaymentButton: {
    name: '',
    details: {
      dataFormat(_: any, row: invoiceableRecord): any {
        return parseFloat(row.InvoiceTotal) < 0 ? (
          <InlineEmployerRefundButton data={row} />
        ) : null
      },
    },
  },
})

const defaultSort = { col: 'EmployerName', dir: 'asc' }

export const Table = forwardRef(function Table(
  {
    customColumns = defaultColumns,
    apiEndpoint = findZClaimInvoiceable,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 100,
      DataTableProps: {
        isRowCheckable: (row: invoiceableRecord) => row.InvoiceTotal > '0.00',
        keyProp: 'EmployerID',
        ...DataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-invoice-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

function InlineViewInvoiceableDetailTable({
  data,
}: {
  data: invoiceableRecord
}): React.ReactElement {
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <ViewInvoiceableClaimTableOverlay ref={ref} data={data} />
    </>
  )
}

export function FilterEmployer(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <EmployerSearcher
      {...props}
      selectedEmployerID={filter.employerIDs?.[0]}
      onChange={(empID: number | null) => {
        if (!empID) {
          setFilterImmediate({ employerIDs: [] })
          return
        }
        setFilterImmediate({ employerIDs: [empID] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 350 },
      }}
    />
  )
}

function InlineEmployerRefundButton({
  data,
}: {
  data: invoiceableRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)

  return <EmployerRefund data={data} onClose={() => refresh()} />
}
