import React, { useState, useEffect, useRef } from 'react'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar from '../../../../hooks/useSnackbar'
import * as BillingEntityTable from '../../../BillingEntities/BillingEntityTable2'
import DisplayKeyValueData from '../DisplayKeyValueData'
import styled from 'styled-components'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Grid,
  // Chip,
  Typography,
} from '@material-ui/core'
import {
  getZClaimAssignmentProvider,
  putZClaimAssignBillingEntity,
} from '../../../../actions/ZClaimActions'
import {
  renderSwitchField as RenderSwitchField,
  SetterArgs,
} from '../../../ViewHelpers'

const StyledContent = styled(DialogContent)`
  .MuiAlert-root {
    margin-bottom: 0.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .force-switch-label-font-size {
    font-size: inherit;

    .MuiFormControlLabel-label {
      font-size: inherit;
    }
  }

  .std-table .header-items {
    margin-top: 0;
  }
`

interface props {
  zClaimID: number
  onComplete?(): void
}

export default function AssignerBillingEntity({
  zClaimID,
  onComplete,
}: props): React.ReactElement {
  const [selectedBE, setSelectedBE] = useState<any>(null)
  const [makeDefaulter, setMakeDefaulter] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [currentInfo, setCurrentInfo] = useState<any>(null)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const refTable = useRef<any>(null)

  useEffect(() => {
    if (!isOpen) {
      setSelectedBE(null)
      setMakeDefaulter(false)
      setCurrentInfo(null)
      // setShowStep2(false)
      return
    }
    loadInfo()
  }, [isOpen])

  useEffect(() => {
    if (
      !currentInfo?.SrcBillingEntityDefaulter?.BillingEntityID &&
      selectedBE
    ) {
      setMakeDefaulter(true)
      return
    }
    setMakeDefaulter(false)
  }, [selectedBE, currentInfo])

  function loadInfo() {
    if (!zClaimID) return
    getZClaimAssignmentProvider(zClaimID)
      .then(({ Data }: any) => {
        setCurrentInfo(Data)
      })
      .catch(catchAPIError({}))
  }

  function doSave() {
    putZClaimAssignBillingEntity(zClaimID, {
      billingEntityID: selectedBE.ID,
      makeDefaulter,
    }).then((res: any) => {
      setIsOpen(false)
      showSnackbar('Billing Entity assigned OK.', 'success')
      onComplete?.()
    })
  }

  function onClickRow(row: any) {
    if (row.ID === currentInfo?.Current?.BillingEntityInfo?.ID) {
      showSnackbar(
        'Selected Billing Entity is already assigned to this claim, nothing to do.',
        'warning'
      )
      return
    }
    setSelectedBE(row)
  }

  const hasCurrent = !!currentInfo?.Current?.BillingEntityInfo?.ID
  const hasDefaulter = !!currentInfo?.SrcBillingEntityDefaulter?.BillingEntityID
  const selectedAndExistingOrgsMatch =
    selectedBE?.OrganizationID === currentInfo?.Current?.OrganizationInfo?.ID

  return (
    <>
      <DesignSuite2023.Tooltip title="Assign Billing Entity (Organization gets set automatically as a result)">
        <IconButton
          size="small"
          onClick={() => setIsOpen(true)}
          style={{
            padding: '0.3rem',
            background: 'rgba(0,0,0,0.1)',
            fontSize: '110%',
          }}>
          <DesignSuite2023.CommonIcons.IconEdit fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} maxWidth={false} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          Assign Provider{' '}
          <small>
            Selected Billing Entity automatically dictates the Organization
          </small>
        </DialogTitle>
        <StyledContent>
          {!!currentInfo && (
            <Grid container spacing={2}>
              <Grid
                item
                xs="auto"
                style={{ width: 'min-content', maxWidth: 500 }}>
                <DesignSuite2023.Section>
                  <Typography variant="subtitle1" style={{ marginTop: 0 }}>
                    Billing Provider Source Record
                  </Typography>
                  <DisplayKeyValueData
                    data={currentInfo.SrcBillingProvider}
                    skipFields={[
                      'BillingEntityDefaulterID',
                      'BillingEntityDefaulterBillingEntityID',
                    ]}
                  />
                  <SrcAlerts
                    currentInfo={currentInfo}
                    selectedBE={selectedBE}
                  />
                  <DesignSuite2023.AlertInfo>
                    <span>
                      This source record appears on{' '}
                      {currentInfo?.SrcBillingProvider?.ZClaimOccurrences} other
                      claims.
                    </span>
                  </DesignSuite2023.AlertInfo>
                </DesignSuite2023.Section>
              </Grid>
              <Grid
                item
                xs="auto"
                style={{
                  flex: 1,
                  width: 1100,
                  overflow: 'hidden',
                  overflowX: 'scroll',
                }}>
                {!!currentInfo.Current?.BillingEntityInfo?.ID && (
                  <DesignSuite2023.AlertSuccess>
                    <strong>Current Billing Entity: </strong>{' '}
                    {currentInfo.Current.BillingEntityInfo.Name} (ID:{' '}
                    {currentInfo.Current.BillingEntityInfo.ID}) | Organization:{' '}
                    <i>{currentInfo.Current.OrganizationInfo?.Name}</i>
                  </DesignSuite2023.AlertSuccess>
                )}
                <div style={{ display: !!selectedBE ? 'none' : 'block' }}>
                  <BillingEntityTable.Table
                    noWrapWhitespace
                    ref={refTable}
                    onRowClick={onClickRow}
                    DataTableProps={{ density: 'small' }}
                    passFilters={{ contractedStatus: 'yes' }}
                    LeftHeaderItems={
                      <Typography>Choose A Billing Entity</Typography>
                    }
                    RightHeaderItems={
                      <>
                        <BillingEntityTable.FilterContractedStatus />
                        &nbsp;&nbsp;
                        <BillingEntityTable.StandardFilterSearch />
                        &nbsp;&nbsp;
                        <BillingEntityTable.FilterOrganization preloadAll />
                      </>
                    }
                  />
                </div>

                {!!selectedBE && (
                  <div>
                    <DesignSuite2023.SubSection
                      style={{
                        margin: '0 auto 1.5rem',
                        padding: '1rem 2rem',
                        width: 'max-content',
                      }}>
                      <Typography variant="h5">{selectedBE.Name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        <strong>Organization:</strong>{' '}
                        {selectedBE.OrganizationName}
                        <br />
                        <strong>NPI:</strong> {selectedBE.NPI || '?'} |{' '}
                        <strong>TIN:</strong> {selectedBE.TIN || '?'}
                        <br />
                        <strong>Address:</strong>{' '}
                        {selectedBE.RemittanceAddress?.DisplayAddress || '?'}
                        <br />
                        <strong>Is Default For Org:</strong>{' '}
                        {selectedBE.IsDefaultOrganizationBillingEntity
                          ? 'Yes'
                          : 'No'}
                      </Typography>
                    </DesignSuite2023.SubSection>

                    <div style={{ width: '60%', margin: '0 auto' }}>
                      <Typography variant="body1" paragraph>
                        <span>
                          The Billing Entity above will be assigned to this
                          claim. If this does not look correct, or you intended
                          to select a different one, please use the{' '}
                          <i>Back To Search</i> button below.
                        </span>
                      </Typography>

                      {hasCurrent && !selectedAndExistingOrgsMatch && (
                        <DesignSuite2023.AlertWarning>
                          The Billing Entity you selected is part of a different
                          Organization than the Billing Entity currently
                          assigned on the claim. If you proceed, any assigned
                          Practice Facility and/or Fee Schedule will be reset
                          and need to be manually reassigned.
                        </DesignSuite2023.AlertWarning>
                      )}

                      {!hasDefaulter ? (
                        <>
                          <div style={{ paddingTop: '0.25rem' }}>
                            <RenderSwitchField
                              name="makeDefaulter"
                              value={makeDefaulter}
                              label="Make this Billing Entity the default for all future claims with this source data?"
                              setter={(args: SetterArgs) =>
                                setMakeDefaulter(args.value)
                              }
                            />
                          </div>
                          {makeDefaulter && (
                            <small>
                              By making this the default,{' '}
                              <strong>
                                all other claims with this source record will
                                receive this Billing Entity change
                              </strong>{' '}
                              (unless the Billing Entity was manually assigned
                              on other relevant claims already).
                            </small>
                          )}
                        </>
                      ) : (
                        <Typography variant="caption">
                          This change will be applied to{' '}
                          <strong>only this claim</strong>. There are tools
                          available for managing bulk changes to claims in the{' '}
                          <code>Workflows</code> tab.
                        </Typography>
                      )}
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </StyledContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              {!!selectedBE && (
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setSelectedBE(null)}>
                    Back to Search
                  </Button>
                  &nbsp;
                </>
              )}
              <Button
                disabled={!selectedBE}
                color="primary"
                variant="contained"
                onClick={doSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

function SrcAlerts({
  currentInfo,
  selectedBE,
}: any): React.ReactElement | null {
  if (!currentInfo) return null
  const curr = currentInfo.Current?.BillingEntityInfo
  const srcDef = currentInfo.SrcBillingEntityDefaulter

  // @todo: if HasSufficientInfo = false

  if (!srcDef?.BillingEntityID) {
    return (
      <DesignSuite2023.AlertWarning
        key="srcWarn1"
        style={{ marginTop: '0.5rem' }}>
        <span>
          This source record does not have a default Billing Entity assigned.
        </span>
      </DesignSuite2023.AlertWarning>
    )
  }

  switch (srcDef?.BillingEntityID === curr?.ID) {
    case true:
      return (
        <DesignSuite2023.AlertSuccess
          key="srcWarn2"
          style={{ marginTop: '0.5rem' }}>
          <span>
            This source record already has a default Billing Entity,{' '}
            <strong>
              matching the currently assigned Billing Entity on the claim.
            </strong>
            .
          </span>
        </DesignSuite2023.AlertSuccess>
      )

    case false:
      if (!curr.ID) {
        return (
          <DesignSuite2023.AlertWarning
            key="srcWarn3"
            style={{ marginTop: '0.5rem' }}>
            <span>
              This source record already has a default Billing Entity (ID:{' '}
              {srcDef.BillingEntityID}),{' '}
              <strong>but the claim is not using it.</strong>
            </span>
          </DesignSuite2023.AlertWarning>
        )
      }
      return (
        <DesignSuite2023.AlertWarning
          key="srcWarn4"
          style={{ marginTop: '0.5rem' }}>
          <span>
            This source record already has a default Billing Entity (ID:{' '}
            {srcDef.BillingEntityID}),{' '}
            <strong>
              but the current Billing Entity on the claim is different!
            </strong>
            .
          </span>
        </DesignSuite2023.AlertWarning>
      )
  }

  return null
}
