import React, { useState, useImperativeHandle, useRef } from 'react'
// import { ShapeCollection } from '../../types'
import * as CollectionPicker from './Picker'
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  TextField,
} from '@material-ui/core'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
// import * as ClaimTable from '../../Components/ClaimTable'
import styled from 'styled-components'
import { postZClaimCollection } from '../../../../actions/ZClaimActions'
import useErrorHandlers from '../../.././../hooks/useErrorHandlers'

const StyledDialog = styled(Dialog)`
  width: 100vw;

  .custom-title {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    padding: 0.5rem 1rem 1rem;
    min-width: 500px;
  }
`

export interface Props extends CollectionPicker.Props {
  showCreateButton?: boolean
}

export const PickerWithManage = React.forwardRef(function PickerWithManage(
  { showCreateButton = false, ...allProps }: Props,
  ref: any
): React.ReactElement | null {
  const [dialogCreateIsOpen, setDialogCreateIsOpen] = React.useState(false)
  const [name, setName] = useState('')
  const { catchAPIError } = useErrorHandlers()
  const refPicker = useRef<any>(null)

  useImperativeHandle(
    ref,
    () => ({
      open: () => setDialogCreateIsOpen(true),
      close: () => setDialogCreateIsOpen(false),
    }),
    [setDialogCreateIsOpen]
  )

  function doClose() {
    setDialogCreateIsOpen(false)
  }

  function doCreateCollection() {
    postZClaimCollection({
      name,
    })
      .then((res: any) => {
        if (res.error) throw res
        refPicker.current?.setPicked(res.Data)
        setName('')
        setDialogCreateIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed creating collection',
        })
      )
  }

  return (
    <>
      <div>
        <CollectionPicker.Picker ref={refPicker} {...allProps} />
        {showCreateButton && (
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => setDialogCreateIsOpen(true)}>
              Create New Collection
            </Button>
          </div>
        )}
      </div>

      <StyledDialog open={dialogCreateIsOpen} onClose={doClose} maxWidth="lg">
        <DialogTitle disableTypography className="custom-title">
          <Typography variant="h5">Manage Collections</Typography>
          <div>
            <IconButton size="small" onClick={doClose}>
              <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
            </IconButton>
          </div>
        </DialogTitle>

        <div className="content">
          <DesignSuite2023.Tooltip title="Create a new collection">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '1rem',
              }}>
              <div style={{ flex: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={name}
                  placeholder="Collection Name"
                  onChange={(ev: any) => {
                    setName(ev.target?.value || '')
                  }}
                />
              </div>
              <div>
                <Button variant="outlined" onClick={doCreateCollection}>
                  Create
                </Button>
              </div>
            </div>
          </DesignSuite2023.Tooltip>
        </div>
      </StyledDialog>
    </>
  )
})

export default PickerWithManage
