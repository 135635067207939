import utils from '../utils'
const READ_PERMISSION = 'r:provider'

export interface ShapeBillingEntity {
  ID: number
  CreatedAt?: string
  UpdatedAt?: string
  ModifiedBy?: string
  Name: string
  TIN: string
  NPI: string
  OrganizationId: number | null
  Notes: string | null
  DateStart: string | null
  DateEnd: string | null
  // Sequence: number
  RemittanceAddressID: number | null
  RemittanceAddress: {
    ID: number
    Address1: string | null
    Address2: string | null
    City: string | null
    State: string | null
    Zip: string | null
    [key: string]: any // indicates other fields that aren't typed here
  }
  // IsActive: boolean
  // IsActiveChangedAt?: string | null
  // IsDefaultOrganizationBillingEntity: boolean
}

export default {
  hasAccess: () => {
    return utils.checkPerms(READ_PERMISSION)
  },
}
