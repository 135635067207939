import React, { useState, useEffect, useRef } from 'react'
import {
  getZClaimSrcRecord,
  putZClaimSrcRecordDefaulterBillingEntity,
  putZClaimAssignUncontractedProvider,
} from '../../../../actions/ZClaimActions'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import styled from 'styled-components'
import DisplayKeyValueData from '../../Components/DisplayKeyValueData'
import * as BillingEntityTable from '../../../BillingEntities/BillingEntityTable2'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import * as ClaimTable from '../../Components/ClaimTable'
import { Button, Typography } from '@material-ui/core'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar from '../../../../hooks/useSnackbar'

const StyledWrap = styled.div`
  padding: 1.5rem;

  .std-table .header-items {
    margin-top: 0;
  }

  h4 {
    margin: 0;
  }

  .flexd {
    display: flex;
    align-items: start;
    width: 100%;

    .src-info {
      padding-right: 1rem;
    }

    .right {
      flex: 1;
      overflow: clip;
      overflow-clip-margin: 1rem;
    }
  }
`

interface props {
  kind: string
  hash: string
}

export default function ReconcileSrcBillingEntity({
  kind,
  hash,
}: props): React.ReactElement {
  const [srcRec, setSrcRec] = useState<any>(null)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const refClaimsTable = useRef<any>(null)

  useEffect(() => {
    getZClaimSrcRecord({ kind, hash }).then(({ Data }: any) => {
      setSrcRec(Data)
    })
  }, [kind, hash])

  function onSelect(row: any) {
    console.log('onSelect', row)
    ConfirmDialog({
      content: (
        <div>
          <Typography variant="h5">{row.Name}</Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Organization:</strong> {row.OrganizationName}
            <br />
            <strong>NPI:</strong> {row.NPI || '?'} | <strong>TIN:</strong>{' '}
            {row.TIN || '?'}
            <br />
            <strong>Address:</strong>{' '}
            {row.RemittanceAddress?.DisplayAddress || '?'}
            <br />
            <strong>Is Default For Org:</strong>{' '}
            {row.IsDefaultOrganizationBillingEntity ? 'Yes' : 'No'}
          </Typography>
          <DesignSuite2023.Divider />
          <Typography variant="body1" paragraph>
            You selected the above Billing Entity as the default for this source
            record. All future claims with the same data will automatically link
            to this Billing Entity, and any current claims **that have not
            manually assigned the Billing Entity** will be auto-set.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            If you think this Billing Entity even <strong>might</strong> be
            inaccurate, given what you see in the source data, do not continue
            and talk to a manager.
          </Typography>
        </div>
      ),
      onConfirm() {
        putZClaimSrcRecordDefaulterBillingEntity({
          Hash: srcRec.Hash,
          BillingEntityID: row.ID,
        })
          .then(() => {
            showSnackbar(
              'Assigned default billing entity for source record OK',
              'success'
            )
            refClaimsTable.current?.refresh()
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed assigning defaulter',
            })
          )
      },
    })
  }

  function markAsUncontractedProvider() {
    ConfirmDialog({
      content:
        'This will record the information from the source record and categorize all associated claims as attached to an "uncontracted provider". This means claims will NOT be associated with an organization.',
      onConfirm() {
        putZClaimAssignUncontractedProvider({
          ZClaimObservedBillingProviderID: srcRec.ID,
        })
          .then(() => {
            showSnackbar('Uncontracted Provider assigned OK', 'success')
            refClaimsTable.current?.refresh()
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed assigning uncontracted provider',
            })
          )
      },
    })
  }

  return (
    <StyledWrap>
      <div className="flexd">
        <div className="src-info">
          <h4>Billing Provider Source Record</h4>
          <DisplayKeyValueData
            hideEmptyFields={false}
            data={srcRec}
            style={{ margin: '0.5rem 0' }}
          />
          <DesignSuite2023.Tooltip title='ZERO occasionaly receives claims from providers by accident (claims intended for a different payer); in other words: claims we should not receive. We still track the groups sending these claims and bucket them together under a "trash" billing entity.'>
            <Button
              color="secondary"
              variant="outlined"
              onClick={markAsUncontractedProvider}>
              Mark As Uncontracted Provider
            </Button>
          </DesignSuite2023.Tooltip>
        </div>
        <div className="right">
          <BillingEntityTable.Table
            DataTableProps={{ density: 'small' }}
            onRowClick={onSelect}
            customColumns={BillingEntityTable.densifiedColumns}
            initPageSize={10}
            LeftHeaderItems={
              <>
                <BillingEntityTable.StandardFilterSearch />
                &nbsp;&nbsp;
                <BillingEntityTable.FilterOrganization />
              </>
            }
            RightHeaderItems={
              <>
                <Typography variant="body2" color="textSecondary">
                  Use the filters to locate a <strong>Billing Entity</strong>
                </Typography>
              </>
            }
          />
        </div>
      </div>

      <DesignSuite2023.Divider />

      {srcRec?.Hash && (
        <ClaimTable.Table
          ref={refClaimsTable}
          passFilters={{ linkedToSrcHash: srcRec?.Hash }}
          LeftHeaderItems={
            <>
              <h4>Claims where this record appears</h4>
            </>
          }
        />
      )}
    </StyledWrap>
  )
}
