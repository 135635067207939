import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { SetterArgs } from '../../../components/Inputs/standard'
import {
  postInvoice,
  postInvoiceParams,
} from '../../../actions/ZClaimFundingActions'
import styled from 'styled-components'

interface props {
  zClaimIDs: number[]
  invoiceCount: number
  invoiceTotal: string
  onComplete?(): void
  disabled?: boolean
}

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
  }
`

const initialPostInvoiceParams: postInvoiceParams = {
  invoiceDate: new Date(),
  zClaimIDs: [],
}

export default function IssueInvoices({
  zClaimIDs,
  invoiceCount,
  invoiceTotal,
  onComplete,
  disabled = false,
}: props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [postInvoiceParams, setPostInvoiceParams] = useState<postInvoiceParams>(
    initialPostInvoiceParams
  )

  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    setPostInvoiceParams((prevParams) => ({
      ...prevParams,
      zClaimIDs: zClaimIDs,
    }))
  }, [zClaimIDs])

  useEffect(() => {
    setPostInvoiceParams((prevParams) => ({
      ...prevParams,
      invoiceDate: new Date(),
    }))
  }, [isOpen])

  function doInvoice() {
    setIsWorking(true)

    postInvoice(postInvoiceParams)
      .then(() => {
        showSnackbar(`Invoices issued OK`, SnackbarTypeSuccess)
        onComplete?.()
        setIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed issuing invoices',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  const setter = ({ name, value }: SetterArgs) => {
    setPostInvoiceParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }))
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Invoice selected items">
        <span>
          <Button
            disabled={isWorking || disabled}
            fullWidth
            variant="outlined"
            color="secondary"
            size="small"
            endIcon={<DesignSuite2023.CommonIcons.IconFile />}
            onClick={() => {
              setIsOpen(true)
            }}>
            {'Invoice' +
              (invoiceCount > 0
                ? ` (${invoiceCount}) ${formatAmount(invoiceTotal)}`
                : '')}
          </Button>
        </span>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} onClose={handleClose} maxWidth={'lg'}>
        <DialogTitle>Issue Invoices</DialogTitle>
        <DialogContent style={{ width: '1080px' }}>
          <StyledContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <ManagedDateInput
                  name="invoiceDate"
                  label="Invoice Date"
                  value={postInvoiceParams.invoiceDate}
                  setter={setter}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  Invoice count: {invoiceCount}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  Total: {formatAmount(invoiceTotal)}
                </Typography>
              </Grid>
            </Grid>
          </StyledContent>
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </>
            }
            right={
              <>
                <Button color="primary" variant="outlined" onClick={doInvoice}>
                  Issue Invoices
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
