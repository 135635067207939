import React, { forwardRef, useContext } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { IconButton } from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import ViewPaidDetailOverlay from './ViewPaidDetailOverlay'
import { paidRecord } from './PaidDetail'
//@ts-ignore
import styled from 'styled-components'
import { findPayments } from '../../../actions/ZClaimFundingActions'

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`
export * from '../../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  ViewPaidDetails: {
    name: '',
    details: {
      dataFormat(_: any, row: paidRecord): any {
        return <InlineViewPaidDetail data={row} />
      },
    },
  },
  PaymentID: { name: 'Payment ID' },
  Date: { name: 'Date' },
  PayeeName: { name: 'Payee Name' },
  PaymentType: {
    name: 'Type',
    details: {
      dataFormat(_: any, row: paidRecord): any {
        return (
          <>
            {row.PaymentType.toLowerCase() === 'manual'
              ? `${row.PaymentType} (${row.ManualPayType})`
              : row.PaymentType}
          </>
        )
      },
    },
  },
  ClaimCount: { name: 'Claim Count' },
  Amount: {
    name: 'Amount',
    details: {
      dataFormat(_: any, row: paidRecord): string {
        return formatAmount(row.Amount)
      },
    },
  },
})

const defaultSort = { col: 'PaymentID', dir: 'desc' }

export const Table = forwardRef(function Table(
  {
    customColumns = defaultColumns,
    apiEndpoint = findPayments,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 25,
      DataTableProps: {
        keyProp: 'PaymentID',
        ...DataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-invoice-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

function InlineViewPaidDetail({
  data,
}: {
  data: paidRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <ViewPaidDetailOverlay ref={ref} data={data} onClose={() => refresh()} />
    </>
  )
}
