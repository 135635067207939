import React, { useEffect, useRef, useState } from 'react'
import EmployerAccessCard from '../../components/EmployerAccessCard/EmployerAccessCard'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slider,
  Typography,
} from '@material-ui/core'
import { Edit as EditIcon, InfoOutlined } from '@material-ui/icons'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import {
  renderSelectField,
  renderSwitchField,
  renderTextField,
  SetterArgs,
} from '../ViewHelpers'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import useDiffChangedFields from '../../hooks/useDiffChangedFields'
import { useSetFrameHeaderLeft } from '../../components/AppFrame'
import NetworksAutocomplete from '../Networks/NetworksAutocomplete'
import _ from 'lodash'
import Models from '../../models/index'
import { Employer } from '../../models/types/Employer'
import * as model from '../../models/Org'
import * as ContactTable from '../Contacts/ContactTable'
import { OrganizationLogoPicker } from './OrganizationLogoPicker'
import ManageSpecializations from './ManageSpecializations'
import DetailTabBillingEntity from './detailTabs/billingEntities'
import OrgFileTableContainer from './OrgFileTableContainer'
import * as EmployerTable from '../Employers/EmployerTable'
import EmployerManagerV2 from '../Employers/EmployerManagerV2'
import GoZeroUsersTab from './GoZeroUsersTab'
import * as PracticeFacilityTable from '../PracticeFacilities/PracticeFacilityTable2'
import { canExecEngineering, canImportFacilities } from '../../utils/perms'
import { TermModal } from '../../components/TermModal/TermModal'
import { OrgActions } from '../../actions'
import { findGroupEmployersV2 } from '../../actions/GroupActions'
import DialogPracticeFacilitiesImport from './PracticeFacilitiesImport'
import DialogLaunchOrg from './DialogLaunchOrg'
import DesignSuite2023 from './../../components/DesignSuite2023'
import FeeSchedulesTab from './detailTabs/feeSchedules'
import ComponentFeeSchedulesTab from './detailTabs/componentFeeSchedules'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
//@ts-ignore
import styled from 'styled-components'

const {
  backfillReferralInvoices,
  getOrgContactTypes,
  getOrgContractingTypes,
  getOrgEmployers,
  getOrgTermData,
  getOrganization,
  saveNewOrgContact,
  saveNewOrganization,
  saveOrganization,
  terminateOrg,
  updateOrgEmployers,
} = OrgActions

const ChipContainer = styled(Grid)`
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
`

const SpecChip = styled(Chip)`
  margin-right: 2px;
  margin-top: 2px;
`

const DisabledText = styled(Typography)`
  color: #9e9e9e;
`

const EditNetworks = styled(EditIcon)`
  cursor: pointer;
  color: #337ab7;
  font-size: 20px;
  margin-left: 5px;
`

const NetworkChip = styled(Chip)`
  margin-right: 5px;
  margin-top: 2px;
`

const NetworksCompleteContainer = styled(DialogContent)`
  width: 600px;
`

const tabs: Record<string, string> = {
  practiceFacilities: 'pf',
  feeSchedules: 'fs',
  contacts: 'cts',
  files: 'fls',
  gozero: 'gz',
  billingEntities: 'be',
  componentFeeSchedules: 'cfs',
  employer: 'emp',
}

export default function OrganizationDetail({
  match,
  history,
  location,
}: any): React.ReactElement | null {
  const orgID: number | null = match.params.id * 1 || null
  const [record, setRecord] = useState<model.OrgRecordShape>({
    ...model.defaultOrg,
  })
  const { queryData, setQueryData } = useQueryParamsGen2({ scope: 'orgDetail' })
  const [notFound, setNotFound] = useState<boolean>(false)
  const [tab, setTab] = useState<string>(
    queryData.tab || tabs.practiceFacilities
  )
  const [contactTypes, setContactTypes] = useState<any[]>([])
  const [specializations, setSpecializations] = useState<any[]>([])
  const [manageSpecializationsOpen, setManageSpecializationsOpen] =
    useState<boolean>(false)
  const [backfilling, setBackfilling] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [showNetworksModal, setShowNetworksModal] = useState<boolean>(false)
  const [orgContractingTypes, setOrgContractingTypes] = useState<any[]>([])
  const [employers, setEmployers] = useState<Employer[]>([])
  const [orgCanAccessAllEmployers, setOrgCanAccessAllEmployers] =
    useState<boolean>(false)
  const { catchAPIError } = useErrorHandlers()
  const { show: showSnackbar } = useSnackbar()
  const changeTracker = useDiffChangedFields(record, model.diffChangedFields)
  const refEmployersTable = useRef() as any
  const pfTableRef = React.createRef() as any

  useEffect(function () {
    getOrgContactTypes()
      .then((res: any) => {
        if (res.error) throw res
        setContactTypes(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to fetch contact types',
        })
      )

    getOrgContractingTypes()
      .then((res: any) => {
        if (res.error) throw res
        setOrgContractingTypes(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to fetch contracting types',
        })
      )
  }, [])

  useEffect(
    function () {
      if (!orgID) return
      loadOrg(orgID)
      fetchOrgEmployers()
    },
    [orgID]
  )

  useEffect(() => {
    setQueryData({ tab })
  }, [tab])

  useSetFrameHeaderLeft(() => {
    if (!orgID) return <h1>New Organization</h1>
    return !!record.ID ? (
      <div style={{ lineHeight: 1.2 }}>
        <Typography variant="h6" style={{ lineHeight: 'inherit' }}>
          {record.Name}
        </Typography>
        <Typography variant="body2" style={{ lineHeight: 'inherit' }}>
          <strong>Hash:</strong> {record.Hash}
        </Typography>
      </div>
    ) : (
      <></>
    )
  }, [record])

  function loadOrg(id: number): void {
    setIsLoading(true)
    getOrganization(id)
      .then((res: any) => {
        if (res.error) throw res
        setRecord(res)
        setSpecializations(res.Specializations)
        changeTracker.setInitialForDiff(res)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading or refreshing Organization',
          withError: (err: any) => {
            if (err?.Error?.StatusCode === 404) {
              setNotFound(true)
            }
          },
        })
      )
      .finally(() => setIsLoading(false))
  }

  function setTabValue(tabValue: string): void {
    setTab(tabValue)
  }

  function setter({ name, value }: SetterArgs): void {
    setRecord((curr: model.OrgRecordShape) => ({ ...curr, [name]: value }))
  }

  function renderManageSpecializationsDialog(): undefined | React.ReactElement {
    if (!manageSpecializationsOpen) return

    return (
      <ManageSpecializations
        orgID={record.ID}
        handleCancelDialog={() => setManageSpecializationsOpen(false)}
        handleSave={onSaveSpecializations}
        open={manageSpecializationsOpen}
      />
    )
  }

  function onSaveSpecializations(): void {
    getOrganization(record.ID).then((res: model.OrgRecordShape) => {
      setSpecializations(res.Specializations)
    })
    setManageSpecializationsOpen(false)
  }

  function refreshPageData(): void {
    if (!record?.ID) return
    loadOrg(record.ID)
    fetchOrgEmployers()
  }

  function renderChildTabs(): JSX.Element {
    if (!record.ID) return <div />
    return (
      <>
        <DesignSuite2023.Section>
          <DesignSuite2023.StyledTabs value={tab}>
            <DesignSuite2023.StyledTab
              value={tabs.practiceFacilities}
              label="Practice Facilities"
              onClick={() => setTabValue(tabs.practiceFacilities)}
            />
            <DesignSuite2023.StyledTab
              value={tabs.feeSchedules}
              label="Fee Schedules"
              onClick={() => setTabValue(tabs.feeSchedules)}
            />
            {Models.Contact.hasAccess() && (
              <DesignSuite2023.StyledTab
                value={tabs.contacts}
                label="Contacts"
                onClick={() => setTabValue(tabs.contacts)}
              />
            )}
            {Models.File.hasAccess() && (
              <DesignSuite2023.StyledTab
                value={tabs.files}
                label="Files"
                onClick={() => setTabValue(tabs.files)}
              />
            )}
            {Models.Organization.hasGoZeroUserAccess() && (
              <DesignSuite2023.StyledTab
                value={tabs.gozero}
                label="GoZERO"
                onClick={() => setTabValue(tabs.gozero)}
              />
            )}
            {Models.BillingEntity.hasAccess() && (
              <DesignSuite2023.StyledTab
                value={tabs.billingEntities}
                label="Billing Entities"
                onClick={() => setTabValue(tabs.billingEntities)}
              />
            )}
            {Models.ComponentFeeSchedule.hasAccess() && (
              <DesignSuite2023.StyledTab
                value={tabs.componentFeeSchedules}
                label="Component Fee Schedules"
                onClick={() => setTabValue(tabs.componentFeeSchedules)}
              />
            )}
            {!orgCanAccessAllEmployers && (
              <DesignSuite2023.StyledTab
                value={tabs.employer}
                label="Employers (Access)"
                onClick={() => setTabValue(tabs.employer)}
              />
            )}
          </DesignSuite2023.StyledTabs>
          {renderChildTabContent()}
        </DesignSuite2023.Section>
      </>
    )
  }

  function handleSaveNewOrgContact(contact: any): Promise<any> {
    return saveNewOrgContact({ orgId: record.ID, contact })
  }

  function renderChildTabContent(): React.ReactElement | null {
    const employerGroupFilters = {
      NoLimit: true,
      accessGroupIDs: [record.DefaultGoZeroAccessGroupID],
    }
    switch (tab) {
      case tabs.practiceFacilities:
        return (
          <PracticeFacilityTable.Table
            ref={pfTableRef}
            passFilters={{ OrganizationID: record.ID }}
            LeftHeaderItems={
              <>
                <PracticeFacilityTable.StandardFilterSearch />
                <Chip
                  label={`OrgID: ${record.ID}`}
                  style={{ marginLeft: '5px' }}
                />
              </>
            }
            RightHeaderItems={
              <>
                {canImportFacilities() && (
                  <DialogPracticeFacilitiesImport
                    orgID={record.ID}
                    onImportSuccess={() => {
                      pfTableRef.current?.refresh()
                    }}
                  />
                )}
                &nbsp;&nbsp;
                <Button
                  onClick={handleAddNewPracticeFacility}
                  color="primary"
                  variant="contained">
                  Add New
                </Button>
              </>
            }
            DataTableProps={{
              rowOptsApplier(row: any) {
                return !!row.TerminationDate && { className: 'tr-warning' }
              },
            }}
          />
        )

      case tabs.feeSchedules:
        return <FeeSchedulesTab organizationID={record.ID} />

      case tabs.componentFeeSchedules:
        return <ComponentFeeSchedulesTab organizationID={record.ID} />

      case tabs.contacts:
        return (
          <ContactTable.Table
            passFilters={{ OrganizationID: record.ID, IncludeInactive: false }}
            handleSaveNewContact={handleSaveNewOrgContact}
            contactTypes={contactTypes}
            LeftHeaderItems={
              <>
                <ContactTable.StandardFilterSearch />
                &nbsp;
                <ContactTable.ContactTypeTableFilter entityType="organization" />
                &nbsp;
                <ContactTable.SwitchInactive />
                <ContactTable.FilterIsPrimaryContact />
              </>
            }
          />
        )

      case tabs.files:
        return <OrgFileTableContainer id={record.ID} />

      case tabs.billingEntities:
        return <DetailTabBillingEntity orgID={record.ID} />

      case tabs.gozero:
        return <GoZeroUsersTab organizationID={record.ID} />

      case tabs.employer:
        return orgCanAccessAllEmployers ? null : (
          <EmployerTable.Table
            ref={refEmployersTable}
            apiEndpoint={findGroupEmployersV2}
            passFilters={employerGroupFilters}
            customColumns={Models.Employer.orgReferrerColumns}
            LeftHeaderItems={<EmployerTable.StandardFilterSearch autoFocus />}
            RightHeaderItems={
              <EmployerManagerV2
                saveEmployers={handleUpdateOrgEmployers}
                currentEmployers={employers}
              />
            }
          />
        )

      default:
        return <div />
    }
  }

  function backToOrganizations(): void {
    const { state } = location
    let qs = ''

    if (state) {
      const { prevSearch } = state
      if (!_.isEmpty(prevSearch)) {
        qs = prevSearch
      }
    }

    history.push(`/organizations${qs}`)
  }

  function handleAddNewPracticeFacility(): void {
    history.push(`/organization/${record.ID}/practice_facility`)
  }

  function executeSave(): void {
    const { ConfigurableNetworks } = record

    const recordToSave = Object.assign({}, record, {
      ConfigurableNetworks: ConfigurableNetworks.map(
        (network: any) => network.ID
      ),
    }) as any
    delete recordToSave.TerminationDate
    delete recordToSave.IsActive

    setSaving(true)
    if (recordToSave.ID) {
      saveOrganization(recordToSave.ID, recordToSave)
        .then((res: any) => {
          setRecord(res.Data)
          changeTracker.setInitialForDiff(res.Data)
          showSnackbar('Organization Saved OK', SnackbarTypeSuccess)
        })
        .catch(
          catchAPIError({
            defaultMessage:
              'Failed saving Organization; please contact engineering',
          })
        )
        .finally(() => {
          setSaving(false)
        })
    } else {
      saveNewOrganization(recordToSave)
        .then((res: any) => {
          if (res.error) throw res
          showSnackbar('Success!', SnackbarTypeSuccess)
          history.replace(`/organization/${res.Data.ID}`)
        })
        .catch(
          catchAPIError({
            defaultMessage:
              'Failed saving Organization; please contact engineering',
          })
        )
        .finally(() => {
          setSaving(false)
        })
    }
  }

  function backfillInvoices(): void {
    setBackfilling(true)
    if (window.confirm('You sure?')) {
      backfillReferralInvoices({ orgID: record.ID })
        .then(() => {
          showSnackbar(
            'Referral invoices have been successfully backfilled!',
            SnackbarTypeSuccess
          )
        })
        .catch((e: any) => {
          console.error('Unable to backfill referral invoices', e)
          showSnackbar(
            'Unable to backfill referral invoices.',
            SnackbarTypeError
          )
        })
        .finally(() => {
          setBackfilling(false)
        })
    } else {
      setBackfilling(false)
    }
  }

  function importPrices(): void {
    history.push(`/organization/${record.ID}/price_import`)
  }

  function handleRankingChange(_: any, ranking: any): void {
    setter({ name: 'Ranking', value: ranking })
  }

  function handleZeroRankingChange(_: any, ranking: any): void {
    setter({ name: 'ZeroRanking', value: ranking })
  }

  function handleUpdateNetworks(networks: any): void {
    setter({ name: 'ConfigurableNetworks', value: networks })
  }

  function fetchOrgEmployers(): Promise<any> {
    return getOrgEmployers(orgID)
      .then((res: any) => {
        setEmployers(res.Data.Employers)
        setOrgCanAccessAllEmployers(res.Data.AllEmployers)
        //@ts-ignore
        refEmployersTable?.current?.refresh()
        return res
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to fetch organization employers; please contact engineering',
        })
      )
  }

  function handleUpdateOrgEmployers(ids: number[]): Promise<void> {
    return updateOrgEmployers({
      orgID: orgID,
      payload: { EmployerIDs: ids, AllEmployers: false },
    })
      .then(() => fetchOrgEmployers())
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to update organization employers; please contact engineering',
        })
      )
  }

  function handleChangeAccesslevel(): Promise<void> {
    return updateOrgEmployers({
      orgID: orgID,
      payload: { EmployerIDs: [], AllEmployers: !orgCanAccessAllEmployers },
    })
      .then(() => fetchOrgEmployers())
      .then(({ Data }: any) => {
        if (Data?.AllEmployers) {
          if (tab === tabs.employer) {
            setTabValue(tabs.practiceFacilities)
          }
          return
        }
        setTabValue(tabs.employer)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to update organization employers; please contact engineering',
        })
      )
  }

  function renderNetworksModal(): React.ReactElement {
    return (
      <Dialog open={showNetworksModal}>
        <DialogTitle>Configure Networks</DialogTitle>
        <NetworksCompleteContainer>
          <NetworksAutocomplete
            currentNetworks={record.ConfigurableNetworks}
            handleChange={handleUpdateNetworks}
          />
        </NetworksCompleteContainer>
        <DialogActions>
          <Button
            onClick={() => setShowNetworksModal(false)}
            variant="contained"
            color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  if (notFound) {
    return (
      <DesignSuite2023.AlertError>
        Organization could not be found
      </DesignSuite2023.AlertError>
    )
  }

  return (
    <>
      <DesignSuite2023.LayoutContainer>
        <DesignSuite2023.LayoutPrimary large>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} lg={3}>
              <DesignSuite2023.SubSection
                title="Organization Details"
                subtitle="Name, settings and other details.">
                {record?.ID
                  ? renderTextField({
                      name: 'ID',
                      label: 'ID',
                      value: record.ID,
                      disabled: true,
                      setter: () => {}, // no-op
                    })
                  : null}
                {record?.ID ? (
                  <OrganizationLogoPicker organizationID={record.ID} />
                ) : null}
                {renderTextField({
                  name: 'Name',
                  label: 'Name',
                  value: record.Name,
                  opts: { autoFocus: true },
                  setter,
                })}
                {renderSwitchField({
                  name: 'LoaOnly',
                  label: 'LOA Only Organization',
                  value: record.LoaOnly,
                  setter,
                })}
                {renderSwitchField({
                  name: 'AcceptingReferrals',
                  label: 'Accepting referrals in GoZERO',
                  value: record.AcceptingReferrals,
                  setter,
                })}
                {renderTextField({
                  name: 'Website',
                  label: 'Website',
                  value: record.Website,
                  opts: { placeholder: 'Provider website URL' },
                  setter,
                })}
                {renderTextField({
                  name: 'SchedulingPortalURL',
                  label: 'Scheduling Portal Website',
                  value: record.SchedulingPortalURL,
                  opts: { placeholder: 'Provider scheduling portal URL' },
                  setter,
                })}
                {renderSelectField({
                  name: 'ImplementationStatus',
                  label: 'Implementation Status',
                  value: record.ImplementationStatus,
                  items: model.implementationStatusOptions,
                  setter,
                })}
                <ManagedDateInput
                  name="LaunchDate"
                  label="Launch Date"
                  value={record.LaunchDate}
                  setter={setter}
                  opts={{ variant: 'contained' }}
                />
                <Typography
                  style={{ color: record?.IsActive ? 'green' : 'red' }}>
                  Organization is {record?.IsActive ? 'ACTIVE' : 'NOT ACTIVE'}
                </Typography>
                {record?.TerminationDate ? (
                  <ManagedDateInput
                    name="TerminationDate"
                    label="Termination Date"
                    value={record.TerminationDate}
                    setter={setter}
                    opts={{ disabled: true }}
                  />
                ) : (
                  renderTextField({
                    name: 'TerminationDate',
                    label: 'Termination Date',
                    value: 'Not Set',
                    opts: { disabled: true },
                    setter: () => {}, // no-op
                  })
                )}
                {record.ID && !record.TerminationDate && (
                  <TermModal
                    name={record.Name}
                    entityType="organization"
                    getTermData={() => {
                      return getOrgTermData(record.ID)
                    }}
                    terminateEntity={(payload) => {
                      return terminateOrg(record.ID, payload)
                    }}
                    onTerminated={({ Destroyed }) => {
                      if (Destroyed) {
                        history.push(`/organizations`)
                        return
                      }
                      getOrganization(record.ID)
                      pfTableRef.current?.refresh()
                    }}
                  />
                )}
              </DesignSuite2023.SubSection>
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <DesignSuite2023.SubSection
                title="Billing"
                subtitle="How the organization bills">
                {renderTextField({
                  name: 'BillingCompanyName',
                  label: 'Billing Company',
                  value: record.BillingCompanyName,
                  setter,
                })}
                {renderSelectField({
                  name: 'PaymentMethod',
                  label: 'Payment Method',
                  value: record.PaymentMethod,
                  items: Models.Organization.paymentMethodOptions,
                  setter,
                })}
                {renderSwitchField({
                  name: 'GoZeroBilling',
                  label: 'GoZERO Billing',
                  value: record.GoZeroBilling,
                  setter,
                })}
                {renderSwitchField({
                  name: 'EdiBilling',
                  label: 'EDI Billing',
                  value: record.EdiBilling,
                  setter,
                })}
                {renderSwitchField({
                  name: 'PaperBilling',
                  label: 'Paper Billing',
                  value: record.PaperBilling,
                  setter,
                })}
              </DesignSuite2023.SubSection>
              <DesignSuite2023.SubSection
                title="Specializations"
                subtitle="Manage specializations across all facilities">
                <Typography>Current Specializations:</Typography>
                <ChipContainer>
                  {!specializations.length ? (
                    <DisabledText>None</DisabledText>
                  ) : (
                    specializations.map((spec: any) => (
                      <SpecChip
                        color="primary"
                        key={spec}
                        label={spec}
                        variant="outlined"
                      />
                    ))
                  )}
                </ChipContainer>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    setManageSpecializationsOpen(true)
                  }}
                  disabled={!orgID}>
                  Manage Specializations
                </Button>
              </DesignSuite2023.SubSection>
              <DesignSuite2023.SubSection
                title="Other"
                subheader="Ancillary provider info"
                style={{ paddingBottom: 0 }}>
                {renderTextField({
                  name: 'EMRSoftware',
                  label: 'EMR Software',
                  value: record.EMRSoftware,
                  opts: { margin: 'none', style: { marginBottom: '0.5rem' } },
                  setter,
                })}
                {renderSwitchField({
                  name: 'IsReferenceProvider',
                  label: 'Is Reference Provider',
                  value: record.IsReferenceProvider,
                  setter,
                })}
                {renderSelectField({
                  name: 'ContractingType',
                  label: 'Contracting Type',
                  value: record.ContractingType,
                  items: orgContractingTypes.map((v: any) => ({
                    label: v.Label,
                    value: v.ID,
                  })),
                  opts: { margin: 'none' },
                  setter,
                })}
              </DesignSuite2023.SubSection>
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <DesignSuite2023.SubSection
                title="Notes"
                subtitle="Different notes for the organization">
                {renderTextField({
                  label: 'Description',
                  name: 'Descr',
                  value: record.Descr,
                  opts: {
                    multiline: true,
                    placeholder: 'Briefly describe the provider',
                    rows: '4',
                    rowsMax: '8',
                  },
                  setter,
                })}
                {renderTextField({
                  label: 'Notes',
                  name: 'Notes',
                  value: record.Notes,
                  opts: {
                    multiline: true,
                    placeholder: 'Enter notes here',
                    rows: '4',
                    rowsMax: '8',
                  },
                  setter,
                })}
                {renderTextField({
                  label: 'Billing Notes',
                  name: 'BillingNotes',
                  value: record.BillingNotes,
                  opts: {
                    multiline: true,
                    placeholder: 'Enter billing notes here',
                    rows: '4',
                    rowsMax: '8',
                  },
                  setter,
                })}
                {renderTextField({
                  label: 'PHA Notes',
                  name: 'PHANotes',
                  value: record.PHANotes,
                  opts: {
                    multiline: true,
                    placeholder: 'Enter PHA notes here',
                    rows: '4',
                    rowsMax: '8',
                  },
                  setter,
                })}
                {renderTextField({
                  label: 'Ranking Notes',
                  name: 'RankingNotes',
                  value: record.RankingNotes,
                  opts: {
                    multiline: true,
                    placeholder: 'Enter ranking notes here',
                    rows: '4',
                    rowsMax: '8',
                  },
                  setter,
                })}
                {renderTextField({
                  label: 'Termination Notes',
                  name: 'TerminationNotes',
                  value:
                    record.TerminationNotes ||
                    'Termination notes will appear here',
                  opts: {
                    multiline: true,
                    placeholder: 'Termination notes will appear here',
                    rows: '4',
                    rowsMax: '8',
                  },
                  disabled: true,
                  setter,
                })}
              </DesignSuite2023.SubSection>
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <DesignSuite2023.SubSection title="Actions">
                <Grid item style={{ display: 'flex' }}>
                  <Typography>Configurable Networks</Typography>
                  {Models.ConfigurableNetwork.hasAccess() ? (
                    <EditNetworks onClick={() => setShowNetworksModal(true)} />
                  ) : null}
                  <DesignSuite2023.Tooltip title="These networks will apply to *all* facilities on the organization">
                    <InfoOutlined />
                  </DesignSuite2023.Tooltip>
                </Grid>
                <ChipContainer>
                  {!record?.ConfigurableNetworks?.length ? (
                    <DisabledText>None</DisabledText>
                  ) : null}
                  {record?.ConfigurableNetworks?.map((network: any) => (
                    <NetworkChip
                      key={network.ID}
                      label={network.Name}
                      variant="outlined"
                      color="primary"
                    />
                  ))}
                </ChipContainer>
              </DesignSuite2023.SubSection>
              <DesignSuite2023.SubSection title="Rankings">
                <Typography variant="subtitle1" id="rank-label">
                  Org Ranking: {record.Ranking}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" id="rank">
                  How happy the provider is with us
                </Typography>
                <Typography variant="caption">
                  {record?.Ranking
                    ? Models.Organization.rankingDescriptions[record.Ranking]
                    : ''}
                </Typography>
                <Slider
                  value={record.Ranking || undefined}
                  min={1}
                  max={5}
                  step={1}
                  aria-labelledby="rank-label"
                  onChange={handleRankingChange}
                />
                <Typography variant="subtitle1" id="rank-label">
                  ZERO's Org Ranking: {record.ZeroRanking}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" id="rank">
                  How we feel about the provider
                </Typography>
                <Typography variant="caption">
                  {record?.ZeroRanking
                    ? Models.Organization.rankingDescriptions[
                        record.ZeroRanking
                      ]
                    : ''}
                </Typography>
                <Slider
                  value={record.ZeroRanking || undefined}
                  min={1}
                  max={5}
                  step={1}
                  aria-labelledby="rank-label"
                  onChange={handleZeroRankingChange}
                />
              </DesignSuite2023.SubSection>
              <DesignSuite2023.SubSection
                title="Other"
                subtitle="Organization related actions">
                {Models.BundlePrices.hasAccess() && (
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={importPrices}
                    disabled={!record.ID}>
                    Import Prices
                  </Button>
                )}
                {canExecEngineering() && (
                  <DesignSuite2023.LoadingButton
                    variant="outlined"
                    loading={backfilling}
                    onClick={backfillInvoices}
                    disabled={!record.ID}>
                    Backfill Referral Invoices
                  </DesignSuite2023.LoadingButton>
                )}
                <DialogLaunchOrg
                  organizationID={record.ID}
                  onDone={refreshPageData}
                  ButtonProps={{
                    fullWidth: true,
                  }}
                />
              </DesignSuite2023.SubSection>
              <EmployerAccessCard
                EntityID={orgID}
                DefaultGoZeroAccessGroupID={
                  record.DefaultGoZeroAccessGroupID
                    ? record.DefaultGoZeroAccessGroupID
                    : null
                }
                EntityCanAccessAllEmployers={orgCanAccessAllEmployers}
                HandleChangeAccessLevel={handleChangeAccesslevel}
              />
            </Grid>
          </Grid>
        </DesignSuite2023.LayoutPrimary>
        <DesignSuite2023.LayoutSidebar>
          <DesignSuite2023.LoadingButton
            loading={saving}
            variant="contained"
            color="primary"
            onClick={executeSave}>
            Save
          </DesignSuite2023.LoadingButton>
          <DesignSuite2023.LoadingButton
            size="small"
            onClick={backToOrganizations}>
            Back to Organizations
          </DesignSuite2023.LoadingButton>
          {record.ID !== 0 && (
            <>
              <DesignSuite2023.Divider />
              <DesignSuite2023.DisplayRecordMeta record={record} />
              <changeTracker.DisplayChangedFields>
                <DesignSuite2023.Divider />
              </changeTracker.DisplayChangedFields>
            </>
          )}
        </DesignSuite2023.LayoutSidebar>
      </DesignSuite2023.LayoutContainer>
      {renderNetworksModal()}
      {renderManageSpecializationsDialog()}
      {!isLoading && renderChildTabs()}
    </>
  )
}
