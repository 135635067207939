import React, { forwardRef, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { getEmployerMarketingActivity } from '../../../actions/EmployerActions'
import { fileLinkFormatter } from '../../../utils/ReactFormatters'
import utils from '../../../utils'
import dateTime from '../../../utils/dateTime'
import ButtonDeleteMarketingActivity from './ButtonDeleteMarketingActivity'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../../hooks/useStandardTableSetup'

const defaultColumns = {
  ActivityType: { name: 'Type' },
  Cost: {
    name: 'Cost',
    details: {
      dataFormat(cost: any, row: any): any {
        return utils.priceFormatter(cost, row)
      },
    },
  },
  Notes: { name: 'Notes' },
  File: {
    name: 'File',
    details: {
      dataFormat(_: any, row: any): any {
        return fileLinkFormatter(null, row.File)
      },
    },
  },
  ActivityDate: {
    name: 'Date',
    details: {
      dataFormat(_: any, row: any): any {
        return dateTime.parse(row.ActivityDate).format()
      },
    },
  },
  ModifiedBy: {
    name: 'LastModifiedBy',
    details: {
      dataFormat(_: any, row: any): any {
        return row.ModifiedByUsername
      },
    },
  },
}

const defaultSort = { col: 'CreatedAt', dir: 'desc' }

export const Table = forwardRef(function MarketingActivityTable(
  {
    employerID,
    customColumns = defaultColumns,
    apiEndpoint = getEmployerMarketingActivity,
    onCheckHandler,
    disableCheckHandler = false,
    RightHeaderItems: PropRightHeaderItems,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const refDlg = React.useRef<any>(null)
  const [selectedActivities, setSelectedActivities] = useState<any[]>([])
  const { TableDisplay, refresh } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      onCheckHandler: disableCheckHandler ? undefined : handleSelected,
      RightHeaderItems: (
        <>
          {renderSelectedButtons()}
          {PropRightHeaderItems}
        </>
      ),
      onRowClick(row: any) {
        refDlg?.current?.openAndLoadID(row.ID)
      },
    },
    ref
  )

  function handleSelected(selected: any[]): void {
    if (selected.length === 0) return setSelectedActivities([])
    setSelectedActivities(selected)
  }

  function renderSelectedButtons() {
    if (!selectedActivities.length) return <></>
    return (
      <ButtonDeleteMarketingActivity
        employerID={employerID}
        selectedActivities={selectedActivities}
        onSuccess={() => refresh()}
      />
    )
  }

  return <>{TableDisplay}</>
})
