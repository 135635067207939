import React, { ReactElement, useMemo, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import {
  postRecordInvoiceAch,
  PostRecordInvoiceAchParams,
  PostRecordInvoiceAchRecord,
} from '../../../actions/ZClaimFundingActions'
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { invoicedRecord } from './InvoicedTable'
import {
  renderTextField as RenderTextField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import { ArrowDownward } from '@material-ui/icons'

interface props {
  invoicedRecords: invoicedRecord[]
  onComplete: () => void
  disabled: boolean
}

export default function ButtonRecordAutoDebitACH({
  invoicedRecords,
  onComplete,
  disabled = false,
}: props): ReactElement {
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const [isOpen, setIsOpen] = useState(false)
  const [achDate, setAchDate] = useState<Date>(new Date())
  const [notesByInvoiceID, setNotesByInvoiceID] = useState<{
    [key: number]: string
  }>({})

  const achDateSetter = ({ name, value }: SetterArgs) => {
    setAchDate(value as Date)
  }

  const handleNoteChange = ({ name, value }: SetterArgs) =>
    setNotesByInvoiceID((curr) => ({
      ...curr,
      [parseInt(name.split('-')[1])]: value,
    }))

  const calculateTotalAmount = (records: invoicedRecord[]): number => {
    return records.reduce(
      (total, record) => total + parseFloat(record.InvoiceBalance),
      0
    )
  }

  const totalAmount = useMemo(
    () => calculateTotalAmount(invoicedRecords),
    [invoicedRecords]
  )

  function fillAllNotesFields(value: string) {
    let newNotes: { [key: number]: string } = {}

    for (let record of invoicedRecords) {
      newNotes[record.InvoiceID] = value
    }

    setNotesByInvoiceID(newNotes)
  }

  function doPostRecordInvoiceAch() {
    const records: PostRecordInvoiceAchRecord[] = invoicedRecords.map(
      (record) => ({
        invoiceID: record.InvoiceID,
        amount: record.InvoiceBalance,
        achInfo: notesByInvoiceID[record.InvoiceID] || '',
      })
    )

    const params: PostRecordInvoiceAchParams = {
      achDate,
      achRecords: records,
    }

    postRecordInvoiceAch(params)
      .then(() => {
        showSnackbar('Record Auto Debit ACH completed OK', SnackbarTypeSuccess)
        onComplete?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed recording invoice ACH',
        })
      )
  }

  return (
    <>
      <Button
        fullWidth
        disabled={disabled}
        variant="outlined"
        color="primary"
        size="small"
        style={{ height: '50px' }}
        onClick={() => {
          fillAllNotesFields('')
          setIsOpen(true)
        }}>
        {'Record Auto Debit ACH' +
          (disabled
            ? ''
            : ` (${invoicedRecords.length}) ${formatAmount(totalAmount)}`)}
      </Button>
      <Dialog
        open={isOpen}
        maxWidth="lg"
        onClose={() => {
          setIsOpen(false)
        }}>
        <Container>
          <DialogTitle>
            Record Auto Debit ACH
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={4}>
                <Typography>
                  Total Amount: {formatAmount(totalAmount)}
                </Typography>
                <Typography>Invoice Count: {invoicedRecords.length}</Typography>
              </Grid>

              <Grid item xs={2}>
                <ManagedDateInput
                  name="achDate"
                  label="ACH Date"
                  value={achDate}
                  setter={achDateSetter}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ width: '1080px' }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={2}>
                <Typography>Invoice Number</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Invoice Date</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Employer</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Balance</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>ACH Info</Typography>
              </Grid>
              {invoicedRecords.map((record, index) => (
                <React.Fragment key={record.InvoiceID}>
                  <Grid item xs={2}>
                    <Typography>{record.InvoiceNumber}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>{record.InvoiceDate}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{record.EmployerName}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>
                      {formatStringAmount(record.InvoiceBalance)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: '0px' }}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={9}>
                        <RenderTextField
                          use2023Styles={true}
                          name={`notes-${record.InvoiceID}`}
                          label="ACH info"
                          value={notesByInvoiceID[record.InvoiceID] || ''}
                          setter={handleNoteChange}
                        />
                      </Grid>
                      {index === 0 && invoicedRecords.length > 1 && (
                        <Grid item xs={3}>
                          <DesignSuite2023.Tooltip
                            title={'Fill value to all other rows'}>
                            <IconButton
                              onClick={() => {
                                fillAllNotesFields(
                                  notesByInvoiceID[record.InvoiceID]
                                )
                              }}>
                              <ArrowDownward fontSize="small" />
                            </IconButton>
                          </DesignSuite2023.Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <DesignSuite2023.GridLR
              left={
                <>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setIsOpen(false)
                    }}>
                    Cancel
                  </Button>
                </>
              }
              right={
                <>
                  <>
                    <Button
                      disabled={Object.values(notesByInvoiceID).some(
                        (note) => note === ''
                      )}
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        doPostRecordInvoiceAch()
                        setIsOpen(false)
                      }}>
                      Record ACH
                    </Button>
                  </>
                </>
              }
            />
          </DialogActions>
        </Container>
      </Dialog>
    </>
  )
}

function formatAmount(amount: number): string {
  if (!amount || amount === 0) return '$0.00'
  return `$${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
function formatStringAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
