import React, { useState, useEffect } from 'react'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import { postZClaimCollectionClaims } from '../../../../actions/ZClaimActions'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core'
import * as PickerWithManage from './PickerWithManage'
import styled from 'styled-components'

const StyledDialog = styled(Dialog)`
  .custom-title {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    padding: 0.5rem 1rem 1rem;
    min-width: 500px;
  }
`

interface props {
  zClaimIDs: Array<number>
  onDone?: () => void
}

export default function DialogAddToCollection({
  zClaimIDs,
  onDone,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [existingCollectionID, setExistingCollectionID] = useState<Array<any>>(
    []
  )
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isOpen) return
    setExistingCollectionID([])
  }, [isOpen])

  function doClose() {
    setIsOpen(false)
  }

  function isDisabled() {
    if ((zClaimIDs || []).length === 0) return true
    if (existingCollectionID?.length !== 1) return true
    return false
  }

  function doAddToCollection() {
    if (!(zClaimIDs?.length > 0)) {
      showSnackbar(
        'No claims selected to add to collection; nothing to do',
        SnackbarTypeError
      )
      return
    }

    postZClaimCollectionClaims({
      zClaimCollectionID: existingCollectionID[0] || null,
      zClaimIDs,
    })
      .then((res: any) => {
        if (res.error) throw res
        setIsOpen(false)
        showSnackbar(
          `${zClaimIDs.length} Claims added to collection OK`,
          SnackbarTypeSuccess
        )
        onDone?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed creating collection',
        })
      )
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Add the selected claims to a collection. Any given claim may only be in one collection at a time.">
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          size="small"
          onClick={setIsOpen.bind(null, true)}>
          Add To Collection
        </Button>
      </DesignSuite2023.Tooltip>

      <StyledDialog open={isOpen} maxWidth="sm">
        <DialogTitle disableTypography className="custom-title">
          <Typography variant="h5">
            Add {zClaimIDs?.length || '0'} Claims To Collection
          </Typography>
          <div>
            <IconButton size="small" onClick={doClose}>
              <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="content">
          <PickerWithManage.PickerWithManage
            // multiple
            passFilters={{ myCollections: true }}
            inputLabel="Existing Collections"
            handleChange={(collectionIDs: number[]) => {
              setExistingCollectionID(collectionIDs)
            }}
            currentCollectionIDs={existingCollectionID}
            showCreateButton={true}
          />
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button color="secondary" variant="outlined" onClick={doClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={isDisabled()}
                color="primary"
                variant="contained"
                onClick={doAddToCollection}>
                Add
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </StyledDialog>
    </>
  )
}
