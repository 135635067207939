import React, { ReactElement } from 'react'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { bulkDeleteEmployerMarketingActivity } from '../../../actions/EmployerActions'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import useErrorHandlers from '../../../hooks/useErrorHandlers'

const SelectedButton = styled(Button)`
  margin-right: 10px;
`

export default function ButtonDeleteMarketingActivity({
  employerID,
  selectedActivities,
  onSuccess,
}: {
  employerID: number
  selectedActivities: any[]
  onSuccess: () => void
}): ReactElement {
  const { showDurationShort: showSnackbarDuration } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  function handleDeleteActivities() {
    if (!selectedActivities.length) return
    ConfirmDialog({
      content: `Do you really want to delete the selected Activities?`,
      onConfirm() {
        const activityIDs = selectedActivities.map((activity) => activity.ID)
        bulkDeleteEmployerMarketingActivity(employerID, {
          ActivityIDs: activityIDs,
        })
          .then(() => {
            showSnackbarDuration('Activities Deleted!', SnackbarTypeSuccess)
            onSuccess()
          })
          .catch(
            catchAPIError({ defaultMessage: 'Failed to delete activities' })
          )
      },
    })
  }

  return (
    <SelectedButton
      variant="contained"
      color="secondary"
      onClick={handleDeleteActivities}>
      Delete Activities
    </SelectedButton>
  )
}
