import React, { useState } from 'react'
import DesignSuite2023, {
  DrawerProps,
} from '../../../components/DesignSuite2023'
import { paidRecord } from './PaidDetail'
import ZelisBatchDetail from './ZelisBatchDetail'

type props = DrawerProps & {
  data: paidRecord
  onClose?(): void
}

export default React.forwardRef<props, any>(function (
  { data, disableEscapeKeyDown = true, onClose: propOnClose, ...spread }: props,
  ref: any
): React.ReactElement | null {
  const [component, setComponent] = useState<React.ReactElement | null>(null)

  function onOpen() {
    setComponent(
      <ZelisBatchDetail data={data} doClose={() => ref?.current?.close()} />
    )
  }

  function onClose() {
    setComponent(null)
    propOnClose?.()
  }

  return (
    <DesignSuite2023.Drawer
      {...spread}
      ref={ref}
      width="80vw"
      disableEscapeKeyDown={disableEscapeKeyDown}
      onOpen={onOpen}
      onClose={onClose}>
      {component}
    </DesignSuite2023.Drawer>
  )
})
